import { CameraIcon } from '@radix-ui/react-icons';
import { IconButton } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineContext } from 'contexts/machine.context';
import { StaticVideoType, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPreviewVideoMsg } from 'lib_ts/interfaces/i-machine-msg';
import { DEFAULT_MACHINE_STATE } from 'lib_ts/interfaces/i-machine-state';
import { useContext } from 'react';
import { WebSocketService } from 'services/web-socket.service';

const COMPONENT_NAME = 'VideoPreviewButton';

export const VideoPreviewButton = (props: {
  id: string | undefined;
  px: number | undefined;
  pz: number | undefined;
}) => {
  const { resetMSHash } = useContext(MachineContext);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <IconButton
        size="1"
        title="Preview video using the machine's projector."
        className="btn-floating"
        variant={RADIX.BUTTON.VARIANT.BORDERLESS}
        color={RADIX.COLOR.NEUTRAL}
        onClick={() => {
          if (!props.id) {
            NotifyHelper.warning({
              message_md: 'Please select a video to preview first.',
            });
            return;
          }

          const safePx = props.px ?? DEFAULT_MACHINE_STATE.px;
          const safePz = props.pz ?? DEFAULT_MACHINE_STATE.pz;

          const data: IPreviewVideoMsg = {
            px: safePx,
            pz: safePz,
            video_uuid:
              props.id ??
              (safePx > 0
                ? StaticVideoType.default_LHP
                : StaticVideoType.default_RHP),
          };

          WebSocketService.send(
            WsMsgType.U2M_PreviewVideo,
            data,
            COMPONENT_NAME
          );

          resetMSHash();
        }}
      >
        <CameraIcon />
      </IconButton>
    </ErrorBoundary>
  );
};
