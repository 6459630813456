import { isAppearanceDark } from 'index';
import { LottieOptions, useLottie } from 'lottie-react';
import { useEffect } from 'react';
import darkAnimation from './folder-for-dark.json';
import lightAnimation from './folder-for-light.json';

export const LottieFolder = (props: { open: boolean }) => {
  const isDark = isAppearanceDark();

  const options: LottieOptions = {
    animationData: isDark ? darkAnimation : lightAnimation,
    loop: false,
  };

  const Lottie = useLottie(options);

  Lottie.setSpeed(4);

  useEffect(() => {
    // play forwards or backwards depending on the value of the prop
    Lottie.setDirection(props.open ? 1 : -1);
    Lottie.play();
  }, [props.open]);

  return Lottie.View;
};
