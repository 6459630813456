import { CommonSelectInput } from 'components/common/form/select';
import { HittersContext } from 'contexts/hitters.context';
import { HITTER_SIDES, HitterSide } from 'lib_ts/enums/hitters.enums';
import { useContext } from 'react';

export const SelectHitterSide = (props: { callback?: () => void }) => {
  const { loading, filters, setFilters } = useContext(HittersContext);

  return (
    <CommonSelectInput
      id="hitters-side"
      name="side"
      placeholder="hitters.hitter-side"
      options={HITTER_SIDES}
      value={filters.side}
      onChange={(v) => {
        const current = filters;

        if (!v) {
          delete current.side;
        } else {
          current.side = v as HitterSide;
        }

        setFilters({ ...current });

        props.callback?.();
      }}
      disabled={loading}
      optional
    />
  );
};
