import { StringHelper } from 'classes/helpers/string.helper';
import { DragItem, DropContainer } from 'enums/dnd.enums';
import { t } from 'i18next';
import { ISidebarFolder, ISidebarPitchList } from 'interfaces/i-sidebar';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { PITCH_LIST_TYPES, PitchListExtType } from 'lib_ts/enums/pitches.enums';

export class SidebarHelper {
  static getFileKeys(v: ISidebarPitchList): string[] {
    const output = [
      ...StringHelper.keyify(v.object.name),
      ...StringHelper.keyify(v.object.folder),
    ];

    return ArrayHelper.unique(output);
  }

  // needs to recurse over every sub-folder and file
  static getFolderKeys(v: ISidebarFolder): string[] {
    const output: string[] = [];

    // loop over sub-folders to get the keys from their children
    output.push(...v.folders.flatMap(SidebarHelper.getFolderKeys));

    // keys from immediate children
    output.push(...v.files.flatMap(SidebarHelper.getFileKeys));

    return ArrayHelper.unique(output);
  }

  static parseFolder = (value: string): string[] => {
    return value
      .split('/')
      .map((s) => s.trim())
      .filter((s) => s.length > 0);
  };

  static getFolderName = (
    folder: ISidebarFolder,
    machineID: string
  ): string => {
    if (folder.depth !== 0) {
      // regular folders, return the final path component
      return folder.pathEnd;
    }

    return folder.type
      ? SidebarHelper.getTypedFolderName(folder)
      : SidebarHelper.getRootFolderName(folder, machineID);
  };

  /** given files in a folder, find the folders that are exactly 1 level deeper */
  static getSortedDescendantPaths = (
    // current depth of the root folder where these children will reside
    depth: number,
    descendants: ISidebarPitchList[]
  ): string[][] => {
    const output: string[] = ArrayHelper.unique(
      descendants
        // skip anything that lives in the current depth
        .filter((c) => c.pathComponents.length > depth)
        .map((c) => c.pathComponents.slice(0, depth + 1).join('/'))
    );

    output.sort((a, b) => a.localeCompare(b));

    return output.map((s) => s.split('/'));
  };

  static getFilesRecursively = (
    folder: ISidebarFolder
  ): ISidebarPitchList[] => {
    return [
      ...folder.files,
      ...folder.folders.flatMap((f) => SidebarHelper.getFilesRecursively(f)),
    ];
  };

  static makeFolder = (
    depth: number,
    // corresponding to this folder
    pathComponents: string[],
    children: ISidebarPitchList[],
    descendants: ISidebarPitchList[]
  ): ISidebarFolder => {
    if (children.length + descendants.length === 0) {
      throw new Error('SidebarHelper: makeFolder called on no files');
    }

    // sort by pitch lists' names
    children.sort((a, b) => a.name.localeCompare(b.name));

    descendants.sort((a, b) => {
      const aComps = a.pathComponents.length;
      const bComps = b.pathComponents.length;

      if (aComps !== bComps) {
        // sort the less nested stuff to the top
        return aComps < bComps ? -1 : 1;
      }

      // sort by folder name
      return a.pathEnd.localeCompare(b.pathEnd);
    });

    // used for basic summary of the folder
    const sample = children[0] ?? descendants[0];

    const descPaths = SidebarHelper.getSortedDescendantPaths(
      depth,
      descendants
    );

    const descFolders: ISidebarFolder[] = [];

    descPaths.forEach((descPath) => {
      const descChildren = descendants.filter((dc) =>
        ArrayHelper.equals(descPath, dc.pathComponents)
      );

      const descDescendants = descendants.filter(
        (dc) =>
          descPath.length < dc.pathComponents.length &&
          ArrayHelper.startsWith(descPath, dc.pathComponents)
      );

      if (descChildren.length + descDescendants.length === 0) {
        return;
      }

      descFolders.push(
        SidebarHelper.makeFolder(
          depth + 1,
          descPath,
          descChildren,
          descDescendants
        )
      );
    });

    const folder: ISidebarFolder = {
      depth: depth,
      type: sample.type,

      _parent_def: sample._parent_def,
      _parent_field: sample._parent_field,
      _parent_id: sample._parent_id,

      pathComponents: pathComponents,
      pathDisplay: pathComponents.join('/'),
      pathEnd: pathComponents[depth - 1] ?? '',

      files: children.sort((a, b) =>
        (a.name ?? '').localeCompare(b.name ?? '')
      ),
      folders: descFolders,
    };

    return folder;
  };

  private static getRootFolderName(folder: ISidebarFolder, machineID: string) {
    switch (folder._parent_def) {
      case PitchListOwner.Team: {
        return t('constants.pitch-list-team');
      }

      case PitchListOwner.Machine: {
        return t('constants.pitch-list-x-lists', {
          x: machineID,
        });
      }

      case PitchListOwner.User: {
        return t('constants.pitch-list-personal');
      }

      default: {
        return t('constants.pitch-list-misc');
      }
    }
  }

  private static getTypedFolderName(folder: ISidebarFolder) {
    return t(
      PITCH_LIST_TYPES.find((o) => o.value === folder.type)?.label ??
        'constants.pitch-list-misc'
    );
  }

  static getDragDropDef(folder: ISidebarFolder): {
    container: DropContainer;
    accept: DragItem;
    id: string;
  } {
    if (folder.type) {
      switch (folder.type) {
        case PitchListExtType.Card: {
          return {
            container: DropContainer.CardFolder,
            accept: DragItem.CardList,
            id: 'SidebarCardLists',
          };
        }

        case PitchListExtType.Sample: {
          return {
            container: DropContainer.SampleFolder,
            accept: DragItem.SampleList,
            id: 'SidebarSampleLists',
          };
        }

        /** todo: add reference lists */

        default: {
          return {
            container: DropContainer.PersonalFolder,
            accept: DragItem.PersonalList,
            id: 'SidebarPersonalLists',
          };
        }
      }
    }

    switch (folder._parent_def) {
      case PitchListOwner.Team: {
        return {
          container: DropContainer.TeamFolder,
          accept: DragItem.TeamList,
          id: 'SidebarTeamLists',
        };
      }
      case PitchListOwner.Machine: {
        return {
          container: DropContainer.MachineFolder,
          accept: DragItem.MachineList,
          id: 'SidebarMachineLists',
        };
      }
      case PitchListOwner.User: {
        return {
          container: DropContainer.PersonalFolder,
          accept: DragItem.PersonalList,
          id: 'SidebarPersonalLists',
        };
      }
      default: {
        return {
          container: DropContainer.PersonalFolder,
          accept: DragItem.PersonalList,
          id: 'SidebarPersonalLists',
        };
      }
    }
  }
}
