import { Box, Flex, IconButton, Spinner } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { RemoteKeydownListener } from 'components/common/listeners/remote-keydown';
import { CommonTooltip } from 'components/common/tooltip';
import { MachineAutoFireButton } from 'components/machine/buttons/auto-fire';
import { MachineFireButton } from 'components/machine/buttons/fire';
import {
  Q_DEFINITIONS,
  QueueContext,
} from 'components/sections/pitch-list/queue.context';
import { PitchListSidebarMachineButton } from 'components/sections/pitch-list/sidebar/machine-button';
import { usePitchListStore } from 'components/sections/pitch-list/store/use-pitch-list-store';
import { AimingContext } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { GlobalContext } from 'contexts/global.context';
import { HittersContext } from 'contexts/hitters.context';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { MachineButtonMode } from 'enums/machine.enums';
import { QueueType } from 'interfaces/i-queue-mode';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { IFireResponseMsg } from 'lib_ts/interfaces/i-machine-msg';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

const COMPONENT_NAME = 'PitchListSidebarControls';

// ms to wait after a remote command is received before actually sending mstarget to machine
const DELAY_REMOTE_SEND_MS = 2_000;

export const PitchListSidebarControls = (props: {
  machineBtnClassName: string;
  handleTrainPitches: (config: {
    ids: string[];
    promptRefresh: boolean;
  }) => void;
}) => {
  const fireButton = useRef<MachineFireButton>();

  const cookiesCx = useContext(CookiesContext);
  const machineCx = useContext(MachineContext);
  const aimingCx = useContext(AimingContext);

  const { current } = useContext(AuthContext);
  const { dialogs } = useContext(GlobalContext);
  const { active: aHitter } = useContext(HittersContext);
  const { pitch, checkRequireSend } = useContext(AimingContext);
  const { aggReady, machineButtonMode } = useContext(MatchingShotsContext);
  const { def, resendKey, changePitch, changeQueue, sendSelected } =
    useContext(QueueContext);

  useEffect(() => {
    if (!fireButton.current) {
      return;
    }

    fireButton.current.setAwaitingResend(resendKey > 0);
  }, [resendKey, fireButton.current]);

  const handleFireResponse = (event: CustomEvent) => {
    console.debug('fireresponse', event);

    if (dialogs.length > 0) {
      return;
    }

    const data: IFireResponseMsg = event.detail;

    if (!data) {
      NotifyHelper.error({
        message_md: `Empty fire response payload. ${ERROR_MSGS.CONTACT_SUPPORT}`,
      });
      return;
    }

    if (!data.status) {
      const message = data.message ?? 'Failed to fire, reason unknown';
      NotifyHelper.error({ message_md: message, inbox: true });
      return;
    }

    if (def.type === QueueType.RepeatOne) {
      // don't auto-change pitch
      return;
    }

    changePitch({
      delta: 1,
      autoSend: true,
    });
  };

  // Create a stable reference to handleFireResponse so the WebSocket event listener doesn't keep getting added/removed
  const handleFireResponseRef = useRef(handleFireResponse);

  // Update the ref to always point to the latest handler definition
  useEffect(() => {
    handleFireResponseRef.current = handleFireResponse;
  }, [handleFireResponse]);

  useEffect(() => {
    const onFireResponse = (event: CustomEvent) => {
      if (handleFireResponseRef.current) {
        handleFireResponseRef.current(event);
      }
    };

    WebSocketHelper.on(WsMsgType.M2U_FireResponse, onFireResponse);

    return () => {
      WebSocketHelper.remove(WsMsgType.M2U_FireResponse, onFireResponse);
    };
  }, []);

  const mode = useMemo(
    () =>
      machineButtonMode({
        pitch: pitch,
        requireTraining: true,
        requireSend: checkRequireSend(),
        awaitingResend: fireButton.current?.getAwaitingResend(),
      }),
    [pitch, checkRequireSend]
  );

  const [ignoreAutoFire, setIgnoreAutoFire] = useState(true);

  const listStore = usePitchListStore(
    useShallow(({ tags, setTags }) => ({
      tags,
      setTags,
    }))
  );

  const safeTags = `pitch-list,${listStore.tags}`;

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex gap="2" direction="column">
        <Flex gap="2">
          <Box>
            <CommonTooltip
              trigger={
                <IconButton
                  data-testid="QueueMode"
                  disabled={!aggReady}
                  variant="soft"
                  onClick={() => {
                    const i = Q_DEFINITIONS.findIndex(
                      (o) => o.type === def.type
                    );

                    const iNext = (i + 1) % Q_DEFINITIONS.length;
                    changeQueue(Q_DEFINITIONS[iNext].type);
                  }}
                >
                  {!aggReady ? <Spinner /> : def.icon}
                </IconButton>
              }
              text={def.tooltip}
            />
          </Box>
          <Box flexGrow="1">
            {pitch && (
              <div hidden={mode !== MachineButtonMode.Fire}>
                <MachineFireButton
                  ref={(elem) =>
                    (fireButton.current = elem as MachineFireButton)
                  }
                  className={props.machineBtnClassName}
                  cookiesCx={cookiesCx}
                  machineCx={machineCx}
                  aimingCx={aimingCx}
                  hitter={aHitter}
                  tags={safeTags}
                  beforeFire={() => setIgnoreAutoFire(false)}
                  ignoreAutoFire={ignoreAutoFire}
                  onReady={() => {
                    if (!machineCx.autoFire || ignoreAutoFire) {
                      fireButton.current?.goToReady();
                      return;
                    }

                    if (dialogs.length > 0) {
                      // never auto-fire while a dialog is open
                      return;
                    }

                    if (mode !== MachineButtonMode.Fire) {
                      // never auto-fire while not in firing mode
                      return;
                    }

                    fireButton.current?.performFire(
                      'auto',
                      'pitch list controls'
                    );
                  }}
                  firing
                />
              </div>
            )}

            <PitchListSidebarMachineButton
              className={props.machineBtnClassName}
              handleTrainPitches={props.handleTrainPitches}
              mode={mode}
              pitch={pitch}
            />
          </Box>
        </Flex>

        {current.role === UserRole.admin && (
          <Box>
            <MachineAutoFireButton
              className="btn-block"
              beforeToggleFn={() => setIgnoreAutoFire(true)}
            />
          </Box>
        )}
      </Flex>

      <RemoteKeydownListener
        machineCx={machineCx}
        suspendFn={() => dialogs.length > 0}
        onFire={() => {
          switch (mode) {
            case MachineButtonMode.Fire: {
              fireButton.current?.performFire(
                'manual',
                'clicker event: fire',
                machineCx.machine.remote_hotkeys?.delay_ms
              );
              return;
            }

            case MachineButtonMode.Send: {
              sendSelected('remote control', false);
              return;
            }

            default: {
              return;
            }
          }
        }}
        onNext={() => {
          changePitch({
            delta: 1,
            delay_ms: DELAY_REMOTE_SEND_MS,
            autoSend: true,
          });
        }}
        onPrev={() => {
          changePitch({
            delta: -1,
            delay_ms: DELAY_REMOTE_SEND_MS,
            autoSend: true,
          });
        }}
      />
    </ErrorBoundary>
  );
};
