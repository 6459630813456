import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { Box, Flex, Grid, Heading, IconButton } from '@radix-ui/themes';
import {
  addMonths,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isSameDay,
  lightFormat,
  startOfMonth,
  startOfToday,
} from 'date-fns';
import { LOCAL_DATE_FORMAT } from 'enums/env';
import { IDateWidget } from 'interfaces/forms/date';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX, RadixBtnVariant, RadixColor } from 'lib_ts/enums/radix-ui';
import React, { ReactNode } from 'react';

const DAYS_OF_WEEK = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

interface IProps extends IDateWidget {
  defaultDate?: Date;
  onChangeDate: (value: Date | undefined) => void;
}

interface IState {
  // start of today
  today: Date;

  // which month is being displayed in the widget
  visible: Date;

  // updated whenever a click goes through
  selected?: Date;
}

export class CommonDateWidget extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const init = props.defaultDate ?? new Date();

    this.state = {
      today: startOfToday(),
      visible: startOfMonth(init),
      selected: props.defaultDate,
    };

    this.getClassName = this.getClassName.bind(this);
    this.getColor = this.getColor.bind(this);
    this.getTooltip = this.getTooltip.bind(this);
    this.getVariant = this.getVariant.bind(this);
  }

  private getColor(d: Date): RadixColor {
    const isSelected = this.state.selected && isSameDay(d, this.state.selected);
    if (isSelected) {
      return RADIX.COLOR.ACCENT;
    }

    const isToday = isSameDay(d, this.state.today);
    if (isToday) {
      return RADIX.COLOR.ACCENT;
    }

    return RADIX.COLOR.NEUTRAL;
  }

  private getClassName(d: Date): string | undefined {
    const isSelected = this.state.selected && isSameDay(d, this.state.selected);
    if (isSelected) {
      return undefined;
    }

    const isToday = isSameDay(d, this.state.today);
    if (isToday) {
      return undefined;
    }

    return 'btn-floating';
  }

  private getVariant(d: Date): RadixBtnVariant {
    const isSelected = this.state.selected && isSameDay(d, this.state.selected);
    if (isSelected) {
      return 'solid';
    }

    const isToday = isSameDay(d, this.state.today);
    if (isToday) {
      return 'outline';
    }

    return 'soft';
  }

  private getTooltip(d: Date): string {
    const ds = lightFormat(d, 'yyyy-MM-dd');

    const isSelected = this.state.selected && isSameDay(d, this.state.selected);
    if (isSelected) {
      return `${ds} - Selected`;
    }

    const isToday = isSameDay(d, this.state.today);
    if (isToday) {
      return `${ds} - Today`;
    }

    return ds;
  }

  render(): ReactNode {
    const dates = ArrayHelper.getDateOptions(
      startOfMonth(this.state.visible),
      endOfMonth(this.state.visible)
    );

    const disablePrevMonth =
      this.props.minDate &&
      isBefore(
        addMonths(this.state.visible, -1),
        startOfMonth(this.props.minDate)
      );

    const disableNextMonth =
      this.props.maxDate &&
      isAfter(addMonths(this.state.visible, 1), endOfMonth(this.props.maxDate));

    return (
      <Grid columns="7" gap="1" align="center" style={{ textAlign: 'center' }}>
        {/* header */}
        <Box>
          <IconButton
            variant="soft"
            color={RADIX.COLOR.NEUTRAL}
            disabled={disablePrevMonth}
            onClick={() =>
              this.setState({ visible: addMonths(this.state.visible, -1) })
            }
          >
            <ChevronLeftIcon />
          </IconButton>
        </Box>

        <Flex gridColumn="span 5" justify="center">
          <Heading mt="2" mb="0" size={RADIX.HEADING.SIZE.SM}>
            {format(this.state.visible, 'MMMM y')}
          </Heading>
        </Flex>

        <Box>
          <IconButton
            variant="soft"
            color={RADIX.COLOR.NEUTRAL}
            disabled={disableNextMonth}
            onClick={() =>
              this.setState({ visible: addMonths(this.state.visible, 1) })
            }
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>

        {/* days of week */}
        {DAYS_OF_WEEK.map((d, i) => (
          <Box key={i}>
            <IconButton
              color={RADIX.COLOR.NEUTRAL}
              className="btn-floating"
              disabled
            >
              {d}
            </IconButton>
          </Box>
        ))}

        {/* dates */}
        {dates.map((d, i) => {
          const isBeforeMinDate =
            this.props.minDate && isBefore(d, this.props.minDate);

          const isAfterMaxDate =
            this.props.maxDate && isAfter(d, this.props.maxDate);

          const isNotAllowed =
            this.props.allowedDates &&
            !this.props.allowedDates.includes(
              lightFormat(d, LOCAL_DATE_FORMAT)
            );

          return (
            <Box key={i} gridColumn={(d.getDay() + 1).toString()}>
              <IconButton
                disabled={isBeforeMinDate || isAfterMaxDate || isNotAllowed}
                color={this.getColor(d)}
                variant={this.getVariant(d)}
                className={this.getClassName(d)}
                title={this.getTooltip(d)}
                onClick={() => {
                  this.setState({ selected: d }, () =>
                    this.props.onChangeDate(d)
                  );
                }}
              >
                {d.getDate()}
              </IconButton>
            </Box>
          );
        })}
      </Grid>
    );
  }
}
