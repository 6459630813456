import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SidebarHelper } from 'classes/helpers/sidebar.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { SectionsContext } from 'contexts/sections.context';
import { lightFormat, parseISO } from 'date-fns';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { ISidebarFolder, ISidebarPitchList } from 'interfaces/i-sidebar';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import {
  PitchListCategory,
  PitchListOwner,
} from 'lib_ts/enums/pitch-list.enums';
import { PitchListExtType, TrainingStatus } from 'lib_ts/enums/pitches.enums';
import { ICopyPitchLists } from 'lib_ts/interfaces/pitches/i-copy-pitch-list';
import {
  IPitchList,
  IPitchListPutManyRequest,
  safeFolder,
} from 'lib_ts/interfaces/pitches/i-pitch-list';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PitchListsService } from 'services/pitch-lists.service';

const CONTEXT_NAME = 'PitchListsContext';

const RECENT_LENGTH = 5;

const SORTED_PARENT_DEFS = [
  PitchListOwner.User,
  PitchListOwner.Machine,
  PitchListOwner.Team,
];

interface IOptionsDict {
  name: string[];
  _created: string[];
}

interface IToolbarFilters {
  key: number;

  names: string[];
  status: TrainingStatus[];
  visibility: PitchListOwner | undefined;
  type: PitchListExtType | undefined;
  created: string[];
}

interface ISidebarFilters {
  // matching substrings from folder name or list name
  search: string;
  // can be root (i.e. owner) or type (e.g. reference, card, etc...)
  category: PitchListCategory | undefined;
}

const sanitizeFolders = (lists: IPitchList[]) => {
  /** ensure that any repeated instances of FOLDER_SEPARATOR are collapsed into no more than 1 */
  return lists.map((list) => {
    const out = Object.assign({}, list);
    out.folder = safeFolder(list.folder);
    return out;
  });
};

/** todo: this could probably be refactored into:
 *  - list context (one list and its contents)
 *  - lists context (all lists visible to the user)
 *  - search context (all pitches from all lists visible to the user)
 */
export interface IPitchListsContext {
  lastFetched: number;

  // changing this will cause expanded folders to be collapsed
  collapseKey: number;
  readonly collapseFolders: () => void;

  /** insert list id at index 0 whenever user opens it */
  recentIDs: string[];

  lists: IPitchList[];

  toolbarFilters: IToolbarFilters;
  readonly setToolbarFilters: (value: Partial<IToolbarFilters>) => void;

  sidebarFilters: ISidebarFilters;
  readonly setSidebarFilters: (value: Partial<ISidebarFilters>) => void;

  sidebarFilterKeys: string[];
  sidebarRootFolders: ISidebarFolder[];

  options: IOptionsDict;

  active: IPitchList | undefined;

  loading: boolean;

  readonly updateList: (config: {
    payload: Partial<IPitchList>;
    successMsg?: string;
    silently?: boolean;
  }) => Promise<IPitchList | undefined>;

  readonly updateListViaCSV: (files: File[]) => Promise<boolean>;

  readonly uploadAvatar: (
    files: File[],
    onProgress?: (ev: ProgressEvent) => void
  ) => Promise<IPitchList | undefined>;

  readonly createList: (
    payload: Partial<IPitchList>
  ) => Promise<IPitchList | undefined>;

  readonly copyList: (
    payload: Partial<IPitchList>
  ) => Promise<IPitchList | undefined>;

  readonly copyLists: (
    payload: ICopyPitchLists,
    successMsg?: string
  ) => Promise<boolean>;

  readonly updateLists: (
    payload: IPitchListPutManyRequest,
    successMsg?: string
  ) => Promise<boolean>;

  readonly deleteLists: (ids: string[]) => Promise<boolean>;

  readonly renameFolder: (
    payload: { _parent_id: string; fromFolder: string; toFolder: string },
    successMsg?: string
  ) => Promise<boolean>;

  /** refreshes lists from server (e.g. if someone else changes a list's visiblity) */
  readonly refreshLists: (notify?: boolean) => void;
}

const DEFAULT: IPitchListsContext = {
  loading: false,

  lastFetched: Date.now(),

  collapseKey: Date.now(),
  collapseFolders: () => console.error(`${CONTEXT_NAME}: not init`),

  recentIDs: [],

  options: {
    name: [],
    _created: [],
  },

  lists: [],

  toolbarFilters: {
    names: [],
    status: [],
    created: [],
    visibility: undefined,
    type: undefined,
    key: Date.now(),
  },
  setToolbarFilters: () => console.error(`${CONTEXT_NAME}: not init`),

  sidebarFilters: {
    search: '',
    category: undefined,
  },
  setSidebarFilters: () => console.error(`${CONTEXT_NAME}: not init`),

  sidebarFilterKeys: [],
  sidebarRootFolders: [],

  active: undefined,

  createList: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  copyList: () => new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  copyLists: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  updateList: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  updateListViaCSV: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  uploadAvatar: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  updateLists: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  deleteLists: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  renameFolder: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  refreshLists: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const PitchListsContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const PitchListsProvider: FC<IProps> = (props) => {
  const authCx = useContext(AuthContext);
  const sectionsCx = useContext(SectionsContext);
  const machineCx = useContext(MachineContext);

  const [_lastFetched, _setLastFetched] = useState(DEFAULT.lastFetched);

  const [_loading, _setLoading] = useState(DEFAULT.loading);

  const [_active, _setActive] = useState(DEFAULT.active);

  const [_recentIDs, _setRecentIDs] = useState(DEFAULT.recentIDs);

  const [_lists, _setLists] = useState(DEFAULT.lists);

  const [_options, _setOptions] = useState(DEFAULT.options);

  const [_toolbarFilters, _setToolbarFilters] = useState(
    DEFAULT.toolbarFilters
  );

  const [_sidebarFilters, _setSidebarFilters] = useState(
    DEFAULT.sidebarFilters
  );

  const [_rawLists, _setRawLists] = useState(DEFAULT.lists);

  /** assumes that pitch lists (that the user can access) have been loaded */
  const _changeActive = async (config: { trigger: string; listID: string }) => {
    try {
      if (_loading) {
        console.debug(
          'skipping _changeActive because context is already loading something'
        );
        return;
      }

      if (_lists.length === 0) {
        console.debug('skipping _changeActive because no lists are loaded');
        return;
      }

      const nextActive = _lists.find((l) => l._id === config.listID);
      if (!nextActive) {
        /** warning should show if lists is loaded but doesn't contain the target
         * e.g. trying to use a URL for a list that the user shouldn't access
         * e.g. user reassigned their active list and it's no longer accessible
         */
        NotifyHelper.warning({
          message_md: `You do not have access to list \`${config.listID}\`.`,
        });
        sectionsCx.tryGoHome();
        return;
      }

      // deserialize to ensure useEffect triggers
      _setActive({ ...nextActive });
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md:
          'There was an error while preparing your pitch list. Please try again.',
      });
    }
  };

  const [collapseKey, setCollapseKey] = useState(Date.now());

  const _sidebarFilterKeys = useMemo(
    () => StringHelper.keyify(_sidebarFilters.search),
    [_sidebarFilters.search]
  );

  const _sidebarRootFolders = useMemo(() => {
    const allFiles = _lists
      .map((l) => {
        const safePathComps = `${l.folder}`
          .split('/')
          .map((s) => s.trim())
          .filter((s) => s.length > 0);

        const file: ISidebarPitchList = {
          type: l.type,

          _parent_def: l._parent_def,
          _parent_field: l._parent_field,
          _parent_id: l._parent_id,

          pathComponents: safePathComps,
          pathDisplay: safePathComps.join('/'),
          pathEnd: safePathComps.slice(-1)[0] ?? '',

          name: l.name,
          object: l,
        };

        return file;
      })
      .sort((a, b) => {
        const aIndex = SORTED_PARENT_DEFS.findIndex((d) => d === a._parent_def);
        const bIndex = SORTED_PARENT_DEFS.findIndex((d) => d === b._parent_def);
        return aIndex < bIndex ? -1 : 1;
      });

    const filteredFiles = allFiles.filter(
      (f) =>
        _sidebarFilterKeys.length === 0 ||
        ArrayHelper.hasSubstringIntersection(
          SidebarHelper.getFileKeys(f),
          _sidebarFilterKeys
        )
    );

    const foldersDict: { [key: string]: ISidebarPitchList[] } = {
      ...ArrayHelper.groupBy(
        filteredFiles.filter((f) => !f.type),
        '_parent_id'
      ),
      ...ArrayHelper.groupBy(
        filteredFiles.filter((f) => f.type),
        'type'
      ),
    };

    // 0 => there is no folder value
    const depth = 0;

    const allFolders = Object.values(foldersDict).map((values) =>
      SidebarHelper.makeFolder(
        depth,
        [],
        values.filter((v) => v.pathComponents.length === depth),
        values.filter((v) => v.pathComponents.length > depth)
      )
    );

    const filteredFolders = allFolders.filter(
      (f) => f.files.length > 0 || f.folders.length > 0
    );

    return filteredFolders;
  }, [_lists, _sidebarFilters.category, _sidebarFilterKeys]);

  const state: IPitchListsContext = {
    lastFetched: _lastFetched,

    collapseKey: collapseKey,
    collapseFolders: () => setCollapseKey(Date.now()),

    loading: _loading,

    active: _active,

    recentIDs: _recentIDs,

    lists: _lists,

    toolbarFilters: _toolbarFilters,
    setToolbarFilters: (value) =>
      _setToolbarFilters({
        ..._toolbarFilters,
        ...value,
      }),

    sidebarFilters: _sidebarFilters,
    setSidebarFilters: (value) =>
      _setSidebarFilters({
        ..._sidebarFilters,
        ...value,
      }),

    sidebarFilterKeys: _sidebarFilterKeys,
    sidebarRootFolders: _sidebarRootFolders,

    options: _options,

    deleteLists: async (ids) => {
      try {
        _setLoading(true);

        const result = await PitchListsService.getInstance()
          .deleteLists(ids)
          .finally(() => _setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: `Pitch ${ids.length === 1 ? 'list' : 'lists'} deleted!`,
        });

        setTimeout(() => {
          /** remove deleted lists from session */
          const currentLists = _lists.filter((l) => !ids.includes(l._id));
          _setRawLists(currentLists);

          // don't linger on a deleted list
          if (_active && ids.includes(_active._id)) {
            sectionsCx.tryGoHome();
          }
        }, 500);

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error ? e.message : t('common.request-failed-msg'),
        });

        return false;
      }
    },

    updateList: async (config: {
      payload: Partial<IPitchList>;
      silently?: boolean;
    }) => {
      try {
        if (!config.silently) {
          _setLoading(true);
        }

        const result = await PitchListsService.getInstance()
          .putList(config.payload)
          .finally(() => {
            if (!config.silently) {
              _setLoading(false);
            }
          });

        if (!result.success) {
          if (!config.silently) {
            NotifyHelper.warning({
              message_md:
                result.error ??
                `There was an error updating your pitch list. ${ERROR_MSGS.CONTACT_SUPPORT}`,
            });
          }
          return;
        }

        const updated = result.data as IPitchList;

        if (!updated) {
          if (!config.silently) {
            NotifyHelper.error({
              message_md: 'Server responded with an empty result.',
            });
          }
          return;
        }

        const index = _lists.findIndex((l) => l._id === updated._id);
        if (index !== -1) {
          /** replace pitch list in lists */
          const currentLists = _lists.filter((l) => l._id !== updated._id);
          currentLists.push(updated);
          _setRawLists(currentLists);
        }

        /** keep active synced with whatever is in lists, e.g. if its folder is renamed */
        if (_active?._id === updated._id) {
          _setActive({
            ..._active,
            ...updated,
          });
        }

        if (!config.silently) {
          NotifyHelper.success({
            message_md: 'Pitch list updated!',
          });
        }

        return updated;
      } catch (e) {
        console.error(e);

        if (!config.silently) {
          NotifyHelper.error({
            message_md: `There was an unexpected error updating your pitch list. ${ERROR_MSGS.CONTACT_SUPPORT}`,
          });
        }
      }
    },

    updateListViaCSV: async (files) => {
      try {
        if (!_active) {
          NotifyHelper.error({
            message_md: 'Cannot import file without an active pitch list.',
          });
          return false;
        }

        _setLoading(true);

        await PitchListsService.getInstance()
          .importCSV(_active._id, files)
          .finally(() => _setLoading(false));

        _setLastFetched(Date.now());

        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    uploadAvatar: async (files, onProgress) => {
      try {
        if (!_active) {
          return;
        }

        /** append the files */
        const formData = new FormData();
        files.forEach((f) => {
          formData.append('files', f);
        });

        _setLoading(true);

        const result = await PitchListsService.getInstance()
          .postCardAvatar(_active._id, formData, onProgress)
          .finally(() => _setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        _setActive(result.data);

        return result.data as IPitchList;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : 'There was an error uploading your image.',
        });

        return undefined;
      }
    },

    updateLists: async (payload, successMsg) => {
      try {
        _setLoading(true);

        const result = await PitchListsService.getInstance()
          .putLists(payload)
          .finally(() => _setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: successMsg ?? 'Pitch lists updated!',
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error updating your pitch lists. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return false;
      }
    },

    renameFolder: async (payload, successMsg) => {
      try {
        _setLoading(true);

        const result = await PitchListsService.getInstance()
          .renameFolder(payload)
          .finally(() => _setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: successMsg ?? 'Folder renamed!',
        });

        /** triggers reload of rawLists => lists */
        _setLastFetched(Date.now());

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error renaming your folder. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return false;
      }
    },

    createList: async (payload) => {
      try {
        _setLoading(true);

        const result = await PitchListsService.getInstance()
          .postList(payload)
          .finally(() => _setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        const newList = result.data as IPitchList;
        const currentLists = [..._lists];
        currentLists.push(newList);

        _setRawLists(currentLists);

        NotifyHelper.success({
          message_md: `Pitch list "${newList.name}" created!`,
        });

        return newList;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error creating your pitch list. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return undefined;
      }
    },

    copyList: async (payload) => {
      try {
        _setLoading(true);

        const result = await PitchListsService.getInstance()
          .copyList(payload)
          .finally(() => _setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        const list = result.data as IPitchList;

        const newLists = [..._lists, list];

        _setRawLists(newLists);

        NotifyHelper.success({
          message_md: `Pitch list "${list.name}" copied!`,
        });

        return list;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error copying your pitch list. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return undefined;
      }
    },

    copyLists: async (payload, successMsg) => {
      try {
        _setLoading(true);

        const result = await PitchListsService.getInstance()
          .copyLists(payload)
          .finally(() => _setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: successMsg ?? 'Pitch lists copied!',
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error copying your pitch lists. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return false;
      }
    },

    refreshLists: (notify) => {
      if (notify) {
        NotifyHelper.success({ message_md: 'Refreshing pitch lists...' });
      }
      _setLastFetched(Date.now());
    },
  };

  /** reload the data whenever machineID changes to get relevant machine-only lists */
  useEffect(() => {
    _setLoading(true);

    PitchListsService.getInstance()
      .getVisible(authCx.current.role, authCx.current.mode)
      .then((result) => {
        if (!result) {
          _setRawLists([]);
          return;
        }

        const filtered = result.filter((l) => {
          if (l.type) {
            return true;
          }

          switch (l._parent_def) {
            case PitchListOwner.Team: {
              return authCx.current.team_lists;
            }

            case PitchListOwner.Machine: {
              return authCx.current.machine_lists;
            }

            /** always allow personal lists */
            case PitchListOwner.User: {
              return true;
            }

            /** suppress any malformed entries */
            default: {
              return false;
            }
          }
        });

        _setRawLists(filtered);
      })
      .finally(() => _setLoading(false));
  }, [
    _lastFetched,
    /** anything that might result in different pitch mss should trigger a reload */
    machineCx.machine.machineID,
    machineCx.machine.ball_type,
    authCx.current.session,
    authCx.current.role,
    authCx.current.mode,
    authCx.current.team_lists,
    authCx.current.machine_lists,
  ]);

  // monitor route fragment to change active list
  useEffect(() => {
    if (_lists.length === 0) {
      return;
    }

    if (!authCx.current.auth) {
      return;
    }

    if (sectionsCx.active.section !== SectionName.Pitches) {
      return;
    }

    if (sectionsCx.active.subsection !== SubSectionName.List) {
      return;
    }

    if (!sectionsCx.active.fragments) {
      return;
    }

    if (sectionsCx.active.fragments.length === 0) {
      return;
    }

    _changeActive({
      trigger: 'lists context, detected route fragment',
      listID: sectionsCx.active.fragments?.[0],
    });
  }, [
    _lists,
    authCx.current.auth,
    sectionsCx.active.section,
    sectionsCx.active.subsection,
    sectionsCx.active.fragments,
  ]);

  /** keep track of the most recently activated pitch lists */
  useEffect(() => {
    if (!_active) {
      return;
    }

    const recent = _recentIDs.filter((id) => id !== _active._id);
    recent.splice(0, 0, _active._id);

    _setRecentIDs(recent.filter((_, i) => i < RECENT_LENGTH));
  }, [_active]);

  useEffect(() => {
    if (!authCx.current.auth) {
      return;
    }

    _setLastFetched(Date.now());
  }, [
    /** trigger once logged in/successfully resumed */
    authCx.current.auth,
    /** detect special session mode, reload data to match user's access */
    authCx.current.session,
  ]);

  /** refresh the list (which will populate msDict if necessary) whenever machine changes */
  useEffect(() => {
    if (!_active) {
      return;
    }

    _changeActive({
      trigger: 'machine context changed',
      listID: _active._id,
    });
  }, [machineCx.machine.machineID, machineCx.machine.ball_type]);

  /** automatically clean up recent IDs and the folders from rawLists before setting lists for use */
  useEffect(() => {
    /** e.g. a list is deleted, should not show up in recent lists anymore */
    const rawIDs = _rawLists.map((l) => l._id);
    _setRecentIDs(_recentIDs.filter((id) => rawIDs.includes(id)));

    const cleanLists = sanitizeFolders(_rawLists);
    _setLists(cleanLists);

    _setOptions({
      name: ArrayHelper.unique(cleanLists.map((l) => l.name)),

      _created: ArrayHelper.unique(
        cleanLists.map((l) => lightFormat(parseISO(l._created), 'yyyy-MM-dd'))
      ),
    });
  }, [_rawLists, machineCx.machine.machineID]);

  return (
    <PitchListsContext.Provider value={state}>
      {props.children}
    </PitchListsContext.Provider>
  );
};
