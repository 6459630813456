import { Cross2Icon, UploadIcon } from '@radix-ui/react-icons';
import { Box, Button, Flex } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { CommonProgress } from 'components/common/progress';
import { DefaultTFuncReturn, t } from 'i18next';
import { IFileInput } from 'interfaces/forms/file';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

type NotifyMode = 'aggregate' | 'each';

const BYTES_PER_MB = 1048576;

/** filters list of files based on size and format rules,
 * (optionally) shows notifications for any excluded files along the way
 */
const filterFiles = (config: {
  allFiles: File[];
  fileTypes?: string[];
  maxMB?: number;
  notifyMode?: NotifyMode;
}): File[] => {
  const output: File[] = [];

  /** count number of times each error was tripped */
  const aggregateErrors = {
    size: 0,
    format: 0,
  };

  /** loop over each file, checking each condition */
  config.allFiles.forEach((f) => {
    /** (optional) size check */
    if (config.maxMB !== undefined) {
      /** only use files under size limit, show warning about the rest */
      const maxBytes = config.maxMB * BYTES_PER_MB;

      if (f.size > maxBytes) {
        aggregateErrors.size++;

        if (config.notifyMode === 'each') {
          NotifyHelper.warning({
            message_md: `"${f.name}" will be skipped (larger than ${config.maxMB} MB).`,
          });
        }

        return;
      }
    }

    /** format check, if necessary */
    if (config.fileTypes && !config.fileTypes.includes(f.type)) {
      aggregateErrors.format++;

      if (config.notifyMode === 'each') {
        NotifyHelper.warning({
          message_md: `"${f.name}" will be skipped (unsupported format: ${f.type}).`,
        });
      }

      return;
    }

    output.push(f);
  });

  if (config.notifyMode === 'aggregate') {
    if (aggregateErrors.format > 0) {
      NotifyHelper.warning({
        message_md: `${aggregateErrors.format} file(s) skipped (unsupported format).`,
      });
    }

    if (aggregateErrors.size > 0) {
      NotifyHelper.warning({
        message_md: `${aggregateErrors.size} file(s) skipped (larger than ${config.maxMB} MB).`,
      });
    }
  }

  if (output.length === 0) {
    NotifyHelper.warning({
      message_md: 'No provided files are valid for this operation.',
    });
  }

  return output;
};

interface IProps extends ISimpleProps {
  /** provide to give hover tooltip on upload button */
  notes?: string | DefaultTFuncReturn;

  /** maximum size (per file) in MB */
  maxMB?: number;

  /** 0-100 */
  progress: number;
  progressLabel?: string;
}

interface ISimpleProps extends IFileInput {
  /** hides the input form visually, the elements are still part of the DOM for click events */
  hidden?: boolean;

  notifyMode?: NotifyMode;

  placeholder?: string;
}

/** has progress bar (optional) and tooltips (optional), composes CommonSimpleFileUploader */
export class CommonFileUploader extends React.Component<IProps> {
  private fileInput?: CommonSimpleFileUploader;

  constructor(props: IProps) {
    super(props);

    this.getHasFiles = this.getHasFiles.bind(this);
    this.getTooltip = this.getTooltip.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  getHasFiles(): boolean {
    return !!this.fileInput?.getHasFiles();
  }

  handleClick() {
    this.fileInput?.handleClick();
  }

  getTooltip() {
    const tooltip = [
      this.props.notes,
      this.props.maxMB
        ? t('common.maximum-file-size-x', { x: `${this.props.maxMB} MB` })
        : undefined,
    ]
      .filter((s) => s)
      .join('\n\n');

    return tooltip;
  }

  render() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        <Box title={this.getTooltip()}>
          <CommonSimpleFileUploader
            ref={(ref) => (this.fileInput = ref as CommonSimpleFileUploader)}
            {...this.props}
          />
        </Box>

        {(this.props.progressLabel || this.props.progress > 0) && (
          <CommonProgress
            value={this.props.progress}
            label={
              this.props.progressLabel ?? `${this.props.progress.toFixed(2)}%`
            }
          />
        )}
      </Flex>
    );
  }
}

interface ISimpleState {
  loading: boolean;
  hasFiles: boolean;
}

export class CommonSimpleFileUploader extends React.Component<
  ISimpleProps,
  ISimpleState
> {
  private formRef = React.createRef<HTMLFormElement>();
  private inputRef = React.createRef<HTMLInputElement>();

  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
      hasFiles: false,
    };

    this.getHasFiles = this.getHasFiles.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getHasFiles(): boolean {
    return this.state.hasFiles;
  }

  private async onChange(e: any) {
    const files = filterFiles({
      allFiles: [...e.target.files],
      fileTypes: this.props.acceptedTypes,
      notifyMode: this.props.notifyMode,
    });

    if (files.length === 0) {
      return;
    }

    this.setState(
      {
        loading: true,
      },
      () => {
        this.props
          .onChange(files)
          .then(() => {
            this.setState({ loading: false, hasFiles: true });
          })
          .catch((error) => {
            console.error(error);
            this.setState({ loading: false, hasFiles: false });
          });
      }
    );
  }

  handleClear() {
    if (!this.formRef?.current || !this.inputRef?.current) {
      NotifyHelper.warning({
        message_md: 'File input was not ready yet, please try again.',
      });
      return;
    }

    // upload new files
    if (!this.state.hasFiles) {
      // reset first so that uploading the same filename still triggers onChange
      this.formRef.current.reset();
      this.inputRef.current.click();
      return;
    }

    // reset the input and update the hasFiles flag
    this.formRef.current.reset();
    this.setState({ hasFiles: false });
  }

  handleClick() {
    if (!this.formRef?.current || !this.inputRef?.current) {
      NotifyHelper.warning({
        message_md: 'File input was not ready yet, please try again.',
      });
      return;
    }

    // upload new files
    if (!this.state.hasFiles) {
      // reset first so that uploading the same filename still triggers onChange
      this.formRef.current.reset();
      this.inputRef.current.click();
      return;
    }

    // reset the input and update the hasFiles flag
    this.formRef.current.reset();
    this.setState({ hasFiles: false });
  }

  render() {
    const icon = this.state.hasFiles ? <Cross2Icon /> : <UploadIcon />;

    const label = this.state.hasFiles
      ? 'common.start-over'
      : this.props.multiple
      ? 'common.select-files'
      : 'common.select-a-file';

    return (
      <>
        <form ref={this.formRef} hidden>
          <input
            type="file"
            ref={this.inputRef}
            onChange={(e) => this.onChange(e)}
            accept={this.props.acceptedTypes?.join(',')}
            multiple={this.props.multiple}
          />
        </form>

        {!this.props.hidden && (
          <CommonInputWrapper {...this.props}>
            <CommonInputLabel {...this.props} />

            <Button
              variant="soft"
              title={
                this.state.hasFiles
                  ? t('common.clear-selection').toString()
                  : undefined
              }
              onClick={() => {
                this.state.hasFiles ? this.handleClear() : this.handleClick();
              }}
            >
              {icon} {t(label)}
            </Button>

            <CommonInputHint {...this.props} />
          </CommonInputWrapper>
        )}
      </>
    );
  }
}
