import { Button } from '@radix-ui/themes';
import { MachineCalibrateButton } from 'components/machine/buttons/calibrate';
import { MachineUnavailableButton } from 'components/machine/buttons/unavailable';
import { QueueContext } from 'components/sections/pitch-list/queue.context';
import { usePitchListStore } from 'components/sections/pitch-list/store/use-pitch-list-store';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { MachineButtonMode } from 'enums/machine.enums';
import { t } from 'i18next';
import { PitchListDialog } from 'lib_ts/enums/pitch-list.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { useContext } from 'react';
import { useShallow } from 'zustand/react/shallow';

export const PitchListSidebarMachineButton = (props: {
  className: string;
  mode: MachineButtonMode;
  pitch: IPitch | undefined;
  handleTrainPitches: (config: {
    ids: string[];
    promptRefresh: boolean;
  }) => void;
}) => {
  const { resetMSHash } = useContext(MachineContext);
  const { loading, readyToTrain } = useContext(MatchingShotsContext);
  const { sendSelected } = useContext(QueueContext);

  const listStore = usePitchListStore(
    useShallow(({ openDialog }) => ({
      openDialog,
    }))
  );

  switch (props.mode) {
    case MachineButtonMode.Unavailable: {
      return <MachineUnavailableButton className={props.className} />;
    }

    case MachineButtonMode.Calibrate: {
      return <MachineCalibrateButton className={props.className} />;
    }

    case MachineButtonMode.Train: {
      return (
        <Button
          className={props.className}
          color={RADIX.COLOR.TRAIN_PITCH}
          disabled={!readyToTrain()}
          onClick={() => {
            if (!props.pitch) {
              return;
            }

            props.handleTrainPitches({
              ids: [props.pitch._id],
              promptRefresh: true,
            });
          }}
        >
          {t('common.train-pitch')}
        </Button>
      );
    }

    case MachineButtonMode.Refresh: {
      if (!props.pitch) {
        return <></>;
      }

      return (
        <Button
          color={RADIX.COLOR.WARNING}
          className={props.className}
          onClick={async () => {
            resetMSHash();

            if (!props.pitch) {
              return;
            }

            listStore.openDialog({
              dialog: PitchListDialog.DialogResetList,
              pitches: [props.pitch],
            });
          }}
        >
          {t('pl.refresh-model')}
        </Button>
      );
    }

    case MachineButtonMode.NoPitch:
    case MachineButtonMode.Send: {
      return (
        <Button
          color={RADIX.COLOR.SEND_PITCH}
          className={props.className}
          disabled={loading || props.mode === MachineButtonMode.NoPitch}
          onClick={() => sendSelected('send button', false)}
        >
          {t('common.load-pitch')}
        </Button>
      );
    }

    case MachineButtonMode.Fire:
    default: {
      return <></>;
    }
  }
};
