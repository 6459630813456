import { TextField } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonTextHint } from 'components/common/form/text-hint';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { t } from 'i18next';
import { ITextInput } from 'interfaces/forms/text';
import { safeNumber } from 'lib_ts/classes/math.utilities';

export const CommonTextInput = (props: ITextInput) => (
  <CommonInputWrapper {...props}>
    <CommonInputLabel {...props} />

    <TextField.Root
      size={props.size}
      autoComplete={props.autoComplete}
      className={StringHelper.classNames([
        props.className,
        props.monospace ? 'font-mono' : undefined,
      ])}
      color={props.inputColor}
      disabled={props.disabled}
      maxLength={props.maxLength}
      name={props.name}
      onChange={(e) => {
        const v = e.target.value;
        if (props.onChange) {
          props.onChange(v);
        }

        if (props.onNumericChange) {
          if (!v) {
            props.onNumericChange(0);
          } else {
            const maybeNum = safeNumber(v);
            if (maybeNum !== undefined) {
              props.onNumericChange(maybeNum);
            }
          }
        }
        if (props.onOptionalNumericChange) {
          const maybeNum = safeNumber(v);
          props.onOptionalNumericChange(maybeNum);
        }
      }}
      onWheel={(e) => {
        if (props.type === 'number') {
          // prevent scrolling from modifying the number value
          (e.target as HTMLElement).blur();
        }
      }}
      onKeyUp={(e) => {
        props.onKeyUp?.({
          code: e.code,
          ctrl: e.ctrlKey,
          alt: e.altKey,
          shift: e.shiftKey,
        });
      }}
      placeholder={
        props.placeholder ? t(props.placeholder).toString() : undefined
      }
      readOnly={props.readOnly}
      required={!props.optional}
      type={props.type ?? 'text'}
      value={props.value}
    >
      {props.leftSlot && (
        <TextField.Slot side="left">{props.leftSlot}</TextField.Slot>
      )}
      {props.rightSlot && (
        <TextField.Slot side="right">{props.rightSlot}</TextField.Slot>
      )}
    </TextField.Root>

    <CommonTextHint {...props} />
  </CommonInputWrapper>
);
