import { NotifyHelper } from 'classes/helpers/notify.helper';
import { AuthContext } from 'contexts/auth.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { ErrorLevel } from 'lib_ts/enums/errors.enums';
import { IErrorType } from 'lib_ts/interfaces/common/i-error-type';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AdminErrorTypesService } from 'services/admin/error-types.service';

const CONTEXT_NAME = 'AdminErrorTypesContext';

interface IFilters {
  errorIDs: string[];
  levels: ErrorLevel[];
  internal?: boolean;
}

interface IOptionsDict {
  errorIDs: IOption[];
  levels: IOption[];
  _created: string[];
}

export interface IErrorTypesContext {
  filters: IFilters;
  setFilters: (value: Partial<IFilters>) => void;

  filtered: IErrorType[];
  loading: boolean;

  options: IOptionsDict;

  /** changes lastFetched date which auto-triggers data to be fetched */
  readonly refresh: () => void;

  readonly create: (payload: Partial<IErrorType>) => Promise<boolean>;
  readonly update: (payload: Partial<IErrorType>) => Promise<boolean>;
}

const DEFAULT: IErrorTypesContext = {
  filters: {
    errorIDs: [],
    levels: [],
    internal: undefined,
  },
  setFilters: () => console.error(`${CONTEXT_NAME}: not init`),
  filtered: [],

  options: {
    errorIDs: [],
    levels: [],
    _created: [],
  },

  loading: false,

  refresh: () => console.error(`${CONTEXT_NAME}: not init`),
  create: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  update: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
};

const getOptions = (data: IErrorType[]): IOptionsDict => {
  if (data) {
    return {
      errorIDs: data.map((m) => {
        const o: IOption = {
          label: m.errorID,
          group: m.category,
          value: m.errorID,
        };

        return o;
      }),

      levels: Object.values(ErrorLevel)
        .filter((l) => data.findIndex((m) => m.level === l) !== -1)
        .map((l) => {
          const o: IOption = {
            label: l.toUpperCase(),
            color: NotifyHelper.getColorFromLevel(l),
            value: l,
          };
          return o;
        }),

      _created: ArrayHelper.unique(
        data.map((m) => lightFormat(parseISO(m._created), 'yyyy-MM-dd'))
      ),
    };
  } else {
    return DEFAULT.options;
  }
};

export const ErrorTypesContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const ErrorTypesProvider: FC<IProps> = (props) => {
  const [_lastFetched, _setLastFetched] = useState(new Date());

  const [_errorTypes, _setErrorTypes] = useState<IErrorType[]>([]);

  const [_filters, _setFilters] = useState(DEFAULT.filters);
  const _filtered = useMemo(
    () =>
      _errorTypes
        .filter(
          (m) =>
            _filters.errorIDs.length === 0 ||
            _filters.errorIDs.includes(m.errorID)
        )
        .filter(
          (m) =>
            _filters.levels.length === 0 || _filters.levels.includes(m.level)
        )
        .filter(
          (m) =>
            _filters.internal === undefined || _filters.internal === m.internal
        ),
    [_errorTypes, _filters]
  );

  const _options = useMemo(() => getOptions(_errorTypes), [_errorTypes]);

  const [_loading, _setLoading] = useState(DEFAULT.loading);

  const state: IErrorTypesContext = {
    filters: _filters,
    setFilters: (v) => _setFilters({ ..._filters, ...v }),

    filtered: _filtered,

    options: _options,

    loading: _loading,

    refresh: () => {
      _setLastFetched(new Date());
    },

    create: async (payload) => {
      try {
        _setLoading(true);
        const result =
          await AdminErrorTypesService.getInstance().create(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const newValue: IErrorType = result.data;
        _setErrorTypes([..._errorTypes, newValue]);

        NotifyHelper.success({
          message_md: `\`${newValue.errorID}\` created!`,
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error ? e.message : t('common.request-failed-msg'),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    update: async (payload) => {
      try {
        _setLoading(true);

        const result =
          await AdminErrorTypesService.getInstance().update(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const team = result.data as IErrorType;
        const nextValues = [..._errorTypes];

        const index = nextValues.findIndex((v) => v._id === team._id);
        if (index !== -1) {
          /** replace current context value with updated result */
          nextValues.splice(index, 1, team);
        } else {
          /** append to end */
          nextValues.push(team);
        }

        _setErrorTypes(nextValues);

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },
  };

  /** fetch the data whenever lastFetched changes */
  useEffect(() => {
    (async (): Promise<void> => {
      _setLoading(true);

      return AdminErrorTypesService.getInstance()
        .getAll()
        .then((result) => {
          _setErrorTypes(
            result.sort((a, b) =>
              (a.errorID ?? '').localeCompare(b.errorID ?? '')
            )
          );
        })
        .finally(() => _setLoading(false));
    })();
  }, [_lastFetched]);

  const { current } = useContext(AuthContext);

  /** reload data to match session access */
  useEffect(() => {
    /** trigger refresh */
    _setLastFetched(new Date());
  }, [current.session]);

  return (
    <ErrorTypesContext.Provider value={state}>
      {props.children}
    </ErrorTypesContext.Provider>
  );
};
