import { Badge } from '@radix-ui/themes';
import { CommonSearchInput } from 'components/common/form/search';
import { HittersContext } from 'contexts/hitters.context';
import { CrudAction } from 'enums/tables';
import { t } from 'i18next';
import { IOptionExt } from 'interfaces/forms/option-ext';
import { HITTER_SIDES, HitterSide } from 'lib_ts/enums/hitters.enums';
import { PLAYER_LEVELS } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useMemo } from 'react';

const CREATE_HITTER_ID = '----create----';

export const SearchHitter = () => {
  const { active, filtered, lastChanged, openCrudDialog, setActive } =
    useContext(HittersContext);

  const levelsDict = useMemo(() => {
    const dict: { [level: string]: string } = {};

    PLAYER_LEVELS.forEach((m) => {
      dict[m.value] = t(m.label);
    });

    return dict;
  }, [PLAYER_LEVELS]);

  const sidesDict = useMemo(() => {
    const dict: { [side: string]: string } = {};

    HITTER_SIDES.forEach((m) => {
      dict[m.value] = t(m.label);
    });

    return dict;
  }, [HITTER_SIDES]);

  return (
    <CommonSearchInput
      key={lastChanged}
      id="hitters-hitter"
      placeholder="common.hitter"
      options={[
        {
          label: t('hitters.add-a-new-hitter'),
          value: CREATE_HITTER_ID,
        },
        ...filtered.map((h) => {
          const isLeft = h.side === HitterSide.LHH;

          const o: IOptionExt = {
            label: h.name,
            value: h._id,
            group: `${t('common.level')}: ${levelsDict[h.level]}`,
            suffixLabel: sidesDict[h.side],
            suffix: (
              <Badge color={isLeft ? RADIX.COLOR.LEFT : RADIX.COLOR.RIGHT}>
                {sidesDict[h.side]}
              </Badge>
            ),
          };

          return o;
        }),
      ]}
      values={active ? [active._id] : []}
      onChange={(v) => {
        if (v.length === 0) {
          setActive(undefined);
          return;
        }

        const hitterID = v[0];

        if (!hitterID) {
          return;
        }

        if (hitterID === CREATE_HITTER_ID) {
          openCrudDialog({
            key: Date.now(),
            action: CrudAction.Create,
            models: [],
          });
          return;
        }

        setActive(filtered.find((h) => h._id === hitterID)?._id);
      }}
      optional
    />
  );
};
