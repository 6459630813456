import { Box, Flex, Text } from '@radix-ui/themes';
import { endOfYesterday, lightFormat } from 'date-fns';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const GameDataFooter = () => {
  const datestamp = endOfYesterday();

  return (
    <Flex justify="between" gap={RADIX.FLEX.GAP.SECTION}>
      <Box>
        <Text size={RADIX.TEXT.SIZE.XS}>
          {t('common.last-updated')}:{' '}
          {lightFormat(endOfYesterday(), 'yyyy-MM-dd')}
        </Text>
      </Box>
      <Box>
        <Text size={RADIX.TEXT.SIZE.XS}>{t('common.mlb-copyright')}</Text>
      </Box>
    </Flex>
  );
};
