import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PitchListSidebar } from 'components/sections/pitch-list/sidebar';
import { AimingContext } from 'contexts/aiming.context';
import { GameDataContext } from 'contexts/game-data.context';
import { MachineContext } from 'contexts/machine.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { useCallback, useContext } from 'react';

const COMPONENT_NAME = 'GameDataPitchesSidebar';

export const GameDataPitchesSidebar = () => {
  const { getVideo } = useContext(VideosContext);
  const { resetMSHash } = useContext(MachineContext);
  const { pitch, setPitch } = useContext(AimingContext);
  const { openAddDialog } = useContext(GameDataContext);

  const handleVideoChange = useCallback(
    (videoID: string | undefined) => {
      if (!pitch) {
        return;
      }

      const changed = pitch.video_id !== videoID;

      if (!changed) {
        return;
      }

      setPitch({
        ...pitch,
        video_id: videoID ?? '',
      });

      resetMSHash();
    },
    [pitch, setPitch, resetMSHash]
  );

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <PitchListSidebar
        onMatchesChanged={() => {
          // nothing
        }}
        onVideoChanged={(video_id) => {
          if (!pitch) {
            return;
          }

          const changed = pitch.video_id !== video_id;
          if (!changed) {
            return;
          }

          if (video_id) {
            const video = getVideo(video_id);

            if (!video) {
              NotifyHelper.error({
                message_md: `Video \`${video_id}\` does not exist in context. Please try again.`,
              });
              return;
            }

            const warnings = VideoHelper.validateSelection({
              pitch_name: pitch.name,
              position: pitch.bs,
              video: video,
            });

            if (warnings.length > 0) {
              NotifyHelper.warning({
                message_md: warnings[0],
                inbox: true,
              });
            }
          }

          handleVideoChange(video_id);
        }}
        handleTrainPitches={() => {
          // nothing
        }}
        handleSave={(pitch) =>
          openAddDialog({
            pitches: [pitch],
          })
        }
        enableSave
      />
    </ErrorBoundary>
  );
};
