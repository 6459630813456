export enum CustomIconPath {
  AddAverages = 'AddAverages',
  AddPitch = 'AddPitch',
  ArrowAxis = 'ArrowAxis',
  Baseball = 'Baseball',
  Beta = 'Beta',
  BetaBeaker = 'BetaBeaker',
  CalibrateDevice = 'CalibrateDevice',
  CheckCircledFilled = 'CheckCircledFilled',
  CollapseAll = 'CollapseAll',
  FolderClosed = 'FolderClosed',
  FolderOpen = 'FolderOpen',
  Helmet = 'Helmet',
  R2F = 'R2F',
  RepeatOne = 'RepeatOne',
  RowsAdd = 'RowsAdd',
  SuperAdmin = 'SuperAdmin',
  SwitchOff = 'SwitchOff',
  SwitchOn = 'SwitchOn',
  TeamAdmin = 'TeamAdmin',
  Void = 'Void',

  // machine states
  BusyMachine = 'BusyMachine',
  ConnectedMachine = 'ConnectedMachine',
  DisconnectedMachine = 'DisconnectedMachine',
  LowerMachine = 'LowerMachine',
  ResetMachine = 'ResetMachine',
}
