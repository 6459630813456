import { CommonSelectInput } from 'components/common/form/select';
import { HittersContext } from 'contexts/hitters.context';
import { PLAYER_LEVELS, PlayerLevel } from 'lib_ts/enums/pitches.enums';
import { useContext } from 'react';

export const SelectHitterLevel = () => {
  const { loading, filters, setFilters } = useContext(HittersContext);

  return (
    <CommonSelectInput
      id="hitters-level"
      name="level"
      placeholder="common.level"
      options={PLAYER_LEVELS}
      value={filters.level}
      onChange={(v) => {
        const current = filters;

        if (!v) {
          delete current.level;
        } else {
          current.level = v as PlayerLevel;
        }

        setFilters({ ...current });
      }}
      disabled={loading}
      optional
      skipSort
    />
  );
};
