import { Avatar, Box, Flex, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import {
  DragHandle,
  DropHandle,
  IDropHandle,
} from 'components/common/drag-drop';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { SectionsContext } from 'contexts/sections.context';
import { DragItem, DropContainer } from 'enums/dnd.enums';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { ISidebarPitchList } from 'interfaces/i-sidebar';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitchList } from 'lib_ts/interfaces/pitches';
import { useContext, useMemo } from 'react';

const ENABLE_DRAGGING = false;

interface IProps {
  file: ISidebarPitchList;
  accept: DragItem;
}

export const PitchList = (props: IProps) => {
  const { active: aList, loading, updateList } = useContext(PitchListsContext);
  const { active: aSection, tryChangeSection } = useContext(SectionsContext);

  const safePath = useMemo(
    () => [props.file.pathEnd, props.file.name].filter((s) => !!s).join('/'),
    [props.file]
  );

  const isActiveList = useMemo(
    () =>
      aSection.section === SectionName.Pitches &&
      aSection.subsection === SubSectionName.List &&
      aSection.fragments?.[0] === props.file.object._id,
    [props.file.object._id, aSection]
  );

  const abbr = useMemo(
    () => props.file.name?.trim().substring(0, 1).toUpperCase() ?? '*',
    [props.file.name]
  );

  const className = useMemo(
    () =>
      StringHelper.classNames([
        'Entry',
        loading ? 'cursor-not-allowed' : 'cursor-pointer',
      ]),
    [loading]
  );

  const content = useMemo(
    () => (
      <Flex
        className={className}
        gap="2"
        p="2"
        mr="2"
        align="center"
        onClick={(e) => {
          // prevents click from collapsing parent folder
          e.stopPropagation();

          if (loading) {
            return;
          }

          if (
            !aList ||
            aList._id !== props.file.object._id ||
            aSection.section !== SectionName.Pitches ||
            aSection.subsection !== SubSectionName.List ||
            aSection.fragments?.[0] !== props.file.object._id
          ) {
            /** move user to pitch list (invisible) section */
            tryChangeSection({
              trigger: 'sidebar, open list',
              section: SectionName.Pitches,
              subsection: SubSectionName.List,
              fragments: [props.file.object._id],
            });
          }
        }}
      >
        <Box>
          <Avatar
            size="1"
            fallback={abbr}
            color={isActiveList ? RADIX.COLOR.ACCENT : RADIX.COLOR.NEUTRAL}
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        </Box>
        <Box
          // required for nested text truncation to work
          minWidth="0"
          flexGrow="1"
          title={safePath}
          data-testid="SidebarPitchList"
        >
          <DropHandle
            value={props.file}
            container={DropContainer.PitchList}
            accept={DragItem.Pitch}
          >
            <Text
              color={isActiveList ? RADIX.COLOR.ACCENT : undefined}
              // as div + overflow hidden allows truncate to take effect
              as="div"
              truncate
            >
              {props.file.name}
            </Text>
          </DropHandle>
        </Box>
      </Flex>
    ),
    [props.file, isActiveList, safePath, loading, aList, tryChangeSection]
  );

  if (!ENABLE_DRAGGING) {
    return (
      <ErrorBoundary componentName="PitchListItem">{content}</ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary componentName="PitchListItem">
      <DragHandle
        value={props.file.object}
        type={props.accept}
        endFn={(item, monitor) => {
          if (!item) {
            return;
          }

          const target = monitor.getDropResult<IDropHandle>();
          if (!target) {
            return;
          }

          const list: IPitchList = item.value;
          const targetFolder: string = target.value;

          if (typeof targetFolder !== 'string') {
            return;
          }

          if (list.folder === targetFolder) {
            return;
          }

          updateList({
            payload: {
              _id: list._id,
              folder: targetFolder,
            },
            successMsg: `Pitch list "${list.name}" moved to "${targetFolder}" folder!`,
          });
        }}
      >
        {content}
      </DragHandle>
    </ErrorBoundary>
  );
};
