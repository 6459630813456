import { ResetIcon } from '@radix-ui/react-icons';
import { Box, Grid } from '@radix-ui/themes';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTableButton } from 'components/common/table/button';
import { AuthContext } from 'contexts/auth.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import {
  PITCH_LIST_TYPES,
  PitchListExtType,
  TRAINING_STATUS_OPTIONS,
  TrainingStatus,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const PitchListsToolbar = () => {
  const { current } = useContext(AuthContext);
  const isSuperAdmin = current.role === UserRole.admin;

  const {
    toolbarFilters: filters,
    options,
    setToolbarFilters: setFilter,
  } = useContext(PitchListsContext);

  return (
    <Grid columns={isSuperAdmin ? '6' : '5'} gap={RADIX.FLEX.GAP.SM}>
      <Box>
        <CommonSearchInput
          key={`name-${filters.key}`}
          id="pitch-lists-name"
          placeholder="common.name"
          options={options.name.map((o) => ({
            label: o,
            value: o,
          }))}
          values={filters.names}
          onChange={(v) => {
            setFilter({ names: v });
          }}
          multiple
        />
      </Box>
      <Box>
        <CommonSearchInput
          key={`status-${filters.key}`}
          id="pitch-lists-status"
          placeholder="pl.status"
          options={TRAINING_STATUS_OPTIONS}
          values={filters.status}
          onChange={(v) => {
            setFilter({
              status: v as TrainingStatus[],
            });
          }}
          multiple
        />
      </Box>
      <Box>
        <CommonSelectInput
          key={`vis-${filters.key}`}
          id="pitch-lists-visibility"
          name="filter-visibility"
          placeholder="common.visibility"
          options={[
            {
              label: 'common.personal',
              value: PitchListOwner.User,
            },
            {
              label: 'common.machine',
              value: PitchListOwner.Machine,
            },
            {
              label: 'common.team',
              value: PitchListOwner.Team,
            },
          ]}
          value={filters.visibility}
          onChange={(v) =>
            setFilter({
              visibility: v as PitchListOwner,
            })
          }
          optional
        />
      </Box>
      {isSuperAdmin && (
        <Box>
          <CommonSelectInput
            key={`type-${filters.key}`}
            id="pitch-lists-type"
            name="filter-type"
            placeholder="pl.type"
            inputColor={RADIX.COLOR.SUPER_ADMIN}
            options={PITCH_LIST_TYPES}
            value={filters.type}
            onChange={(v) =>
              setFilter({
                type: v as PitchListExtType,
              })
            }
            optional
          />
        </Box>
      )}
      <Box>
        <CommonSearchInput
          key={`date-${filters.key}`}
          id="pitch-lists-date-added"
          placeholder="common.date-added"
          options={options._created.map((o) => ({
            label: o,
            value: o,
          }))}
          values={filters.created}
          onChange={(v) => {
            setFilter({ created: v });
          }}
          multiple
          reverseSort
        />
      </Box>
      <Box>
        <CommonTableButton
          label="common.reset"
          icon={<ResetIcon />}
          className="btn-block"
          variant="soft"
          onClick={() => {
            setFilter({
              names: [],
              status: [],
              created: [],
              visibility: undefined,
              type: undefined,
              key: Date.now(),
            });
          }}
        />
      </Box>
    </Grid>
  );
};
