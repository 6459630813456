import { Select, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { t } from 'i18next';
import { IOptionExt } from 'interfaces/forms/option-ext';
import { ISelectInput } from 'interfaces/forms/select';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { safeNumber } from 'lib_ts/classes/math.utilities';
import { useCallback, useEffect, useMemo, useState } from 'react';

const EMPTY_VALUE = '--EMPTY--';

const MAX_SELECT_OPTIONS = 30;

export const CommonSelectInput = (props: ISelectInput) => {
  const options = useMemo(() => {
    const output: IOptionExt[] = [];

    const grouped = ArrayHelper.groupBy(props.options, 'group');

    const keys = Object.keys(grouped);

    if (!props.skipSort) {
      keys.sort();

      if (props.reverseSort) {
        keys.reverse();
      }
    }

    keys.forEach((key) => {
      const group = grouped[key];

      if (group.length === 0) {
        return;
      }

      if (key) {
        output.push({
          label: key,
          value: key,
          group: key,
          isGroup: true,
          disabled: true,
          hideControl: true,
        });
      }

      if (!props.skipSort) {
        group.sort((a, b) => a.label.localeCompare(b.label));

        if (props.reverseSort) {
          group.reverse();
        }
      }

      output.push(...group);
    });

    return output;
  }, [props.options, props.skipSort, props.reverseSort]);

  useEffect(() => {
    if (!props.suppressWarning && options.length > MAX_SELECT_OPTIONS) {
      NotifyHelper.devWarning({
        message_md: `Select input \`${props.id}\` given too many options (${options.length} > ${MAX_SELECT_OPTIONS}); consider switching to a search input instead.`,
      });
    }
  }, [options]);

  const [key, setKey] = useState(Date.now());
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(props.value ?? EMPTY_VALUE);

  const onValueChange = useCallback(
    (value: string) => {
      if (props.onChange) {
        props.onChange(value);
      }

      if (props.onBooleanChange) {
        props.onBooleanChange([true, 'true'].includes(value));
      }

      if (props.onOptionalBooleanChange) {
        const isEmpty = ['', undefined].includes(value);

        props.onOptionalBooleanChange(
          isEmpty ? undefined : [true, 'true'].includes(value)
        );
      }

      if (props.onNumericChange) {
        const maybeNum = safeNumber(value);
        if (maybeNum !== undefined) {
          props.onNumericChange(maybeNum);
        }
      }

      if (props.onOptionalNumericChange) {
        const maybeNum = safeNumber(value);
        props.onOptionalNumericChange(maybeNum);
      }
    },
    [
      props.onChange,
      props.onBooleanChange,
      props.onOptionalBooleanChange,
      props.onNumericChange,
      props.onOptionalNumericChange,
    ]
  );

  const displayValue = useMemo(() => {
    if (!value || value === EMPTY_VALUE) {
      return;
    }

    const label = props.options.find(
      // otherwise numbers and booleans would not match
      (o) => `${o.value}` === value
    )?.label;

    if (label) {
      return t(label);
    }

    return value;
  }, [value, props.options]);

  const placeholder = t(
    props.placeholder ?? 'common.select-placeholder'
  ).toString();

  return (
    <CommonInputWrapper {...props}>
      <CommonInputLabel {...props} />

      <Select.Root
        key={key}
        data-testid={props.name}
        data-value={value}
        size={props.size}
        open={open}
        onOpenChange={(open) => setOpen(open)}
        name={props.name}
        // do not bind to value or it will cause an error
        defaultValue={value}
        disabled={props.disabled}
        onValueChange={(v) => {
          const shouldProceed = props.shouldChange
            ? props.shouldChange(v)
            : true;

          if (!shouldProceed) {
            // reset the input to the default value
            setKey(Date.now());
            return;
          }

          // update the local value
          setValue(v);

          // notify parent
          onValueChange(v === EMPTY_VALUE ? '' : v);
        }}
        required={!props.optional}
      >
        <Select.Trigger
          variant={props.variant}
          color={props.inputColor}
          title={props.title}
          className={props.className}
          disabled={props.disabled}
          placeholder={placeholder}
          style={{ width: '100%' }}
          value={displayValue}
        />

        <Select.Content className="BorderedContent">
          <Select.Group>
            {props.optional && (
              <Select.Item value={EMPTY_VALUE}>
                <Text
                  style={{
                    color: 'var(--gray-a10)',
                  }}
                >
                  {placeholder}
                </Text>
              </Select.Item>
            )}

            {options.map((option, index) => {
              if (option.disabled) {
                return (
                  <Select.Label key={index}>
                    <Text mr="1">{t(option.label)}</Text>
                    {option.suffix}
                  </Select.Label>
                );
              }

              return (
                <Select.Item
                  key={index}
                  value={option.value}
                  disabled={option.disabled}
                >
                  <Text mr="1" color={option.color}>
                    {t(option.label)}
                  </Text>
                  {option.suffix}
                </Select.Item>
              );
            })}
          </Select.Group>
        </Select.Content>
      </Select.Root>

      <CommonInputHint {...props} />
    </CommonInputWrapper>
  );
};
