import { CopyIcon, InfoCircledIcon, UpdateIcon } from '@radix-ui/react-icons';
import { Box, Flex, Grid, Strong, Text } from '@radix-ui/themes';
import { BreakCanvas } from 'classes/break-canvas';
import { AimingContextHelper } from 'classes/helpers/aiming-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { BreakView } from 'components/common/break-view';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTooltip } from 'components/common/tooltip';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { t } from 'i18next';
import { FT_TO_INCHES } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMSFromMSDict, getMergedMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  IBallState,
  IClosedLoopBuildChars,
  IPitch,
  ITrajectory,
} from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { PitchListsService } from 'services/pitch-lists.service';
import { StateTransformService } from 'services/state-transform.service';
import { usePitchListStore } from 'components/sections/pitch-list/store/use-pitch-list-store';
import { useShallow } from 'zustand/react/shallow';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';

const COMPONENT_NAME = 'EditBreaksDialog';

// how many decimals to print
const PRECISION = 1;

// show warning if either x or z requested differs from corresponding avgs by more than this
const WARNING_THRESHOLD_INCHES = 10;

interface IProps {
  pitch: IPitch;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;

  // hides the update button
  readonly: boolean;
  onClose: () => void;
}

interface IState {
  actual_x_text?: string;
  actual_x_in: number;
  actual_z_text?: string;
  actual_z_in: number;

  target_x_text?: string;
  target_x_in: number;
  target_z_text?: string;
  target_z_in: number;

  overriding: boolean;
}

// TODO: I don't think we need this many useCallbacks
export const EditBreaksDialog = (props: IProps) => {
  const init = useRef(false);

  const listsCx = useContext(PitchListsContext);
  const listStore = usePitchListStore(
    useShallow(({ loading, updatePitches, reloadPitches }) => ({
      listsCx,
      loading,
      updatePitches,
      reloadPitches,
    }))
  );

  const [state, setState] = useState<IState>({
    actual_x_in: 0,
    actual_z_in: 0,
    target_x_in:
      (PitchListHelper.getSafePitchBreaks(props.pitch)?.xInches ?? 0) * -1,
    target_z_in: PitchListHelper.getSafePitchBreaks(props.pitch)?.zInches ?? 0,
    overriding: false,
  });

  const getShots = useCallback((): IMachineShot[] => {
    return props.matchingCx.safeGetShotsByPitch(props.pitch);
  }, [props.matchingCx, props.pitch]);

  const getMedianBreaks = useCallback(() => {
    const breaks = getShots().map((s) => TrajHelper.getBreaksFromShot(s));
    return BreakCanvas.getMedian(breaks);
  }, [getShots]);

  const getMedianTraj = useCallback((): ITrajectory | undefined => {
    const trajs = getShots().map((s) => s.traj as ITrajectory);

    if (trajs.length === 0) {
      return undefined;
    }

    return MiscHelper.getMedianObject(trajs) as ITrajectory;
  }, [getShots]);

  const initializeValues = useCallback(async () => {
    const pitch = props.pitch;

    if (getShots().length === 0) {
      await props.matchingCx.updatePitch(
        {
          pitch: pitch,
          includeHitterPresent: false,
          includeLowConfidence: false,
        },
        true
      );
    }

    const target =
      props.pitch.priority === BuildPriority.Breaks
        ? (pitch.breaks ?? TrajHelper.getBreaks(pitch.traj))
        : undefined;

    const actual = getMedianBreaks();

    const actual_x_in = -1 * actual.xInches;
    const actual_z_in = actual.zInches;

    const target_x_in = target ? -1 * target.xInches : actual_x_in;
    const target_z_in = target ? target.zInches : actual_z_in;

    setState((prev) => ({
      ...prev,
      actual_x_in: actual_x_in,
      actual_x_text: actual_x_in.toFixed(PRECISION),
      actual_z_in: actual_z_in,
      actual_z_text: actual_z_in.toFixed(PRECISION),
      target_x_in: target_x_in,
      target_x_text: target_x_in.toFixed(PRECISION),
      target_z_in: target_z_in,
      target_z_text: target_z_in.toFixed(PRECISION),
    }));
  }, [props.pitch, props.matchingCx, getShots, getMedianBreaks]);

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      initializeValues();
    }
  }, [initializeValues]);

  const resetActualBreaks = useCallback(() => {
    const medianBreaks = getMedianBreaks();

    setState((prev) => ({
      ...prev,
      actual_x_in: -1 * medianBreaks.xInches,
      actual_x_text: medianBreaks.xInches.toFixed(PRECISION),
      actual_z_in: medianBreaks.zInches,
      actual_z_text: medianBreaks.zInches.toFixed(PRECISION),
    }));
  }, [getMedianBreaks]);

  const submit = useCallback(
    async (mode: 'copy' | 'update') => {
      if (mode === 'update' && props.readonly) {
        NotifyHelper.error({
          message_md: 'Updating is not allowed in read-only mode.',
        });
        return;
      }

      const medianBS = MiscHelper.getMedianObject(
        getShots().map((s) => s.bs as IBallState)
      ) as IBallState;

      const medianBreaks = getMedianBreaks();
      if (!medianBreaks) {
        NotifyHelper.error({
          message_md: 'There is insufficient data to proceed.',
        });
        return;
      }

      const medianTraj = getMedianTraj();
      if (!medianTraj) {
        NotifyHelper.error({
          message_md: 'There is insufficient data to proceed.',
        });
        return;
      }

      const msResult = getMSFromMSDict(props.pitch, props.machineCx.machine);
      if (!msResult.ms) {
        NotifyHelper.error({ message_md: 'There is no machine state.' });
        return;
      }

      const chars: IClosedLoopBuildChars = {
        machineID: props.machineCx.machine.machineID,
        mongo_id: props.pitch._id,
        priority: BuildPriority.Breaks,
        use_gradient: true,
        ms: msResult.ms,
        traj: medianTraj,
        target_bs: props.pitch.bs,
        actual_bs: medianBS ?? props.pitch.bs,
        actual_mvmt: {
          break_x_ft: medianBreaks.xInches / FT_TO_INCHES,
          break_z_ft: medianBreaks.zInches / FT_TO_INCHES,
        },
        target_mvmt: {
          break_x_ft: (-1 * state.target_x_in) / FT_TO_INCHES,
          break_z_ft: state.target_z_in / FT_TO_INCHES,
        },
        override_mvmt: state.overriding
          ? {
              break_x_ft: (-1 * state.actual_x_in) / FT_TO_INCHES,
              break_z_ft: state.actual_z_in / FT_TO_INCHES,
            }
          : undefined,
      };

      const result = (
        await StateTransformService.getInstance().buildClosedLoop({
          machineID: props.machineCx.machine.machineID,
          pitches: [chars],
          stepSize: 1,
          notifyError: true,
        })
      ).find((p) => p.mongo_id === props.pitch._id);

      if (!result) {
        NotifyHelper.error({
          message_md: 'Failed to find adjusted pitch by ID.',
        });
        return;
      }

      const nextMSDict = getMergedMSDict(
        props.machineCx.machine,
        [result.ms],
        props.pitch.msDict
      );

      const payload: Partial<IPitch> = {
        ...props.pitch,
        bs: result.target_bs,
        traj: result.traj,
        breaks: {
          xInches: -1 * state.target_x_in,
          zInches: state.target_z_in,
        },
        msDict: nextMSDict,
        priority: BuildPriority.Breaks,
      };

      const aimed = AimingContextHelper.getAdHocAimed({
        source: `${COMPONENT_NAME} > submit`,
        machine: props.machineCx.machine,
        pitch: payload as IPitch,
        plate: TrajHelper.getPlateLoc(props.pitch.traj),
        usingShots: [],
      });

      if (!aimed) {
        NotifyHelper.error({ message_md: 'Failed to build new pitch.' });
        return;
      }

      switch (mode) {
        case 'copy': {
          aimed.pitch._original_id = props.pitch._id;
          const nameSuffix = `(HB: ${state.target_x_in.toFixed(
            PRECISION
          )}, VB: ${state.target_z_in.toFixed(PRECISION)})`;
          aimed.pitch.name = `${props.pitch.name} ${nameSuffix}`;

          await PitchListsService.getInstance()
            .postPitchesToList({
              listID: props.pitch._parent_id,
              data: [aimed.pitch],
            })
            .then((result) => {
              if (!result.success) {
                NotifyHelper.warning({
                  message_md: result.error ?? 'Pitch could not be created.',
                });
                return;
              }

              if (props.pitch._parent_id === listsCx.active?._id) {
                listStore.reloadPitches();
              }
            });
          break;
        }

        case 'update': {
          await listStore.updatePitches({
            payloads: [aimed.pitch],
          });
          break;
        }

        default: {
          break;
        }
      }

      props.onClose();
    },
    [
      props.readonly,
      props.pitch,
      props.machineCx.machine,
      props.onClose,
      state.target_x_in,
      state.target_z_in,
      state.overriding,
      state.actual_x_in,
      state.actual_z_in,
      getShots,
      getMedianBreaks,
      getMedianTraj,
      listsCx.active,
      listStore.updatePitches,
      listStore.reloadPitches,
    ]
  );

  const renderWarning = useCallback(() => {
    const diffX = state.actual_x_in - state.target_x_in;
    const warnX = Math.abs(diffX) > WARNING_THRESHOLD_INCHES;

    const diffZ = state.actual_z_in - state.target_z_in;
    const warnZ = Math.abs(diffZ) > WARNING_THRESHOLD_INCHES;

    if (!warnX && !warnZ) {
      return null;
    }

    const msg = (() => {
      if (warnX && warnZ) {
        return t('pd.edit-breaks-both-differ-from-x', {
          x: WARNING_THRESHOLD_INCHES,
        }).toString();
      }

      if (warnX) {
        return t('pd.edit-breaks-h-differs-from-x', {
          x: WARNING_THRESHOLD_INCHES,
        }).toString();
      }

      return t('pd.edit-breaks-v-differs-from-x', {
        x: WARNING_THRESHOLD_INCHES,
      }).toString();
    })();

    return <CommonCallout text={msg} />;
  }, [
    state.actual_x_in,
    state.target_x_in,
    state.actual_z_in,
    state.target_z_in,
  ]);

  const pitchName = props.pitch.name;
  const shots = getShots();

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={COMPONENT_NAME}
        title="pl.edit-breaks"
        subtitle={
          <Text size={RADIX.TEXT.SIZE.SM} color={RADIX.COLOR.SECONDARY}>
            {t('common.pitch')}: {pitchName}
          </Text>
        }
        width={RADIX.DIALOG.WIDTH.LG}
        onClose={props.onClose}
        buttons={[
          {
            label: t(
              state.overriding
                ? 'pl.edit-breaks-override-on'
                : 'pl.edit-breaks-override-off'
            ).toString(),
            color: state.overriding ? RADIX.COLOR.WARNING : undefined,
            invisible: shots.length === 0,
            onClick: () => {
              setState((prev) => ({
                ...prev,
                overriding: !state.overriding,
              }));
              resetActualBreaks();
            },
          },
          {
            icon: <CopyIcon />,
            label: 'common.copy',
            color: RADIX.COLOR.SUCCESS,
            disabled: shots.length === 0,
            invisible: shots.length === 0,
            onClick: () => submit('copy'),
          },
          {
            icon: <UpdateIcon />,
            label: 'common.update',
            color: RADIX.COLOR.WARNING,
            invisible: shots.length === 0 || props.readonly,
            onClick: () => submit('update'),
          },
        ]}
        content={
          <Flex gap={RADIX.FLEX.GAP.LG} direction="column">
            <Text>{t('pl.edit-breaks-msg')}</Text>

            {shots.length === 0 && (
              <CommonCallout text="pl.edit-breaks-insufficient-data-msg" />
            )}

            <BreakView
              actual_x_in={state.actual_x_in}
              actual_z_in={state.actual_z_in}
              target_x_in={state.target_x_in}
              target_z_in={state.target_z_in}
              shots={shots}
              overriding={state.overriding}
              onUpdate={(result) => {
                setState((prev) => ({
                  ...prev,
                  target_x_text: result.xInches.toFixed(PRECISION),
                  target_x_in: result.xInches,
                  target_z_text: result.zInches.toFixed(PRECISION),
                  target_z_in: result.zInches,
                }));
              }}
            />

            <Grid columns="3" gap={RADIX.FLEX.GAP.MD}>
              <Box>&nbsp;</Box>
              <Box>
                {t(state.overriding ? 'common.user-override' : 'common.actual')}
                &nbsp;
                <CommonTooltip
                  trigger={<InfoCircledIcon />}
                  text={
                    state.overriding
                      ? 'pl.actual-break-from-user'
                      : 'pl.actual-break-from-device'
                  }
                />
              </Box>
              <Box>
                <CommonTooltip
                  trigger={<Strong>{t('common.horizontal-break-in')}</Strong>}
                  text_md={PitchDesignHelper.HB_TOOLTIP_TEXT}
                />
              </Box>
              <Box>{t('common.horizontal-break-in')}</Box>
              <Box>
                <CommonTextInput
                  id="edit-breaks-actual-x"
                  name="actual_x_text"
                  type="number"
                  className="align-center"
                  value={state.actual_x_text}
                  disabled={listStore.loading || !state.overriding}
                  onOptionalNumericChange={(v) => {
                    setState((prev) => ({
                      ...prev,
                      actual_x_text: v?.toString(),
                      actual_x_in: v ?? 0,
                    }));
                  }}
                />
              </Box>
              <Box>
                <CommonTextInput
                  id="edit-breaks-target-x"
                  name="target_x_text"
                  type="number"
                  className="align-center"
                  value={state.target_x_text}
                  disabled={listStore.loading}
                  onOptionalNumericChange={(v) => {
                    setState((prev) => ({
                      ...prev,
                      target_x_text: v?.toString(),
                      target_x_in: v ?? 0,
                    }));
                  }}
                />
              </Box>
              <Box>{t('common.vertical-break-in')}</Box>
              <Box>
                <CommonTextInput
                  id="edit-breaks-actual-z"
                  name="actual_z_text"
                  type="number"
                  className="align-center"
                  value={state.actual_z_text}
                  disabled={listStore.loading || !state.overriding}
                  onOptionalNumericChange={(v) => {
                    setState((prev) => ({
                      ...prev,
                      actual_z_text: v?.toString(),
                      actual_z_in: v ?? 0,
                    }));
                  }}
                />
              </Box>
              <Box>
                <CommonTextInput
                  id="edit-breaks-target-z"
                  name="target_z_text"
                  type="number"
                  className="align-center"
                  value={state.target_z_text}
                  disabled={listStore.loading}
                  onOptionalNumericChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      target_z_text: e?.toString(),
                      target_z_in: e ?? 0,
                    }));
                  }}
                />
              </Box>
            </Grid>

            {renderWarning()}
          </Flex>
        }
      />
    </ErrorBoundary>
  );
};
