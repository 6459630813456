import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DownloadIcon,
} from '@radix-ui/react-icons';
import { Box, Text } from '@radix-ui/themes';
import { CommonDialog } from 'components/common/dialogs';
import { HINT_DELIMITER } from 'components/common/form/text-hint';
import { CommonVideoPlayer } from 'components/common/video/player';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IExpiringUrlDict } from 'lib_ts/interfaces/common/i-url-dict';
import { ISessionHit } from 'lib_ts/interfaces/i-session-hit';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import { ReactNode, useEffect, useMemo, useState } from 'react';

interface IProps {
  identifier: string;
  title: ReactNode;

  defaultIndex: number;
  hits: ISessionHit[];
  urls: IExpiringUrlDict;
  onClose: () => void;
}

export const HitterSessionVideoDialog = (props: IProps) => {
  const [index, setIndex] = useState(props.defaultIndex);

  useEffect(() => {
    if (index >= props.hits.length) {
      setIndex(props.hits.length - 1);
    }
  }, [props.hits]);

  const hit = useMemo(() => props.hits[index], [props.hits, index]);

  const playback = useMemo(() => {
    if (!hit.fire.video?.path) {
      return undefined;
    }

    const dictEntry = props.urls[hit.fire.video?.path];

    if (!dictEntry) {
      return undefined;
    }

    const o: IVideoPlayback = {
      video: {
        url: dictEntry.url,
        mime_type: hit.fire.video.mime,
        cap_size_0: hit.fire.video.height,
        cap_size_1: hit.fire.video.width,
      },
      thumb: {
        url: '',
        mime_type: '',
      },
    };

    return o;
  }, [props.urls, hit, index]);

  // forces CommonVideoPlayer to remount
  const playbackKey = useMemo(
    () => MiscHelper.hashify(index.toFixed() + playback?.video.url),
    [index, playback]
  );

  if (!playback) {
    return <></>;
  }

  const subtitle = useMemo(
    () =>
      [
        hit.hitter.name,
        format(parseISO(hit._created), LOCAL_DATETIME_FORMAT, {
          timeZone: LOCAL_TIMEZONE,
        }),
        `${index + 1} / ${props.hits.length}`,
      ].join(HINT_DELIMITER),
    [hit.hitter.name, hit._created, index, props.hits]
  );

  const stats = useMemo(() => {
    const output: string[] = [];

    if (hit.pitch.name) {
      output.push(hit.pitch.name);
    }

    output.push(`${hit.pitch.speedMPH.toFixed(1)}mph`);

    if (hit.pitch.xBreakIN !== undefined) {
      output.push(`↔ ${hit.pitch.xBreakIN.toFixed(2)}in`);
    }

    if (hit.pitch.zBreakIN !== undefined) {
      output.push(`↕ ${hit.pitch.zBreakIN.toFixed(2)}in`);
    }

    return output.join(HINT_DELIMITER);
  }, [hit.pitch]);

  return (
    <CommonDialog
      title={props.title}
      subtitle={
        <Box mt="3">
          <Text color={RADIX.COLOR.SECONDARY} weight="light" size="3">
            <div>{subtitle}</div>
            <div>{stats}</div>
          </Text>
        </Box>
      }
      identifier={props.identifier}
      content={<CommonVideoPlayer key={playbackKey} playback={playback} />}
      buttons={[
        {
          icon: <ArrowLeftIcon />,
          label: 'common.previous',
          disabled: index === 0,
          onClick: () => setIndex(index - 1),
        },
        {
          label: 'common.next',
          suffixIcon: <ArrowRightIcon />,
          disabled: index === props.hits.length - 1,
          onClick: () => setIndex(index + 1),
        },
        {
          icon: <DownloadIcon />,
          variant: RADIX.BUTTON.VARIANT.PRIMARY,
          label: 'common.export-video',
          onClick: () => window.open(playback.video.url, '_blank'),
        },
      ]}
      width={RADIX.DIALOG.WIDTH.LG}
      onClose={props.onClose}
    />
  );
};
