import { Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { SettingButton } from 'components/common/settings-dialog/button';
import { SettingForm } from 'components/common/settings-dialog/form';
import { InactiveCallout } from 'components/common/settings-dialog/main-sections/machine.section';
import { SettingRow } from 'components/common/settings-dialog/row';
import env from 'config';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext, MachineDialogMode } from 'contexts/machine.context';
import { t } from 'i18next';
import {
  TRACKING_DEVICE_OPTIONS,
  TrackingDevice,
} from 'lib_ts/enums/training.enums';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { useContext, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';

const COMPONENT_NAME = 'MachineTrackingDeviceTab';

interface IProps {
  onSave: (payload: Partial<IMachine>) => void;
}

export const MachineTrackingDeviceTab = (props: IProps) => {
  const { restrictedGameStatus } = useContext(AuthContext);
  const { machine, checkActive, setDialog } = useContext(MachineContext);

  const [value, setValue] = useState<IMachine>({ ...machine });

  const deviceOptions = useMemo(() => {
    return TRACKING_DEVICE_OPTIONS.filter((o) => {
      if (!env.production) {
        // everything is allowed on dev/local/etc...
        return true;
      }

      if (o.value === TrackingDevice.TrackmanB1) {
        return env.identifier === 'beta';
      }

      if (o.value === TrackingDevice.TrajektVision) {
        return env.identifier === 'beta';
      }

      return true;
    });
  }, [TRACKING_DEVICE_OPTIONS, env.production]);

  const active = checkActive(true);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <SettingForm>
        {!active && <InactiveCallout />}

        <SettingRow
          header="settings.tracking-device"
          description={<Text>{t('settings.tracking-device-msg')}</Text>}
          input={
            <CommonSelectInput
              id="machine-tracking-device"
              options={deviceOptions}
              disabled={!active || restrictedGameStatus}
              value={value.tracking_device}
              shouldChange={(v) => {
                if (
                  v === TrackingDevice.TrajektVision &&
                  !value.enable_trajekt_vision
                ) {
                  NotifyHelper.warning({
                    message_md: t('common.enable-trajekt-vision-msg'),
                  });
                  return false;
                }

                return true;
              }}
              onChange={(v) => {
                const safeValue = v as TrackingDevice;

                setValue({
                  ...value,
                  tracking_device: safeValue,
                });

                props.onSave({
                  _id: value._id,
                  tracking_device: safeValue,
                });
              }}
            />
          }
        />

        {value.tracking_device === TrackingDevice.RapsodoV3PRO && (
          <SettingRow
            separatorBefore
            header="settings.rapsodo-serial"
            description={
              <ReactMarkdown
                children={t(
                  machine.sandbox
                    ? 'settings.rapsodo-serial-sandbox-msg'
                    : 'settings.rapsodo-serial-msg'
                )}
              />
            }
            input={
              <CommonTextInput
                id="machine-rapsodo-serial"
                value={value.rapsodo_serial}
                disabled={!active || value.sandbox || restrictedGameStatus}
                onChange={(v) => {
                  const safeValue = (v ?? '').trim();

                  setValue({
                    ...value,
                    rapsodo_serial: safeValue,
                  });

                  props.onSave({
                    _id: value._id,
                    rapsodo_serial: safeValue,
                  });
                }}
                monospace
              />
            }
          />
        )}

        {machine.enable_trajekt_vision && (
          <SettingRow
            separatorBefore
            header={`Trajekt Vision™ ${t('settings.calibration')}`}
            description={
              <ReactMarkdown children={t('settings.trajekt-vision-msg')} />
            }
            input={
              <SettingButton
                label="settings.calibrate"
                disabled={!checkActive(true)}
                onClick={() => setDialog(MachineDialogMode.CalibrateVision)}
              />
            }
          />
        )}
      </SettingForm>
    </ErrorBoundary>
  );
};
