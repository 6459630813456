import { CommonFormGrid } from 'components/common/form/grid';
import { SelectHitterLevel } from 'components/common/hitters/select-hitter-level';
import { SelectHitterSide } from 'components/common/hitters/select-hitter-side';
import { CheckedContext } from 'contexts/layout/checked.context';
import { useContext } from 'react';

export const HitterToolbar = () => {
  const { resetChecked } = useContext(CheckedContext);

  return (
    <CommonFormGrid columns={4}>
      <SelectHitterSide callback={resetChecked} />
      <SelectHitterLevel />
    </CommonFormGrid>
  );
};
