import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import {
  PitchListState,
  PitchListStorePropsAndDependencies,
} from 'components/sections/pitch-list/store/pitch-list-store';
import { t } from 'i18next';
import { MachineHelper } from 'lib_ts/classes/machine.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { PitchListExtType } from 'lib_ts/enums/pitches.enums';
import { IPitchList } from 'lib_ts/interfaces/pitches';
import { IPitch } from 'lib_ts/interfaces/pitches/i-pitch';
import { ISearchPitches } from 'lib_ts/interfaces/pitches/i-search-pitches';
import { PitchesService } from 'services/pitches.service';
import { StateTransformService } from 'services/state-transform.service';
import { StateCreator } from 'zustand';

export const MAX_SEARCH_LIMIT = 1_000;
export const SEARCH_ID = '--SEARCH--';

// instead of aggressively loading and using the shots dictionary
const USE_AGG_SHOT_DICT = true;

const READ_ONLY_LIST_TYPES: PitchListExtType[] = [
  PitchListExtType.Card,
  PitchListExtType.Reference,
  PitchListExtType.Sample,
];

export interface ListSlice {
  loading: boolean;

  pitches: IPitch[];

  // Search only enabled in Library
  search?: boolean;
  searchCriteria: ISearchPitches;

  // Migrated from Pitch List local state (wip)
  /** attaches to fire events (e.g. rehab session, plate discipline, etc...) */
  tags: string;

  // Actions
  setLoading: (loading: boolean) => void;

  setSearchCriteria: (payload?: ISearchPitches) => Promise<void>;
  onSearch: () => Promise<void>;

  updatePitches: (config: {
    payloads: Partial<IPitch>[];
    silently?: boolean;
    successMsg?: string;
  }) => Promise<IPitch[] | undefined>;
  deletePitches: (ids: string[]) => Promise<boolean>;
  reloadPitches: () => Promise<void>;

  activeReadOnly: () => boolean;

  updateTrainingStatus: () => void;

  rebuild: (ids: string[]) => Promise<void>;

  setTags: (tags: string) => void;

  // Helper util that's only called by other zustand actions. Not called directly by components
  _safeSetPitches: (params: {
    pitches: IPitch[];
    skipMatching?: boolean;
    calledBy?: string; // Optional name of action that calls _safeSetPitches for debugging/logging purposes
  }) => Promise<IPitch[]>;
}

const DEFAULT_SEARCH: ISearchPitches = {
  sortDir: 'desc',
  sortKey: '_created',
  limit: 50,
};

// Initialize the store by merging context dependencies with the default state
const getInitialState = (props: PitchListStorePropsAndDependencies) => {
  const active: IPitchList | undefined = props.search
    ? {
        _id: SEARCH_ID,
        _created: new Date().toISOString(),
        _changed: new Date().toISOString(),
        _parent_def: PitchListOwner.None,
        _parent_id: '',
        _parent_field: '',

        name: t('main.search'),
        folder: '',
        super: false,
      }
    : props.listsCx.active;

  return {
    active: active,

    pitches: [],

    loading: false,

    searchCriteria: { ...DEFAULT_SEARCH },

    // From Pitch List State - comma delimited list of tags. Needs to be accessed by both the toolbar and table footer
    tags: '',

    search: false,

    ...props,
  };
};

// Takes props via dependency injection and returns a zustand slice
type ICreateListSlice = (
  props: PitchListStorePropsAndDependencies
) => StateCreator<PitchListState, [['zustand/devtools', never]], [], ListSlice>;

export const createListSlice: ICreateListSlice = (props) => (set, get) => ({
  // State
  ...getInitialState(props),

  // Action definitions
  setLoading: (loading) => set({ loading }, undefined, 'pitchList/setLoading'),

  setSearchCriteria: async (payload) => {
    const { onSearch } = get();

    const safePayload = {
      ...(payload ?? DEFAULT_SEARCH),
      limit: MAX_SEARCH_LIMIT,
    };

    set(
      { searchCriteria: safePayload },
      undefined,
      'pitchList/setSearchCriteria'
    );
    onSearch();
  },

  // TODO: Need a better name for this since it's not just run after searching. loadPitches?
  onSearch: async () => {
    // Runs on mount, when active list changes, when search criteria changes, and when the pitch lists are refetched (e.g. when new pitches are uploaded via csv)
    const { search, listsCx, searchCriteria, setLoading, _safeSetPitches } =
      get();

    if (search) {
      setLoading(true);

      const pitches = await PitchesService.getInstance().searchPitches(
        searchCriteria,
        searchCriteria.limit
      );

      await _safeSetPitches({
        pitches,
        skipMatching: USE_AGG_SHOT_DICT,
        calledBy: 'pitchList/onSearch',
      });

      setLoading(false);
      return;
    }

    if (!listsCx.active) {
      return;
    }

    setLoading(true);

    const pitches = await PitchesService.getInstance().getListPitches(
      listsCx.active._id,
      searchCriteria
    );

    await _safeSetPitches({
      pitches,
      skipMatching: USE_AGG_SHOT_DICT,
      calledBy: 'pitchList/onSearch',
    });

    setLoading(false);
  },

  updatePitches: async (config) => {
    const { pitches, setLoading, _safeSetPitches } = get();

    try {
      if (config.payloads.find((p) => !p._id)) {
        NotifyHelper.error({
          message_md: 'Cannot update a pitch with an empty ID.',
        });
        return undefined;
      }

      if (!config.silently) {
        setLoading(true);
      }

      const result = await PitchesService.getInstance()
        .putPitches(config.payloads)
        .finally(() => {
          if (!config.silently) {
            setLoading(false);
          }
        });

      if (!result.success) {
        throw new Error(result.error);
      }

      const workingPitches = config.silently ? pitches : [...pitches];

      const updatedPitches = result.data as IPitch[];

      const changed = updatedPitches.map((updated) => {
        const index = workingPitches.findIndex((m) => m._id === updated._id);

        if (index === -1) {
          return false;
        }

        // replace the item inline
        workingPitches.splice(index, 1, updated);

        return true;
      });

      /** if necessary, update activePitches */
      if (changed.includes(true)) {
        _safeSetPitches({
          pitches: workingPitches,
          calledBy: 'pitchList/updatePitches',
        });

        if (config.successMsg) {
          NotifyHelper.success({ message_md: config.successMsg });
        }
      }

      return updatedPitches;
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md:
          e instanceof Error
            ? e.message
            : `There was an error updating your ${
                config.payloads.length === 1 ? 'pitch' : 'pitches'
              }.`,
      });

      return undefined;
    }
  },

  deletePitches: async (ids) => {
    const { pitches, setLoading, _safeSetPitches } = get();

    try {
      setLoading(true);

      const result = await PitchesService.getInstance()
        .deletePitches(ids)
        .finally(() => setLoading(false));

      if (!result.success) {
        throw new Error(result.error);
      }

      NotifyHelper.success({
        message_md: `${ids.length === 1 ? 'Pitch' : 'Pitches'} deleted!`,
      });

      setTimeout(() => {
        const nextPitches = pitches.filter((p) => !ids.includes(p._id));
        _safeSetPitches({
          pitches: nextPitches,
          skipMatching: true,
          calledBy: 'pitchList/deletePitches',
        });
      }, 500);

      return true;
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md:
          e instanceof Error
            ? e.message
            : `There was an error deleting your pitch. ${ERROR_MSGS.CONTACT_SUPPORT}`,
      });

      return false;
    }
  },

  reloadPitches: async () => {
    const { search, listsCx, searchCriteria, setLoading, _safeSetPitches } =
      get();

    if (search) {
      if (!searchCriteria) {
        return;
      }

      setLoading(true);
      await PitchesService.getInstance()
        .searchPitches(searchCriteria, MAX_SEARCH_LIMIT)
        .then((pitches) =>
          _safeSetPitches({
            pitches,
            calledBy: 'pitchList/reloadPitches',
          })
        )
        .catch(console.error)
        .finally(() => setLoading(false));

      return;
    }

    const list = listsCx.active;

    if (!list) {
      return;
    }

    setLoading(true);
    await PitchesService.getInstance()
      .getListPitches(list._id, searchCriteria)
      .then((pitches) =>
        _safeSetPitches({
          pitches,
          calledBy: 'pitchList/reloadPitches',
        })
      )
      .catch(console.error)
      .finally(() => setLoading(false));
  },

  activeReadOnly: () => {
    const { search, listsCx, authCx } = get();

    if (search) {
      return false;
    }

    const list = listsCx.active;

    if (!list) {
      return false;
    }

    if (authCx.current.role === UserRole.admin) {
      return false;
    }

    if (!list.type) {
      return false;
    }

    return READ_ONLY_LIST_TYPES.includes(list.type);
  },

  updateTrainingStatus: () => {
    const { pitches, matchingCx, machineCx, listsCx } = get();

    if (props.search) {
      return;
    }

    if (!matchingCx.aggReady) {
      return;
    }

    const list = listsCx.active;

    if (!list) {
      return;
    }

    const machineID = machineCx.machine.machineID;

    const training = PitchListHelper.getTrainingDict({
      machineID: machineID,
      current: list.training,
      total: pitches.length,
      untrained: pitches.filter((p) => !matchingCx.isPitchTrained(p)).length,
    });

    if (training[machineID] === list.training?.[machineID]) {
      // value already matches
      return;
    }

    listsCx.updateList({
      payload: {
        _id: list._id,
        training: training,
      },
      silently: true,
    });
  },

  rebuild: async (ids) => {
    const { pitches: _pitches, setLoading, _safeSetPitches } = get();

    try {
      const pitches = _pitches.filter((p) => ids.includes(p._id));
      if (pitches.length === 0) {
        return;
      }

      NotifyHelper.success({
        message_md: `${
          pitches.length === 1 ? 'One pitch' : `${pitches.length} pitches`
        } will be refreshed using your latest model to optimize replication accuracy!`,
      });

      setLoading(true);

      const refreshed = await StateTransformService.getInstance()
        .forceRefreshPitches({
          pitches: pitches,
          ms: true,
          traj: true,
        })
        .finally(() => setLoading(false));

      refreshed.forEach((rp) => {
        const index = _pitches.findIndex((ap) => ap._id === rp._id);
        if (index !== -1) {
          _pitches.splice(index, 1, rp);
        }
      });

      // skip matching because these would be newly built pitches anyway
      _safeSetPitches({
        pitches,
        skipMatching: true,
        calledBy: 'pitchList/rebuild',
      });
    } catch (e) {
      console.error(e);
    }
  },

  setTags: (tags) => set({ tags }, undefined, 'pitchList/setTags'),

  // Helper util that's only called by other zustand actions. Not called directly by components
  _safeSetPitches: async ({ pitches, skipMatching, calledBy }) => {
    // Optionally log the name of the action that called _safeSetPitches
    const actionName = `${
      calledBy ? `${calledBy} ->` : ''
    } pitchList/_safeSetPitches`;
    const { machineCx, matchingCx } = get();

    if (pitches.length === 0) {
      set({ pitches: [] }, undefined, actionName);
      return [];
    }

    // if any pitch is missing any hashes (unlikely but possible), fill them in temporarily
    pitches
      .filter((p) => MachineHelper.needsMSDictHash(p))
      .forEach((p) => {
        const ms = getMSFromMSDict(p, machineCx.machine).ms;

        if (!ms) {
          return;
        }

        ms.matching_hash = MachineHelper.getMSHash('matching', ms);
        ms.full_hash = MachineHelper.getMSHash('full', ms);
      });

    if (!skipMatching) {
      await matchingCx.updatePitches({
        pitches: pitches,
        includeHitterPresent: false,
        includeLowConfidence: true,
      });
    }

    set({ pitches }, undefined, actionName);
    return pitches;
  },
});
