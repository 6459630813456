import env from 'config';
import { CookieKey, DragDropEngine } from 'enums/cookies.enums';
import {
  DEFAULT_APP_SETTINGS,
  IAppCookie,
  TableIdentifier,
} from 'interfaces/cookies/i-app.cookie';
import { IMachineCalibrationCookie } from 'interfaces/cookies/i-machine-calibration-cookie';
import {
  DEFAULT_SESSION_UNAUTH,
  ISessionCookie,
} from 'interfaces/cookies/i-session.cookie';
import {
  DEFAULT_SNAPSHOT_COOKIE,
  ISnapshotCookie,
} from 'interfaces/cookies/i-snapshot.cookie';
import { FC, ReactNode, createContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

const CONTEXT_NAME = 'CookiesContext';

type ValidCookie =
  | Partial<IAppCookie>
  | Partial<ISnapshotCookie>
  | Partial<ISessionCookie>
  | Partial<IMachineCalibrationCookie>;

export interface ICookiesContext {
  app: IAppCookie;
  /** observed by authCx to determine when to merge credentials from previous session */
  session: ISessionCookie;
  machineCalibration: IMachineCalibrationCookie;
  snapshot: ISnapshotCookie;

  /** helper for storing page size selections in the cookie */
  readonly setPageSize: (id: TableIdentifier, value: number) => void;
  /** helper for getting page size selections (if found) from the cookie */
  readonly getPageSize: (id: TableIdentifier) => number | undefined;
  /** expires and source are required for session cookie */
  readonly setCookie: (
    key: CookieKey,
    value: ValidCookie,
    expires?: Date,
    source?: string
  ) => Promise<boolean>;
}

const DEFAULT: ICookiesContext = {
  app: DEFAULT_APP_SETTINGS,
  session: DEFAULT_SESSION_UNAUTH,
  machineCalibration: {
    skippedPitchIDs: [],
  },
  snapshot: DEFAULT_SNAPSHOT_COOKIE,

  setPageSize: () => console.error(`${CONTEXT_NAME}: not init`),
  getPageSize: () => undefined,

  setCookie: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
};

/** for manipulating anything that needs to load from or save to a cookie */
export const CookiesContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const CookiesProvider: FC<IProps> = (props) => {
  const [_appCookie, _setAppCookie] = useCookies([CookieKey.app]);
  const [_app, _setApp] = useState(DEFAULT.app);

  const [_sessionCookie, _setSessionCookie] = useCookies([CookieKey.session]);
  const [_session, _setSession] = useState(DEFAULT.session);

  const [_snapshotCookie, _setSnapshotCookie] = useCookies([
    CookieKey.snapshot,
  ]);
  const [_snapshot, _setSnapshot] = useState(DEFAULT.snapshot);

  const [_calibrationCookie, _setCalibrationCookie] = useCookies([
    CookieKey.machineCalibration,
  ]);
  const [_mc, _setMC] = useState(DEFAULT.machineCalibration);

  const [_init, _setInit] = useState(false);

  /** to save separate entries in the cookie for each combination of user + table identifier */
  const getSessionTableKey = (id: TableIdentifier) => {
    return `${_session.email ?? 'anonymous'}-${id}`;
  };

  const state: ICookiesContext = {
    app: _app,
    session: _session,
    machineCalibration: _mc,
    snapshot: _snapshot,

    setPageSize: (id, value) => {
      _app.page_sizes[getSessionTableKey(id)] = value;
      _setAppCookie(CookieKey.app, _app);
    },

    getPageSize: (id) => {
      return _app.page_sizes[getSessionTableKey(id)];
    },

    setCookie: (key, value, expires, source) => {
      if (source) {
        console.debug(`Updating cookie ${key} from ${source}...`);
      }

      return new Promise<boolean>((resolve) => {
        try {
          switch (key) {
            case CookieKey.app: {
              updateApp();
              break;
            }

            case CookieKey.machineCalibration: {
              updateMachineCalibration();
              break;
            }

            case CookieKey.session: {
              updateSession();
              break;
            }

            case CookieKey.snapshot: {
              updateSnapshot();
              break;
            }

            default: {
              break;
            }
          }

          resolve(true);
        } catch (e) {
          console.error(e);
          resolve(false);
        }

        function updateSession() {
          const nextValue: ISessionCookie = {
            ..._session,
            ...value,
          };

          nextValue.expires = expires;
          nextValue.auth = !!nextValue.token;

          _setSessionCookie(CookieKey.session, nextValue, {
            expires: expires,
          });
          _setSession(nextValue);
        }

        function updateSnapshot() {
          const nextValue: ISnapshotCookie = {
            ..._snapshot,
            ...value,
          };

          _setSnapshotCookie(CookieKey.snapshot, nextValue, {
            expires: expires,
          });
          _setSnapshot(nextValue);
        }

        function updateMachineCalibration() {
          const nextValue: IMachineCalibrationCookie = {
            ..._mc,
            ...value,
          };

          const typedValue = value as Partial<IMachineCalibrationCookie>;

          if (
            typedValue.start_date !== undefined &&
            typedValue.start_date !== _mc.start_date
          ) {
            // reset skipped shots whenever start_date is changing
            nextValue.skippedPitchIDs = [];
          }

          _setCalibrationCookie(CookieKey.machineCalibration, nextValue);
          _setMC(nextValue);
        }

        function updateApp() {
          const nextValue: IAppCookie = {
            ..._app,
            ...value,
          };

          _setAppCookie(CookieKey.app, nextValue);
          _setApp(nextValue);
        }
      });
    },
  };

  useEffect(() => {
    /** ensures the following only runs once, on load, without tripping useEffect warnings */
    if (_init) {
      return;
    }

    console.debug('initializing cookies...');

    _setInit(true);

    const defApp: IAppCookie = {
      ..._app,
      ..._appCookie.app,
    };

    /** fixes for invalid values from past cookies */
    if (!Object.values(DragDropEngine).includes(defApp.drag_drop_engine)) {
      defApp.drag_drop_engine = DEFAULT.app.drag_drop_engine;
    }

    _setAppCookie(CookieKey.app, defApp);
    _setApp(defApp);

    const defCalibration = {
      ..._mc,
      ..._calibrationCookie.machineCalibration,
    };
    _setCalibrationCookie(CookieKey.machineCalibration, defCalibration);
    _setMC(defCalibration);

    const defSession: ISessionCookie = {
      ..._session,
      ..._sessionCookie.session,
    };

    /** only attempt to continue when token was from the same build version */
    defSession.token =
      defSession.version === env.version ? defSession.token : '';
    /** ensures auth value is set correctly when loading from cookie and not using the setter function */
    defSession.auth = !!defSession.token;

    _setSessionCookie(CookieKey.session, defSession);
    _setSession(defSession);

    const defSnapshot: ISnapshotCookie = {
      ..._snapshot,
      ..._snapshotCookie.snapshot,
    };

    _setSnapshotCookie(CookieKey.snapshot, defSnapshot);
    _setSnapshot(defSnapshot);
  }, []);

  return (
    <CookiesContext.Provider value={state}>
      {props.children}
    </CookiesContext.Provider>
  );
};
