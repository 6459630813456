import { FilePlusIcon, UploadIcon } from '@radix-ui/react-icons';
import { Button, Card, Flex, Popover, Separator } from '@radix-ui/themes';
import { BaseballIcon } from 'components/common/custom-icon/shorthands';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonSwitchInput } from 'components/common/form/switch';
import { ManageListDialog } from 'components/common/pitch-lists/manage-list';
import { SectionHeader } from 'components/sections/header';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { MachineContext } from 'contexts/machine.context';
import { PitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { SectionsContext } from 'contexts/sections.context';
import { CookieKey } from 'enums/cookies.enums';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useState } from 'react';

export const PitchesHeader = (props: { extraActions?: IMenuAction[] }) => {
  const { app, setCookie } = useContext(CookiesContext);
  const { buildOptions, getSupportedPriority } = useContext(MachineContext);
  const designCx = useContext(PitchDesignContext);
  const { getDefaultPitch } = useContext(MachineContext);

  const { restrictedGameStatus } = useContext(AuthContext);

  const { userSections, tryChangeSection } = useContext(SectionsContext);

  const [dialogCreateList, setDialogCreateList] = useState<number | undefined>(
    undefined
  );

  return (
    <ErrorBoundary componentName="PitchesHeader">
      <SectionHeader
        header={t('main.pitches')}
        subsections={
          userSections?.find((s) => s.value === SectionName.Pitches)
            ?.subsections
        }
        secondaryAction={
          <Popover.Root>
            <Popover.Trigger>
              <Button variant={RADIX.BUTTON.VARIANT.SECONDARY}>
                {t('common.settings')}
              </Button>
            </Popover.Trigger>
            <Popover.Content asChild>
              <Card>
                <Flex direction="column" gap="3" p="2">
                  <CommonSelectInput
                    id="pitches-header-build-priority"
                    label="common.import-method"
                    options={buildOptions}
                    value={getSupportedPriority(
                      app.pitch_upload_options.priority
                    )}
                    onChange={(v) =>
                      setCookie(CookieKey.app, {
                        pitch_upload_options: {
                          ...app.pitch_upload_options,
                          priority: v as BuildPriority,
                        },
                      })
                    }
                  />

                  <Separator size="4" />

                  <CommonSwitchInput
                    id="pitches-header-normalize"
                    label="common.normalize-releases"
                    defaultChecked={app.pitch_upload_options.average_release}
                    onCheckedChange={(v) =>
                      setCookie(CookieKey.app, {
                        pitch_upload_options: {
                          ...app.pitch_upload_options,
                          average_release: v,
                        },
                      })
                    }
                  />
                </Flex>
              </Card>
            </Popover.Content>
          </Popover.Root>
        }
        actions={[
          {
            group: '_1',
            prefixIcon: <FilePlusIcon />,
            label: 'common.create-pitch-list',
            disabled: restrictedGameStatus,
            onClick: () => setDialogCreateList(Date.now()),
          },
          {
            group: '_1',
            prefixIcon: <BaseballIcon />,
            label: 'common.create-pitch',
            disabled: restrictedGameStatus,
            onClick: () => {
              // clears any existing reference pitch (e.g. from previously updating an existing pitch)
              designCx.setReference(getDefaultPitch());

              tryChangeSection({
                trigger: 'PitchesHeader > actions menu',
                section: SectionName.Pitches,
                subsection: SubSectionName.Design,
              });
            },
          },
          {
            group: '_1',
            prefixIcon: <UploadIcon />,
            label: 'common.upload-pitches',
            disabled: restrictedGameStatus,
            onClick: () =>
              tryChangeSection({
                trigger: 'PitchesHeader > actions menu',
                section: SectionName.Pitches,
                subsection: SubSectionName.Upload,
              }),
          },
          ...(props.extraActions ?? []),
        ]}
      />

      {dialogCreateList && (
        <ManageListDialog
          key={dialogCreateList}
          identifier="PitchesHeaderCreateListDialog"
          mode="create"
          onCreated={() => setDialogCreateList(undefined)}
          onClose={() => setDialogCreateList(undefined)}
        />
      )}
    </ErrorBoundary>
  );
};
