import { Flex } from '@radix-ui/themes';
import { CommonTextInput } from 'components/common/form/text';
import { SearchHitter } from 'components/common/hitters/search-hitter';
import { SelectHitterLevel } from 'components/common/hitters/select-hitter-level';
import env from 'config';
import { QuickSessionContext } from 'contexts/quick-session.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const SelectPitchHeaderSidebar = () => {
  const { tags, setTags } = useContext(QuickSessionContext);

  return (
    <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
      <SelectHitterLevel />
      <SearchHitter />

      {env.enable.fire_tags && (
        <CommonTextInput
          id="quick-session-tags"
          value={tags}
          className="text-uppercase"
          placeholder={t('common.eg-tags').toString()}
          onChange={(v) => setTags(v ?? '')}
        />
      )}
    </Flex>
  );
};
