import { Box, Flex, Spinner } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { BasicVideoPlayer } from 'components/common/video/basic-player';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { MlbStatsGameGuidVideo } from 'lib_ts/interfaces/mlb-stats-api/raw-results/game-guids-video';
import React from 'react';
import { MlbStatsService } from 'services/mlb-stats.service';

const COMPONENT_NAME = 'ViewBroadcastDialog';

interface IProps extends IBaseDialog {
  gamePk: number | string;
  guid: string;
}

interface IFilterState {
  source?: string;
}

interface IState extends IFilterState {
  loading: boolean;

  video?: MlbStatsGameGuidVideo;
}

export class ViewBroadcastDialog extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
    };

    this.initialize = this.initialize.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount() {
    if (this.init) {
      return;
    }

    this.init = true;
    this.initialize();
  }

  private async initialize() {
    this.setState({ loading: true });

    const video = await MlbStatsService.getInstance().getGamePlayVideos(
      this.props.gamePk,
      this.props.guid
    );

    video?.playbackGroups.sort((a, b) =>
      a.mediaSourceType.localeCompare(b.mediaSourceType)
    );

    video?.playbackGroups.forEach((g) => {
      g.playbackRenditions.sort((a, b) => (a.rank > b.rank ? 1 : -1));
    });

    const defaultSource = video?.playbackGroups.find(
      (g) => g.mediaSourceType === 'BROADCAST'
    )?.mediaSourceType;

    this.setState({
      video: video,
      source: defaultSource ?? video?.playbackGroups?.[0].mediaSourceType,
      loading: false,
    });
  }

  private renderContent() {
    try {
      if (this.state.loading) {
        return <Spinner />;
      }

      const firstRendition = this.state.video?.playbackGroups.find(
        (g) => g.mediaSourceType === this.state.source
      )?.playbackRenditions[0];

      return (
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <Box>
            <CommonSelectInput
              id="broadcast-source"
              name="source"
              placeholder="Source"
              options={
                this.state.video?.playbackGroups.map((g) => ({
                  label: g.mediaSourceType.split('_').join(' '),
                  value: g.mediaSourceType,
                })) ?? []
              }
              value={this.state.source}
              onChange={(v) => this.setState({ source: v })}
              disabled={this.state.loading}
            />
          </Box>
          <Box>
            {firstRendition && (
              <BasicVideoPlayer
                video_url={firstRendition.playbackUrl}
                video_mime_type={`video/${firstRendition.mediaType.toLowerCase()}`}
                height_px={9 * 30}
                width_px={16 * 30}
              />
            )}
          </Box>
        </Flex>
      );
    } catch (e) {
      console.error(e);
      return <CommonCallout text="common.request-failed-msg" />;
    }
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={this.props.identifier}
          title="View Broadcast"
          width={RADIX.DIALOG.WIDTH.LG}
          content={this.renderContent()}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }
}
