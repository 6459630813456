import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { createContext, FC, ReactNode, useState } from 'react';

const CONTEXT_NAME = 'DirtyContext';

interface ICallbacks {
  accept: () => void;
  reject?: () => void;
}

const DEFAULT_CALLBACKS: ICallbacks = {
  accept: () => console.error(`${CONTEXT_NAME}: not init`),
};

/** used to track when changes occur in forms and warn the user before data-loss (e.g. changing sections, closing dialogs) */
export interface IDirtyContext {
  /** call this when ready to leave/close/destroy the form, will trigger the warning (if necessary) */
  readonly checkDirty: (value: ICallbacks) => void;
  readonly markDirty: (source: string, trigger: string) => void;
  readonly clearDirty: (source: string, trigger: string) => void;
}

const DEFAULT: IDirtyContext = {
  markDirty: () => console.error(`${CONTEXT_NAME}: not init`),
  checkDirty: () => console.error(`${CONTEXT_NAME}: not init`),
  clearDirty: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const DirtyContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

const DEFAULT_DICT: { [id: string]: boolean } = {};

export const DirtyProvider: FC<IProps> = (props) => {
  const [_dict, _setDict] = useState(DEFAULT_DICT);
  const [_callbacks, _setCallbacks] = useState(DEFAULT_CALLBACKS);
  const [_confirm, _setConfirm] = useState<number | undefined>();

  const _setDirty = (config: {
    source: string;
    trigger: string;
    value: boolean;
  }) => {
    _setDict({ ..._dict, [config.source]: config.value });
  };

  const state: IDirtyContext = {
    markDirty: (source, trigger) => {
      _setDirty({ source: source, trigger: trigger, value: true });
    },
    clearDirty: (source, trigger) => {
      _setDirty({ source: source, trigger: trigger, value: false });
    },
    checkDirty: (value) => {
      const anyDirty = Object.values(_dict).reduce((a, b) => a || b, false);

      if (anyDirty) {
        _setCallbacks(value);
        _setConfirm(Date.now());
      } else {
        /** nothing is dirty, proceed */
        value.accept();
      }
    },
  };

  return (
    <DirtyContext.Provider value={state}>
      {props.children}

      {_confirm && (
        <CommonConfirmationDialog
          // every new value of _confirm causes the alert dialog to re-mount
          key={_confirm}
          identifier="DirtyContextDialog"
          title="common.warning"
          description="common.unsaved-changes-msg"
          action={{
            label: 'common.proceed',
            onClick: () => {
              _callbacks.accept();
              _setDict(DEFAULT_DICT);
              _setCallbacks(DEFAULT_CALLBACKS);
            },
          }}
          cancel={{
            onClick: () => {
              _callbacks.reject?.();
              _setCallbacks(DEFAULT_CALLBACKS);
            },
          }}
        />
      )}
    </DirtyContext.Provider>
  );
};
