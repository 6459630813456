import {
  CheckCircledIcon,
  CopyIcon,
  CrossCircledIcon,
  DownloadIcon,
  InputIcon,
  Pencil2Icon,
  ReloadIcon,
  ResetIcon,
  RowsIcon,
  TrashIcon,
  UpdateIcon,
  UploadIcon,
} from '@radix-ui/react-icons';
import { Badge, Box, Spinner, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CustomIcon } from 'components/common/custom-icon';
import {
  BaseballIcon,
  BetaIcon,
  SuperAdminIcon,
} from 'components/common/custom-icon/shorthands';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { CopyPitchesDialogHoC } from 'components/common/dialogs/copy-pitches';
import { PitchDataDialog } from 'components/common/dialogs/pitch-data';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSimpleFileUploader } from 'components/common/file-uploader';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonContentWithSidebar } from 'components/common/layout/content-with-sidebar';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonLogs, ILog } from 'components/common/logs';
import { ManageCardDialog } from 'components/common/pitch-lists/manage-card';
import { ManageListDialog } from 'components/common/pitch-lists/manage-list';
import { CommonTable } from 'components/common/table';
import { TableContext, TableProvider } from 'components/common/table/context';
import { ActiveCalibrationModelWarning } from 'components/common/warnings/active-calibration-model-warning';
import { PresetTrainingDialog } from 'components/machine/dialogs/preset-training';
import { TrainingDialog } from 'components/machine/dialogs/training';
import {
  ChangeVideoDialog,
  DeletePitchesDialog,
  EditBreaksDialog,
  EditPitchDialog,
  EditSpinsDialog,
  OptimizePitchDialog,
  RefreshListDialog,
  RenameFolderDialog,
  ResetTrainingDialog,
} from 'components/sections/pitch-list/dialogs';
import { Header } from 'components/sections/pitch-list/header';
import {
  QueueContext,
  QueueProvider,
} from 'components/sections/pitch-list/queue.context';
import { PitchListSidebar } from 'components/sections/pitch-list/sidebar';
import {
  MAX_SEARCH_LIMIT,
  SEARCH_ID,
} from 'components/sections/pitch-list/store/pitch-list-store';
import {
  PitchListStoreProvider,
  usePitchListStore,
} from 'components/sections/pitch-list/store/use-pitch-list-store';
import PitchListToolbar from 'components/sections/pitch-list/toolbar';
import { PitchesHeader } from 'components/sections/pitches/header';
import env from 'config';
import { AimingContext } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { CheckedProvider } from 'contexts/layout/checked.context';
import { MachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import {
  MatchingShotsContext,
  ONLY_ALLOW_REFRESH_ON_TRAIN,
} from 'contexts/pitch-lists/matching-shots.context';
import { PitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { SectionsContext } from 'contexts/sections.context';
import {
  ITrainingContext,
  TrainingContext,
  TrainingProvider,
} from 'contexts/training.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { CustomIconPath } from 'enums/custom.enums';
import { LOCAL_DATETIME_FORMAT_SHORT, LOCAL_TIMEZONE } from 'enums/env';
import { ResetPlateMode } from 'enums/machine.enums';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import useDeepCompareEffect from 'hooks/useDeepCompareEffect';
import { t } from 'i18next';
import { appearanceImgPath } from 'index';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { IStatusColumn } from 'interfaces/i-pitch-list';
import { QueueType } from 'interfaces/i-queue-mode';
import { ITableCheckable } from 'interfaces/tables/checking';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableReorder } from 'interfaces/tables/reordering';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { PlateHelper } from 'lib_ts/classes/plate.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { MLB_OUTCOME_CODES } from 'lib_ts/enums/mlb-stats-api/guid-metadata-types.enum';
import { PitchListDialog } from 'lib_ts/enums/pitch-list.enums';
import {
  BuildPriority,
  PitchListExtType,
  SHUFFLE_FREQUENCY_OPTIONS,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ITrainingMsg } from 'lib_ts/interfaces/i-machine-msg';
import { IPitch, IPitchList } from 'lib_ts/interfaces/pitches';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MainService } from 'services/main.service';
import { SessionEventsService } from 'services/session-events.service';
import slugify from 'slugify';
import { useShallow } from 'zustand/react/shallow';

const COMPONENT_NAME = 'PitchList';

// if true, training pitches that can be refreshed will refresh them without user confirmation
const AUTO_REFRESH_ON_TRAIN = true;

const ENABLE_LOGS = false;

// group name starts with _ so that it renders as a separator in the menu
enum ActionGroup {
  Primary = '_1',
  Secondary = '_2',
  Tertiary = '_3',
}

const IDENTIFIER = TableIdentifier.PitchList;

const Q_SORT_KEY = '_queue_sort';

const PAGE_SIZES = TABLES.PAGE_SIZES.XL;

interface IState {
  /** for feedback to user without resorting to toasts */
  logs: ILog[];

  // forces table to redraw (e.g. to update training status per pitch after training)
  tableKey: number;
}

const MIN_SHUFFLE_FREQUENCY = parseInt(SHUFFLE_FREQUENCY_OPTIONS[0].value);

interface IPitchListHocProps {
  search?: boolean;
}

// Could move the PitchListStoreProvider outside of this, like how the Pitch List Context provider is set up
export const PitchListHoc = ({ search }: IPitchListHocProps) => {
  return (
    <PitchListStoreProvider search={search}>
      <PitchList />
    </PitchListStoreProvider>
  );
};

const PitchList = () => {
  const listStore = usePitchListStore(
    useShallow(({ pitches }) => ({
      pitches,
    }))
  );

  return (
    <TableProvider>
      <CheckedProvider data={listStore.pitches}>
        <QueueProvider pitches={listStore.pitches}>
          <PitchListBase />
        </QueueProvider>
      </CheckedProvider>
    </TableProvider>
  );
};

const PitchListBase = () => {
  const aimingCx = useContext(AimingContext);
  const authCx = useContext(AuthContext);
  const cookiesCx = useContext(CookiesContext);
  const designCx = useContext(PitchDesignContext);
  const listsCx = useContext(PitchListsContext);
  const machineCx = useContext(MachineContext);
  const matchingCx = useContext(MatchingShotsContext);
  const sectionsCx = useContext(SectionsContext);
  const tableCx = useContext(TableContext);
  const queueCx = useContext(QueueContext);
  const videosCx = useContext(VideosContext);

  // is there a better way to do this?
  const listStore = usePitchListStore(
    useShallow(
      ({
        loading,
        searchCriteria,
        pitches,
        managePitches,
        dialogData,
        dialogEdit,
        dialogEditBreaks,
        dialogEditSpins,
        dialogOptimize,
        dialogChangeVideo,
        dialogCopy,
        dialogReset,
        dialogTraining,
        dialogDeleteList,
        dialogDeletePitches,
        dialogResetList,
        dialogCopyList,
        dialogEditList,
        dialogCard,
        dialogRenameFolder,
        dialogSearch,
        setSearchCriteria,
        updatePitches,
        reloadPitches,
        activeReadOnly,
        updateTrainingStatus,
        rebuild,
        openDialog,
        closeDialog,
        onSearch,
      }) => ({
        loading,
        searchCriteria,
        pitches,
        managePitches,
        dialogData,
        dialogEdit,
        dialogEditBreaks,
        dialogEditSpins,
        dialogOptimize,
        dialogChangeVideo,
        dialogCopy,
        dialogReset,
        dialogTraining,
        dialogDeleteList,
        dialogDeletePitches,
        dialogResetList,
        dialogCopyList,
        dialogEditList,
        dialogCard,
        dialogRenameFolder,
        dialogSearch,
        setSearchCriteria,
        updatePitches,
        reloadPitches,
        activeReadOnly,
        updateTrainingStatus,
        rebuild,
        openDialog,
        closeDialog,
        onSearch,
      })
    )
  );

  const refreshTimeout = useRef<NodeJS.Timeout>();
  const fileInput = useRef<CommonSimpleFileUploader>();

  // Reference to store the previous matchingCx.aggReady value
  const prevAggReadyRef = useRef(matchingCx.aggReady);

  const [state, setState] = useState<IState>({
    logs: [],

    tableKey: Date.now(),
  });

  // this useEffect with an empty dependency array will run on every render, much like componentDidUpdate
  useEffect(() => {
    if (authCx.restrictedGameStatus && listStore.dialogTraining !== undefined) {
      // auto-end training (if necessary) upon start of game
      NotifyHelper.warning({
        message_md: `Training is not allowed during home games.`,
      });

      listStore.closeDialog({
        dialog: PitchListDialog.DialogTraining,
      });
      // Add a useEffect and call onEndTraining there if necessary
      onEndTraining(listStore.managePitches);
    }

    // this should only trigger at most once, after that will only work when forced
    // Check if aggReady has transitioned from false to true
    if (!prevAggReadyRef.current && matchingCx.aggReady) {
      queueCx.changeQueue(queueCx.def.type);
    }

    // Update the previous aggReady ref for the next render
    prevAggReadyRef.current = matchingCx.aggReady;
  }, [
    authCx.restrictedGameStatus,
    listStore.dialogTraining,
    listStore.closeDialog,
    matchingCx.aggReady,
    queueCx.changeQueue,
  ]);

  // redraw the table after queue changes
  useEffect(() => {
    setState({
      ...state,
      tableKey: Date.now(),
    });
  }, [queueCx.def, queueCx.ids]);

  const rowActions = useMemo(() => {
    const readonly = listStore.activeReadOnly();

    const output = [
      {
        group: ActionGroup.Primary,
        label: 'pl.open-pitch-list',
        color: RADIX.COLOR.SUCCESS,
        invisibleFn: () => listsCx.active?._id !== SEARCH_ID,
        onClick: (pitch: IPitch) => {
          sectionsCx.tryChangeSection({
            trigger: 'go to list from search results',
            section: SectionName.Pitches,
            subsection: SubSectionName.List,
            fragments: [pitch._parent_id],
          });
        },
      },
      {
        group: ActionGroup.Primary,
        label: 'pl.view-pitch-data',
        onClick: (pitch: IPitch) =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogData,
            pitches: [pitch],
          }),
      },
      {
        group: ActionGroup.Primary,
        label: 'common.train-pitch',
        color: RADIX.COLOR.TRAIN_PITCH,
        invisibleFn: () =>
          env.identifier !== 'local' && !matchingCx.readyToTrain(),
        onClick: (pitch: IPitch) =>
          handleTrainPitches({
            ids: [pitch._id],
            promptRefresh: true,
          }),
      },
      {
        group: ActionGroup.Primary,
        label: 'main.pitch-design',
        invisibleFn: () => authCx.restrictedGameStatus,
        onClick: (pitch: IPitch) => {
          const refPitch = listStore.pitches.find((p) => p._id === pitch._id);

          if (!refPitch) {
            console.warn(`Failed to find pitch ${pitch._id} in list store`);
            return;
          }

          if (!readonly) {
            designCx.setReference(refPitch);

            /** allow design context to load up the pitch data */
            setTimeout(() => {
              sectionsCx.tryChangeSection({
                trigger: 'PitchList > context menu > update',
                section: SectionName.Pitches,
                subsection: SubSectionName.Design,
                fragments: [refPitch._id],
              });
            }, 100);
            return;
          }

          /** empty pitch _id => pitch design will only allow saving as a new pitch */
          designCx.setReference({
            ...refPitch,
            _id: '',
          });

          setTimeout(() => {
            sectionsCx.tryChangeSection({
              trigger: 'PitchList > context menu > readonly',
              section: SectionName.Pitches,
              subsection: SubSectionName.Design,
            });
          }, 100);
        },
      },
      {
        group: ActionGroup.Primary,
        label: 'pl.edit-pitch-metadata',
        invisibleFn: () => readonly || authCx.restrictedGameStatus,
        onClick: (pitch: IPitch) =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogEdit,
            pitches: [pitch],
          }),
      },
      {
        group: ActionGroup.Primary,
        label: 'pl.change-video',
        invisibleFn: () => readonly,
        onClick: (pitch: IPitch) =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogChangeVideo,
            pitches: [pitch],
          }),
      },
      {
        group: ActionGroup.Secondary,
        label: 'pl.optimize-pitch',
        suffixIcon: <BetaIcon />,
        disableFn: () =>
          !matchingCx.readyToTrain({
            ignoreConnection: true,
            ignoreGameStatus: true,
          }),
        invisibleFn: (pitch: IPitch) => {
          if (!env.enable.auto_beta && !authCx.current.enable_beta) {
            return true;
          }

          // hide if the pitch is not trained
          const matches = matchingCx.getAggShotsByPitch(pitch);
          return !matches?.trained;
        },
        onClick: async (pitch: IPitch) => {
          await matchingCx.updatePitch(
            {
              pitch: pitch,
              includeHitterPresent: false,
              includeLowConfidence: false,
            },
            true
          );

          listStore.openDialog({
            dialog: PitchListDialog.DialogOptimize,
            pitches: [pitch],
          });
        },
      },
      {
        group: ActionGroup.Secondary,
        label: 'pl.edit-breaks',
        disableFn: () =>
          !matchingCx.readyToTrain({
            ignoreConnection: true,
            ignoreGameStatus: true,
          }),
        invisibleFn: (pitch: IPitch) => {
          // hide if the pitch is not trained
          const matches = matchingCx.getAggShotsByPitch(pitch);
          return !matches?.trained;
        },
        onClick: async (pitch: IPitch) => {
          await matchingCx.updatePitch(
            {
              pitch: pitch,
              includeHitterPresent: false,
              includeLowConfidence: false,
            },
            true
          );

          listStore.openDialog({
            dialog: PitchListDialog.DialogEditBreaks,
            pitches: [pitch],
          });
        },
      },
      {
        group: ActionGroup.Secondary,
        label: 'pl.edit-spins',
        suffixIcon: <BetaIcon />,
        disableFn: () =>
          !matchingCx.readyToTrain({
            ignoreConnection: true,
            ignoreGameStatus: true,
          }),
        invisibleFn: (pitch: IPitch) => {
          if (!env.enable.auto_beta && !authCx.current.enable_beta) {
            return true;
          }

          // hide if the pitch is not trained
          const matches = matchingCx.getAggShotsByPitch(pitch);
          return !matches?.trained;
        },
        onClick: async (pitch: IPitch) => {
          await matchingCx.updatePitch(
            {
              pitch: pitch,
              includeHitterPresent: false,
              includeLowConfidence: false,
            },
            true
          );

          listStore.openDialog({
            dialog: PitchListDialog.DialogEditSpins,
            pitches: [pitch],
          });
        },
      },
      {
        group: ActionGroup.Secondary,
        label: t('common.copy-x', { x: t('common.pitch') }),
        invisibleFn: () => authCx.restrictedGameStatus,
        onClick: (pitch: IPitch) => {
          const originalPitch = listStore.pitches.find(
            (p) => p._id === pitch._id
          );
          if (!originalPitch) {
            NotifyHelper.error({
              message_md:
                'There was an error, please try again after refreshing.',
            });
            console.error({
              event: `${COMPONENT_NAME}: failed to find selected pitch in active context.`,
              pitch,
            });
            return;
          }

          listStore.openDialog({
            dialog: PitchListDialog.DialogCopy,
            pitches: [originalPitch],
          });
        },
      },
      {
        group: ActionGroup.Tertiary,
        label: 'pl.refresh-model',
        invisibleFn: (pitch: IPitch) =>
          ONLY_ALLOW_REFRESH_ON_TRAIN ||
          readonly ||
          !matchingCx.readyToRefresh(pitch),
        onClick: (pitch: IPitch) => {
          listStore.openDialog({
            dialog: PitchListDialog.DialogResetList,
            pitches: [pitch],
          });
        },
        color: RADIX.COLOR.SUCCESS,
      },
      {
        group: ActionGroup.Tertiary,
        label: 'common.reset-training-data',
        invisibleFn: (pitch: IPitch) => {
          if (readonly) {
            return true;
          }

          // hide if the pitch has no shots
          const matches = matchingCx.getAggShotsByPitch(pitch);
          return !matches || matches.total === 0;
        },
        onClick: (pitch: IPitch) => {
          listStore.openDialog({
            dialog: PitchListDialog.DialogReset,
            pitches: [pitch],
          });
        },
        color: RADIX.COLOR.WARNING,
      },
      {
        group: ActionGroup.Tertiary,
        label: t('common.delete-x', { x: t('common.pitch') }),
        invisibleFn: () => readonly || authCx.restrictedGameStatus,
        onClick: (pitch: IPitch) =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogDeletePitches,
            pitches: [pitch],
          }),
        color: RADIX.COLOR.DANGER,
      },
    ];

    return output;
  }, [
    authCx.gameStatus,
    authCx.restrictedGameStatus,
    listStore.pitches,
    listStore.activeReadOnly,
  ]);

  const BASE_COLUMNS = useMemo(() => {
    const output: ITableColumn[] = [
      {
        label: '#',
        key: Q_SORT_KEY,
        align: 'center',
        thClassNameFn: () => 'width-40px',
        classNameFn: () => 'width-40px',
        sortRowsFn: (a: IPitch, b: IPitch, dir: number) => {
          const aIndex = queueCx.ids.findIndex((id) => id === a._id);
          const bIndex = queueCx.ids.findIndex((id) => id === b._id);

          if (aIndex === -1 && bIndex === -1) {
            /** neither are in queue, don't change order */
            return 0;
          }

          if (aIndex === -1) {
            /** only b is in queue, put a after */
            return 1;
          }

          if (bIndex === -1) {
            /** only a is in queue, put a first */
            return -1;
          }

          /** both are in queue, sort by order */
          return (aIndex > bIndex ? -1 : 1) * dir;
        },
        formatFn: (pitch: IPitch) => {
          const showSpinner =
            machineCx.lastPitchID === pitch._id && aimingCx.firing;

          if (showSpinner) {
            return <Spinner />;
          }

          if (queueCx.def.type !== QueueType.RepeatAll) {
            // not the right mode
            return;
          }

          const index = queueCx.ids.findIndex((id) => id === pitch._id);

          if (index === -1) {
            // not in queue
            return;
          }

          return index + 1;
        },
      },
      {
        label: 'common.actions',
        key: ACTIONS_KEY,
        actions: rowActions,
      },
      {
        label: 'pl.status',
        key: '_status',
        align: 'center',
        thClassNameFn: () => 'width-80px',
        tooltipFn: (pitch: IPitch) => {
          const status = getStatusColumn(pitch);

          const lines: string[] = [`${t('pl.status')}: ${status.text}`];

          const ms = getMSFromMSDict(pitch, machineCx.machine).ms;
          if (ms) {
            lines.push(
              `${t('common.model')}: ${machineCx.getModelName(ms.model_id)}`
            );
          }

          if (ms?.last_built) {
            lines.push(
              `${t('common.updated')}: ${format(
                parseISO(ms.last_built),
                LOCAL_DATETIME_FORMAT_SHORT,
                { timeZone: LOCAL_TIMEZONE }
              )}`
            );
          }

          return lines.join('\n\n');
        },
        sortRowsFn: (a: IPitch, b: IPitch, dir: number) => {
          const aCol = getStatusColumn(a);
          const bCol = getStatusColumn(b);

          return dir * (aCol.sortValue > bCol.sortValue ? -1 : 1);
        },
        formatFn: (pitch: IPitch) => {
          const summary = matchingCx.getAggShotsByPitch(pitch);
          const status = getStatusColumn(pitch);
          const canRefresh = matchingCx.readyToRefresh(pitch);

          return (
            <Box
              pt="1"
              data-testid="ShotSummary"
              data-total={summary?.total ?? 0}
              data-qt={summary?.qt ?? 0}
              data-qt-complete={summary?.qt_complete ?? false}
            >
              {!AUTO_REFRESH_ON_TRAIN && canRefresh ? (
                <UpdateIcon />
              ) : (
                status.icon
              )}
            </Box>
          );
        },
      },
      {
        label: 'common.pitch',
        key: 'name',
        formatFn: (pitch: IPitch) => {
          return getPitchColumnText(pitch);
        },
        tooltipFn: (pitch: IPitch) => {
          const items: { label: string; value?: string }[] = [
            { label: 'common.name', value: pitch.name },
            { label: 'common.year', value: pitch.year },
            { label: 'common.game', value: pitch.game },
            { label: 'common.hitter', value: pitch.hitter },
            {
              label: 'common.outcome',
              value:
                MLB_OUTCOME_CODES.find((o) => o.value === pitch.outcome)
                  ?.label ?? pitch.outcome,
            },
          ].filter((i) => i.value);

          if (items.length === 0) {
            return;
          }

          return items.map((l) => `${t(l.label)}: ${l.value}`).join('\n\n');
        },
        sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
          const textA = getPitchColumnText(pitchA);
          const textB = getPitchColumnText(pitchB);
          return -dir * textA.localeCompare(textB);
        },
      },
      {
        label: 'common.priority',
        key: 'priority',
        formatFn: (pitch: IPitch) =>
          t(
            pitch.priority === BuildPriority.Breaks
              ? 'common.break'
              : 'common.spin'
          ),
        sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
          const textA = pitchA.priority ?? BuildPriority.Spins;
          const textB = pitchB.priority ?? BuildPriority.Spins;
          return -dir * textA.localeCompare(textB);
        },
      },
      {
        label: 'common.type',
        key: 'type',
      },
      {
        label: 'common.hand',
        key: 'hand',
        align: 'center',
        formatFn: (pitch: IPitch) => {
          const isRight = Math.sign(pitch.bs.px) <= 0;
          return (
            <Badge color={isRight ? RADIX.COLOR.RIGHT : RADIX.COLOR.LEFT}>
              {t(isRight ? 'common.rhp' : 'common.lhp')}
            </Badge>
          );
        },
        sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
          return (
            dir * (Math.sign(pitchA.bs.px) < Math.sign(pitchB.bs.px) ? 1 : -1)
          );
        },
      },
      {
        label: 'common.zone',
        key: '_zone',
        align: 'center',
        formatFn: (pitch: IPitch) => {
          const summary = PlateHelper.getPitchSummary(pitch);
          return (
            <img
              alt="strike zone icon"
              className="StrikeZoneIcon"
              width={24}
              height={24}
              src={appearanceImgPath(`plate/${summary.grid}.svg`)}
            />
          );
        },
        sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
          const sortA = PlateHelper.getPitchSummary(pitchA).sort;
          const sortB = PlateHelper.getPitchSummary(pitchB).sort;
          return -dir * (sortA < sortB ? -1 : 1);
        },
      },
      {
        label: 'common.speed',
        key: 'speed',
        subLabel: 'mph',
        align: 'right',
        sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
          const va = pitchA.bs.vnet ?? BallHelper.getSpeed(pitchA.traj);
          const vb = pitchB.bs.vnet ?? BallHelper.getSpeed(pitchB.traj);
          return dir * (va < vb ? 1 : -1);
        },
        formatFn: (pitch: IPitch) => {
          return TrajHelper.getSpeedMPH(pitch.traj)?.toFixed(1);
        },
      },
      {
        label: 'common.spin',
        key: 'spin',
        subLabel: 'rpm',
        align: 'right',
        sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
          const va = pitchA.bs.wnet ?? BallHelper.getNetSpin(pitchA.bs);
          const vb = pitchB.bs.wnet ?? BallHelper.getNetSpin(pitchB.bs);
          return dir * (va < vb ? 1 : -1);
        },
        formatFn: (pitch: IPitch) => {
          return (pitch.bs.wnet ?? BallHelper.getNetSpin(pitch.bs)).toFixed(0);
        },
      },
      {
        label: 'common.h-break',
        key: 'breaks_xInches',
        subLabel: 'in',
        align: 'right',
        tooltipFn: () => PitchDesignHelper.HB_TOOLTIP_TEXT,
        sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
          // don't change order
          if (
            pitchA.priority !== BuildPriority.Breaks &&
            pitchB.priority !== BuildPriority.Breaks
          ) {
            return 0;
          }

          // puts all non-breaks stuff after breaks stuff, regardless of direction
          if (pitchA.priority !== BuildPriority.Breaks) {
            return 1;
          }

          if (pitchB.priority !== BuildPriority.Breaks) {
            return -1;
          }

          const va = PitchListHelper.getSafePitchBreaks(pitchA)?.xInches;
          const vb = PitchListHelper.getSafePitchBreaks(pitchB)?.xInches;

          // don't change order
          if (va === undefined && vb === undefined) {
            return 0;
          }

          if (va === undefined) {
            return 1;
          }

          if (vb === undefined) {
            return -1;
          }

          return dir * (va > vb ? -1 : 1);
        },
        formatFn: (pitch: IPitch) => {
          if (pitch.priority !== BuildPriority.Breaks) {
            return;
          }

          const value = PitchListHelper.getSafePitchBreaks(pitch)?.xInches;

          if (value === undefined) {
            return;
          }

          return value.toFixed(1);
        },
      },
      {
        label: 'common.v-break',
        key: 'breaks_zInches',
        subLabel: 'in',
        align: 'right',
        sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
          // don't change order
          if (
            pitchA.priority !== BuildPriority.Breaks &&
            pitchB.priority !== BuildPriority.Breaks
          ) {
            return 0;
          }

          // puts all non-breaks stuff after breaks stuff, regardless of direction
          if (pitchA.priority !== BuildPriority.Breaks) {
            return 1;
          }

          if (pitchB.priority !== BuildPriority.Breaks) {
            return -1;
          }

          const va = PitchListHelper.getSafePitchBreaks(pitchA)?.zInches;
          const vb = PitchListHelper.getSafePitchBreaks(pitchB)?.zInches;

          // don't change order
          if (va === undefined && vb === undefined) {
            return 0;
          }

          if (va === undefined) {
            return 1;
          }

          if (vb === undefined) {
            return -1;
          }

          return dir * (va > vb ? -1 : 1);
        },
        formatFn: (pitch: IPitch) => {
          if (pitch.priority !== BuildPriority.Breaks) {
            return;
          }

          const value = PitchListHelper.getSafePitchBreaks(pitch)?.zInches;

          if (value === undefined) {
            return;
          }

          return value.toFixed(1);
        },
      },
      {
        label: 'common.frequency',
        key: 'frequency',
        sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
          const va = pitchA.frequency ?? MIN_SHUFFLE_FREQUENCY;
          const vb = pitchB.frequency ?? MIN_SHUFFLE_FREQUENCY;
          return dir * (va > vb ? -1 : 1);
        },
        formatFn: (pitch: IPitch) => (
          <CommonSelectInput
            key={`${pitch._id}-frequency`}
            id="PL-PitchFrequency"
            options={SHUFFLE_FREQUENCY_OPTIONS}
            value={(pitch.frequency ?? MIN_SHUFFLE_FREQUENCY).toString()}
            onOptionalNumericChange={(v) => {
              pitch.frequency = v;

              // silently should not update the array => should not trigger table to re-render
              listStore.updatePitches({
                payloads: [{ _id: pitch._id, frequency: v }],
                silently: true,
              });
            }}
            skipSort
          />
        ),
      },
    ];

    return output;
  }, [rowActions, queueCx.def, queueCx.ids]);

  // Helpers
  const getCanRefresh = (pitches: IPitch[]) => {
    return pitches
      .filter((p) => matchingCx.readyToRefresh(p))
      .filter((p) => {
        switch (p.priority) {
          case BuildPriority.Breaks: {
            return !!machineCx.activeModel?.supports_breaks;
          }

          case BuildPriority.Spins:
          case BuildPriority.Default:
          default: {
            return !!machineCx.activeModel?.supports_spins;
          }
        }
      });
  };

  /** the server will emit trainingresponse even if not in training, e.g. for continuous data collection */
  const afterTrainingMsg = (data: ITrainingMsg) => {
    if (listStore.dialogTraining) {
      // training will handle the msgs while open
      return;
    }

    if (data.success === undefined) {
      return;
    }

    if (!data.success) {
      return;
    }

    /** updates the shots # in the table when necessary */
    aimingCx.updateMatches();
  };

  const handleTrainPitches = async (config: {
    ids: string[];
    promptRefresh: boolean;
  }) => {
    // always use pitches from the context in case they were (auto-)refreshed and differ from what's in selectedPitch or managePitches
    const pitches = listStore.pitches.filter((p) => config.ids.includes(p._id));

    if (pitches.length === 0) {
      return;
    }

    await matchingCx.updatePitches({
      pitches: pitches,
      includeHitterPresent: false,
      includeLowConfidence: true,
    });

    const canRefresh = getCanRefresh(pitches);

    // by checking promptRefresh here, we guarantee we never loop indefinitely (e.g. if model refresh fails)
    if (canRefresh.length > 0 && config.promptRefresh) {
      const rebuildCallback = async () => {
        await listStore.rebuild(canRefresh.map((p) => p._id));

        clearTimeout(refreshTimeout.current);

        refreshTimeout.current = setTimeout(() => {
          if (!matchingCx.readyToTrain()) {
            NotifyHelper.warning({
              message_md:
                'Training cannot be performed at this time. Please try again later.',
            });
            return;
          }

          handleTrainPitches({ ...config, promptRefresh: false });
        }, 1_000);
      };

      if (AUTO_REFRESH_ON_TRAIN) {
        rebuildCallback();
        return;
      }

      NotifyHelper.warning({
        message_md: t('pl.x-pitches-refresh-msg', { x: canRefresh.length }),
        delay_ms: 0,
        buttons: [
          {
            label: 'pl.refresh-and-train',
            color: RADIX.COLOR.TRAIN_PITCH,
            onClick: rebuildCallback,
            dismissAfterClick: true,
          },
          {
            label: 'common.ignore',
            onClick: () => {
              if (!matchingCx.readyToTrain()) {
                NotifyHelper.warning({
                  message_md: 'common.training-unavailable-msg',
                });
                return;
              }

              handleTrainPitches({
                ...config,
                promptRefresh: false,
              });
            },
            dismissAfterClick: true,
          },
        ],
      });
      return;
    }

    // sidebar goes away, forces user to reselect a pitch after training
    aimingCx.setPitch(undefined);

    listStore.openDialog({
      dialog: PitchListDialog.DialogTraining,
      pitches: pitches,
    });
  };

  /** for active row selection functionality */
  const afterChangeSelected = async (pitch?: IPitch) => {
    if (!pitch) {
      return;
    }

    // table selected row changes when firing within a queue without user input
    const autoSend = pitch && pitch._id === queueCx.autoSendID;

    await aimingCx.setPitch(pitch, {
      resetPlate: ResetPlateMode.PitchTraj,
      loadShots: true,
      sendConfig: autoSend
        ? {
            training: false,
            skipPreview: false,
            trigger: `${COMPONENT_NAME} > after change selected`,
          }
        : undefined,
    });
  };

  const getListActions = (): IMenuAction[] | undefined => {
    if (sectionsCx.active.subsection === SubSectionName.Library) {
      return;
    }

    if (anyLoading()) {
      return;
    }

    const activeList = listsCx.active as IPitchList;

    if (!activeList) {
      return;
    }

    const countTrained = getTrainingStatus(true).length;

    const canRefresh = getCanRefresh(listStore.pitches);

    const readonly = listStore.activeReadOnly();

    const actions: IMenuAction[] = [
      {
        group: '_1',
        label: 'common.edit-list',
        prefixIcon: <Pencil2Icon />,
        invisible: readonly || authCx.restrictedGameStatus,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogEditList,
          }),
      },
      {
        group: '_1',
        label: 'common.duplicate-list',
        prefixIcon: <CopyIcon />,
        invisible: authCx.restrictedGameStatus,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogCopyList,
          }),
      },
      {
        group: '_1',
        label: 'common.reorder-list',
        prefixIcon: <RowsIcon />,
        invisible: readonly || authCx.restrictedGameStatus,
        onClick: () => {
          /** reset queue before reorder to avoid confusion after reorder list is complete */
          setState((prev) => ({
            ...prev,
            queuePitchIDs: [],
          }));
          tableCx.showReorder();
        },
      },

      // divider

      {
        group: '_2',
        prefixIcon: <BaseballIcon />,
        label: 'common.create-pitch',
        disabled: authCx.restrictedGameStatus,
        onClick: () => {
          // provide _parent_id to pre-select this list for save dialog
          const folderPitch = machineCx.getDefaultPitch();
          folderPitch._parent_id = activeList._id;

          designCx.setReference(folderPitch);

          setTimeout(() => {
            sectionsCx.tryChangeSection({
              trigger: 'PitchesHeader > actions menu',
              section: SectionName.Pitches,
              subsection: SubSectionName.Design,
            });
          }, 100);
        },
      },
      {
        group: '_2',
        prefixIcon: <UploadIcon />,
        label: 'common.upload-pitches',
        disabled: authCx.restrictedGameStatus,
        onClick: () =>
          sectionsCx.tryChangeSection({
            trigger: 'PitchesHeader > actions menu',
            section: SectionName.Pitches,
            subsection: SubSectionName.Upload,
          }),
      },

      // divider

      {
        group: 'pl.bulk-edit',
        label: 'common.export-csv',
        prefixIcon: <DownloadIcon />,
        invisible:
          readonly ||
          authCx.restrictedGameStatus ||
          listStore.pitches.length === 0,
        onClick: () => {
          const pitches = listStore.pitches.map((p) =>
            PitchListHelper.convertToCustomExport({
              pitch: p,
              plate_ft: machineCx.machine.plate_distance,
              video: videosCx.getVideo(p.video_id),
            })
          );

          SessionEventsService.postEvent({
            category: 'pitch',
            tags: 'export',
            data: {
              event: 'list (all) to CSV',
              list_id: activeList._id,
              list_name: activeList.name,
              count: pitches.length,
            },
          });

          MainService.getInstance()
            .convertJSONToCSV(pitches)
            .then((csvString) => {
              const blob = new Blob([csvString], { type: 'text/csv' });
              MiscHelper.saveAs(blob, `${slugify(activeList.name)}.csv`);
            });
        },
      },
      {
        group: 'pl.bulk-edit',
        label: 'common.import-csv',
        prefixIcon: <UploadIcon />,
        invisible:
          readonly ||
          authCx.restrictedGameStatus ||
          listStore.pitches.length === 0,
        onClick: () => {
          if (fileInput.current) {
            fileInput.current.handleClick();
          } else {
            console.warn('no file input element');
          }
        },
      },

      // divider

      {
        group: '_3',
        label: 'pl.refresh-models',
        prefixIcon: <ReloadIcon />,
        invisible: ONLY_ALLOW_REFRESH_ON_TRAIN || canRefresh.length === 0,
        color: RADIX.COLOR.SUCCESS,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogResetList,
            pitches: canRefresh,
          }),
      },
      {
        group: '_3',
        label: 'common.reset-training-data',
        prefixIcon: <ResetIcon />,
        invisible: readonly || countTrained === 0,
        color: RADIX.COLOR.WARNING,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogReset,
            pitches: listStore.pitches,
          }),
      },
      {
        group: '_3',
        label: 'pl.rename-folder',
        prefixIcon: <InputIcon />,
        invisible:
          readonly || authCx.restrictedGameStatus || !activeList.folder,
        color: RADIX.COLOR.WARNING,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogRenameFolder,
          }),
      },
      {
        group: '_3',
        label: 'pl.manage-card',
        prefixIcon: <SuperAdminIcon />,
        invisible:
          authCx.current.role !== UserRole.admin ||
          activeList.type !== PitchListExtType.Card,
        color: RADIX.COLOR.SUPER_ADMIN,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogCard,
          }),
      },
      {
        group: '_3',
        label: 'common.delete',
        prefixIcon: <TrashIcon />,
        invisible: readonly || authCx.restrictedGameStatus,
        color: RADIX.COLOR.DANGER,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogDeleteList,
          }),
      },
    ];

    return actions;
  };

  const onChangeTrained = () => {
    queueCx.changeQueue(queueCx.def.type);
    listStore.updateTrainingStatus();
  };

  /**
   * true if any of lists context, machine context, matching context, or model refresh are loading
   */
  // Probably doesn't need to be a function
  const anyLoading = () => {
    return (
      listStore.loading ||
      listsCx.loading ||
      machineCx.loading ||
      matchingCx.loading
    );
  };

  const getCheckedMenuActions = (checked: IPitch[]) => {
    if (checked.length === 0) {
      return [];
    }

    const isChecked = (id: string) =>
      checked.findIndex((p) => p._id === id) !== -1;

    const countTrained = getTrainingStatus(true).filter((p) =>
      isChecked(p._id)
    ).length;

    const canRefresh = checked.filter((p) => matchingCx.readyToRefresh(p));

    const readonly = listStore.activeReadOnly();

    const actions: IMenuAction[] = [
      {
        label: 'common.train-pitches',
        invisible: !matchingCx.readyToTrain(),
        color: RADIX.COLOR.TRAIN_PITCH,
        onClick: () =>
          handleTrainPitches({
            ids: checked.map((p) => p._id),
            promptRefresh: true,
          }),
      },
      {
        label: 'pl.change-videos',
        invisible: readonly,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogChangeVideo,
            pitches: checked,
          }),
      },
      {
        label: 'pl.copy-pitches',
        invisible: authCx.restrictedGameStatus,
        onClick: () => {
          const checkedPitchIDs = checked.map((p) => p._id);
          const originalPitches = listStore.pitches.filter((p) =>
            checkedPitchIDs.includes(p._id)
          );

          if (originalPitches.length !== checkedPitchIDs.length) {
            NotifyHelper.error({
              message_md:
                'There was an error, please try again after refreshing.',
            });
            console.error({
              event: `${COMPONENT_NAME}: failed to find all checked pitches in active context.`,
              checkedPitches: checked,
              originalPitches,
            });
            return;
          }

          listStore.openDialog({
            dialog: PitchListDialog.DialogCopy,
            pitches: originalPitches,
          });
        },
      },
      {
        label: 'pl.export-pitches',
        invisible: authCx.restrictedGameStatus,
        onClick: async () => {
          if (!listsCx.active) {
            NotifyHelper.warning({
              message_md: t('pl.no-active-pitch-list'),
            });
            return;
          }

          const activeList = listsCx.active;
          if (!activeList) {
            // do nothing
            return;
          }

          const payload = checked.map((p) =>
            PitchListHelper.convertToCustomExport({
              pitch: p,
              plate_ft: machineCx.machine.plate_distance,
              video: videosCx.getVideo(p.video_id),
            })
          );

          SessionEventsService.postEvent({
            category: 'pitch',
            tags: 'export',
            data: {
              event: 'list (checked) to CSV',
              list_id: activeList._id,
              list_name: activeList.name,
              count: payload.length,
            },
          });

          const csvString =
            await MainService.getInstance().convertJSONToCSV(payload);

          const blob = new Blob([csvString], { type: 'text/csv' });
          MiscHelper.saveAs(blob, `${slugify(activeList.name)}-checked.csv`);
        },
      },
      {
        label: 'pl.refresh-models',
        invisible:
          ONLY_ALLOW_REFRESH_ON_TRAIN || readonly || canRefresh.length === 0,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogResetList,
            /** only refresh checked pitches that are outdated */
            pitches: canRefresh,
          }),
        color: RADIX.COLOR.SUCCESS,
      },
      {
        label: 'common.reset-training-data',
        invisible: readonly || countTrained === 0,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogReset,
            pitches: checked,
          }),
        color: RADIX.COLOR.WARNING,
      },
      {
        label: 'pl.delete-pitches',
        invisible: readonly || authCx.restrictedGameStatus,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogDeletePitches,
            pitches: checked,
          }),
        color: RADIX.COLOR.DANGER,
      },
    ];

    return actions;
  };

  const getTrainingStatus = (trained: boolean): IPitch[] => {
    return listStore.pitches.filter(
      (p) => trained === matchingCx.isPitchTrained(p)
    );
  };

  const getPitchColumnText = (pitch: IPitch): string => {
    const parts: string[] = [];

    if (pitch.year) {
      parts.push(`[${pitch.year}]`);
    }

    parts.push(pitch.name || '(no name)');

    return parts.join(' ');
  };

  const getStatusColumn = (pitch: IPitch): IStatusColumn => {
    const shots = matchingCx.getAggShotsByPitch(pitch);

    const output: IStatusColumn = {
      sortValue: 0,
      trained: false,
      precision: false,
      text: 'Untrained',
      icon: (
        <Text color={RADIX.COLOR.NEUTRAL}>
          <CrossCircledIcon />
        </Text>
      ),
    };

    // whether the pitch is trained via QT or old fashioned way
    output.trained = !!shots?.trained;

    output.precision =
      !!shots?.trained &&
      !!getMSFromMSDict(pitch, machineCx.machine).ms?.precision_trained;

    if (output.precision) {
      output.sortValue = 2;
      output.text = 'Precision Trained';
      output.icon = (
        <Text color={RADIX.COLOR.SUCCESS}>
          <CustomIcon icon={CustomIconPath.CheckCircledFilled} />
        </Text>
      );
    } else if (output.trained) {
      output.sortValue = 1;
      output.text = 'Trained';
      output.icon = (
        <Text color={RADIX.COLOR.SUCCESS}>
          <CheckCircledIcon />
        </Text>
      );
    }

    return output;
  };

  const onEndTraining = (trained: IPitch[] | undefined) => {
    if (!trained || trained.length === 0) {
      return;
    }

    machineCx.resetMSHash();

    // deselect any row that was selected to force retrieval of shots on click
    tableCx.setSelected({
      page: 0,
      index: -1,
    });

    // get shots for anything that was just trained
    matchingCx
      .updatePitches({
        pitches: trained,
        includeHitterPresent: false,
        includeLowConfidence: true,
      })
      .then(() => {
        setState((prev) => ({
          ...prev,
          tableKey: Date.now(),
        }));

        onChangeTrained();
      });
  };

  // disable auto-fire and clear any leftover timeouts
  useEffect(() => {
    return () => {
      machineCx.setAutoFire(false);
      clearTimeout(refreshTimeout.current);
    };
  }, []);

  // Note: The useEffect this was refactored from in listCx had searchCriteria as a dependency
  // It was removed from this useEffect and placed directly in listStore.setSearchCriteria for simplicity
  useDeepCompareEffect(() => {
    listStore.onSearch();
  }, [
    // run this on mount...
    listStore.onSearch,
    // when the active list changes...
    listsCx.active?._id, // Do we have to watch for changes to other properties of the active list?
    // when pitch lists are refetched (e.g. when new pitches are uploaded via csv)
    listsCx.lastFetched,
  ]);

  const renderBody = () => {
    const pagination: ITablePageable = {
      identifier: IDENTIFIER,
      enablePagination: true,
      total: listStore.pitches.length,
      pageSizes: PAGE_SIZES,
    };

    const sort: ITableSortable = {
      enableSort: true,
      beforeSortFn: async () => {
        if (listsCx.active?._id !== SEARCH_ID) {
          return;
        }

        if (listStore.searchCriteria.limit === MAX_SEARCH_LIMIT) {
          return;
        }

        // setting the search will cause the limit to be set to the max
        await listStore.setSearchCriteria(listStore.searchCriteria);
      },
    };

    const reorder: ITableReorder = {
      collection: 'pitches',
      mappingFn: (item: IPitch) => {
        return {
          label: item.name ?? t('pl.unnamed-pitch'),
          value: item._id,
        };
      },
    };

    const select: ITableSelectable = {
      enableSelect: true,
      blockSelect: matchingCx.loading,
      afterChangeSelected: (model: IPitch | undefined) =>
        afterChangeSelected(model),
    };

    const checkable: ITableCheckable = {
      checkboxColumnIndex: 1,
      checkedActions: getCheckedMenuActions,
      afterCheckOne: (pitchID, checked) => {
        queueCx.changeQueue(queueCx.def.type, {
          action: checked ? 'add' : 'remove',
          id: pitchID,
        });
      },
      afterCheckMany: () => {
        queueCx.changeQueue(queueCx.def.type);
      },
      afterCheckAll: () => {
        queueCx.changeQueue(queueCx.def.type);
      },
    };

    return (
      <FlexTableWrapper
        gap={RADIX.FLEX.GAP.SECTION}
        header={
          <>
            {renderHeader()}
            <ActiveCalibrationModelWarning showSettingsButton />
          </>
        }
        table={
          <>
            <CommonTable
              id={COMPONENT_NAME}
              toolbarContent={<PitchListToolbar />}
              displayColumns={BASE_COLUMNS}
              displayData={listStore.pitches}
              rowClassNameFn={(pitch: IPitch) =>
                matchingCx.isPitchTrained(pitch) ? 'Sendable' : 'Trainable'
              }
              loading={listStore.loading}
              {...pagination}
              {...checkable}
              {...reorder}
              {...select}
              {...sort}
              vFlex
            />

            {renderDialogs()}
          </>
        }
        footer={ENABLE_LOGS ? <CommonLogs logs={state.logs} /> : undefined}
      />
    );
  };

  const renderDialogs = () => {
    const readOnly = listStore.activeReadOnly();

    return (
      <>
        {listStore.dialogCard && listsCx.active && (
          <ManageCardDialog
            key={listStore.dialogCard}
            identifier="PitchListManageCardDialog"
            authCx={authCx}
            machineCx={machineCx}
            activeList={listsCx.active}
            onClose={async () => {
              machineCx.resetMSHash();
              listStore.closeDialog({
                dialog: PitchListDialog.DialogCard,
              });
            }}
          />
        )}

        {listStore.dialogCopyList && listsCx.active && (
          <ManageListDialog
            key={listStore.dialogCopyList}
            identifier="PitchListCopyListDialog"
            mode="copy"
            onCreated={() =>
              listStore.closeDialog({
                dialog: PitchListDialog.DialogCopyList,
              })
            }
            onClose={() =>
              listStore.closeDialog({
                dialog: PitchListDialog.DialogCopyList,
              })
            }
          />
        )}

        {listStore.dialogEditList && listsCx.active && (
          <ManageListDialog
            key={listStore.dialogEditList}
            identifier="PitchListEditListDialog"
            mode="edit"
            onCreated={() =>
              listStore.closeDialog({
                dialog: PitchListDialog.DialogEditList,
              })
            }
            onClose={() =>
              listStore.closeDialog({
                dialog: PitchListDialog.DialogEditList,
              })
            }
          />
        )}

        {listStore.dialogRenameFolder && listsCx.active && (
          <RenameFolderDialog
            key={listStore.dialogRenameFolder}
            list={listsCx.active}
            onClose={() =>
              listStore.closeDialog({
                dialog: PitchListDialog.DialogRenameFolder,
              })
            }
          />
        )}

        {listStore.dialogChangeVideo && listStore.managePitches && (
          <ChangeVideoDialog
            key={listStore.dialogChangeVideo}
            pitches={listStore.managePitches}
            onClose={async (result) => {
              if (!result) {
                listStore.closeDialog({
                  dialog: PitchListDialog.DialogChangeVideo,
                });
                return;
              }

              /** if selected pitch also had its video updated, update video details so the video preview updates */
              const changedPitch = result.find(
                (p) => p._id === aimingCx.pitch?._id
              );
              if (!changedPitch) {
                listStore.closeDialog({
                  dialog: PitchListDialog.DialogChangeVideo,
                });
                return;
              }

              /** force resend => new video needs to be rendered */
              await aimingCx.setPitch(changedPitch);

              listStore.closeDialog({
                dialog: PitchListDialog.DialogChangeVideo,
              });
            }}
          />
        )}

        {listStore.dialogCopy && listStore.managePitches && (
          <CopyPitchesDialogHoC
            key={listStore.dialogCopy}
            identifier="PitchListCopyPitchesDialog"
            reloadPitches={listStore.reloadPitches}
            title={t('common.copy-x', {
              x: t(
                listStore.managePitches.length === 1
                  ? 'common.pitch'
                  : 'common.pitches'
              ),
            })}
            description={t('pl.select-pitch-list-to-copy-into').toString()}
            pitches={listStore.managePitches}
            onCreated={() => {
              listStore.closeDialog({
                dialog: PitchListDialog.DialogCopy,
              });
              // since you may have copied trained pitches back into this list
              queueCx.changeQueue(queueCx.def.type);
            }}
            onClose={() =>
              listStore.closeDialog({
                dialog: PitchListDialog.DialogCopy,
              })
            }
          />
        )}

        {listStore.dialogEdit && listStore.managePitches && (
          <EditPitchDialog
            key={listStore.dialogEdit}
            pitch={listStore.managePitches[0]}
            onClose={async () => {
              machineCx.resetMSHash();

              listStore.closeDialog({
                dialog: PitchListDialog.DialogEdit,
              });
            }}
          />
        )}

        {listStore.dialogData && listStore.managePitches && (
          <PitchDataDialog
            key={listStore.dialogData}
            identifier="PitchListViewDataDialog"
            cookiesCx={cookiesCx}
            authCx={authCx}
            machine={machineCx.machine}
            pitch={listStore.managePitches[0]}
            onClose={() => {
              listStore.closeDialog({
                dialog: PitchListDialog.DialogData,
              });
            }}
            showTrainingData
          />
        )}

        {listStore.dialogOptimize && listStore.managePitches && (
          <OptimizePitchDialog
            key={listStore.dialogOptimize}
            pitch={listStore.managePitches[0]}
            machineCx={machineCx}
            matchingCx={matchingCx}
            readonly={readOnly}
            onClose={() => {
              listStore.closeDialog({
                dialog: PitchListDialog.DialogOptimize,
              });
              // since you may have deleted training data that untrains a pitch
              onChangeTrained();
            }}
          />
        )}

        {listStore.dialogEditBreaks && listStore.managePitches && (
          <EditBreaksDialog
            key={listStore.dialogEditBreaks}
            pitch={listStore.managePitches[0]}
            machineCx={machineCx}
            matchingCx={matchingCx}
            readonly={readOnly}
            onClose={async () => {
              machineCx.resetMSHash();
              listStore.closeDialog({
                dialog: PitchListDialog.DialogEditBreaks,
              });
              // since you may have modified a trained pitch to make it untrained
              onChangeTrained();
            }}
          />
        )}

        {listStore.dialogEditSpins && listStore.managePitches && (
          <EditSpinsDialog
            key={listStore.dialogEditSpins}
            pitch={listStore.managePitches[0]}
            machineCx={machineCx}
            matchingCx={matchingCx}
            readonly={readOnly}
            onClose={async () => {
              machineCx.resetMSHash();
              listStore.closeDialog({
                dialog: PitchListDialog.DialogEditSpins,
              });
              // since you may have modified a trained pitch to make it untrained
              onChangeTrained();
            }}
          />
        )}

        {listStore.dialogReset && listStore.managePitches && (
          <ResetTrainingDialog
            key={listStore.dialogReset}
            pitches={listStore.managePitches}
            onClose={() => {
              setState((prev) => ({
                ...prev,
                tableKey: Date.now(),
              }));
              listStore.closeDialog({
                dialog: PitchListDialog.DialogReset,
              });
              // since you may have modified a trained pitch to make it untrained
              onChangeTrained();
            }}
          />
        )}
      </>
    );
  };

  const renderHeader = () => {
    if (!listsCx.active || listsCx.active._id === SEARCH_ID) {
      return <PitchesHeader />;
    }

    const untrained = getTrainingStatus(false);

    return (
      <Header
        list={listsCx.active}
        actions={getListActions()}
        mainAction={
          untrained.length === 0
            ? // don't allow train all from search view
              undefined
            : {
                label: t('pl.train-n-new-x', {
                  n: untrained.length,
                  x: t(
                    untrained.length === 1 ? 'common.pitch' : 'common.pitches'
                  ).toLowerCase(),
                }).toString(),
                disabled: !matchingCx.readyToTrain(),
                variant: 'soft',
                color: RADIX.COLOR.TRAIN_PITCH,
                className: 'text-titlecase',
                onClick: () =>
                  handleTrainPitches({
                    ids: untrained.map((p) => p._id),
                    promptRefresh: true,
                  }),
              }
        }
      />
    );
  };

  const renderSidebar = () => {
    return (
      <PitchListSidebar
        training={!!listStore.dialogTraining}
        onMatchesChanged={(newPitch) => {
          if (newPitch) {
            return;
          }

          queueCx.sendSelected('onMatchesChanged', true);
        }}
        onVideoChanged={async (video_id) => {
          const pitch = aimingCx.pitch;
          if (!pitch) {
            return;
          }

          const changed = pitch.video_id !== video_id;
          if (!changed) {
            return;
          }

          // check for video errors if necessary
          if (video_id) {
            const video = videosCx.getVideo(video_id);

            if (!video) {
              NotifyHelper.error({
                message_md: `Video \`${video_id}\` does not exist in context. Please try again.`,
              });
              return;
            }

            const warnings = VideoHelper.validateSelection({
              pitch_name: pitch.name,
              position: pitch.bs,
              video: video,
            });

            // only notify the first error that comes up
            if (warnings.length > 0) {
              NotifyHelper.warning({
                message_md: warnings[0],
                inbox: true,
              });
            }
          }

          const results = await listStore.updatePitches({
            payloads: [
              {
                _id: pitch._id,
                video_id: video_id ?? '',
              },
            ],
          });
          const updatedPitch = results?.find((p) => p._id === pitch._id);
          await aimingCx.setPitch(updatedPitch);
        }}
        handleTrainPitches={handleTrainPitches}
        enableFire
      />
    );
  };

  const renderTrainingDialog = (trainingCx: ITrainingContext) => {
    if (!listStore.dialogTraining) {
      return;
    }

    if (!listStore.managePitches) {
      return;
    }

    if (!matchingCx.aggReady) {
      return;
    }

    if (!listStore.managePitches) {
      return;
    }

    if (listStore.managePitches.length === 0) {
      return;
    }

    const mode = authCx.effectiveTrainingMode();

    if (mode === TrainingMode.Manual) {
      const pitches = listStore.managePitches ?? [];

      return (
        <TrainingDialog
          key={listStore.dialogTraining}
          identifier="PL-TrainingDialog"
          machineCx={machineCx}
          trainingCx={trainingCx}
          pitches={pitches}
          threshold={machineCx.machine.training_threshold}
          onClose={() => {
            listStore.closeDialog({
              dialog: PitchListDialog.DialogTraining,
            });
            onEndTraining(pitches);
          }}
        />
      );
    }

    return (
      <PresetTrainingDialog
        key={listStore.dialogTraining}
        identifier="PL-PT-TrainingDialog"
        machineCx={machineCx}
        trainingCx={trainingCx}
        updatePitches={listStore.updatePitches}
        pitches={listStore.managePitches ?? []}
        onClose={() => {
          listStore.closeDialog({
            dialog: PitchListDialog.DialogTraining,
          });
          onEndTraining(listStore.managePitches);
        }}
      />
    );
  };

  const onCSVChange = (files: File[]): Promise<void> => {
    return listsCx.updateListViaCSV(files).then((success) => {
      if (success) {
        NotifyHelper.success({
          message_md: 'CSV import processed successfully!',
        });
        return;
      }

      NotifyHelper.error({
        message_md: `CSV import was not processed successfully. ${ERROR_MSGS.CONTACT_SUPPORT}`,
      });
    });
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Box className={RADIX.VFLEX.WRAPPER} flexGrow="1">
        <CommonContentWithSidebar
          left={renderBody()}
          right={renderSidebar()}
          vFlex
        />
      </Box>

      <TrainingProvider
        mode={authCx.effectiveTrainingMode()}
        afterTrainingMsg={afterTrainingMsg}
      >
        <TrainingContext.Consumer>
          {(trainingCx) => renderTrainingDialog(trainingCx)}
        </TrainingContext.Consumer>
      </TrainingProvider>

      {listStore.dialogResetList && (
        <RefreshListDialog
          pitches={listStore.managePitches ?? []}
          onRefresh={() => {
            if (!listStore.managePitches) {
              return;
            }

            const canRebuild = getCanRefresh(listStore.managePitches);
            listStore.rebuild(canRebuild.map((p) => p._id));
            onChangeTrained();
          }}
        />
      )}

      {listStore.dialogDeletePitches && (
        <DeletePitchesDialog
          key={listStore.dialogDeletePitches}
          pitches={listStore.managePitches ?? []}
          onDelete={() => {
            // selected pitch was deleted, the sidebar should be cleared
            if (
              aimingCx.pitch &&
              listStore.managePitches
                ?.map((p) => p._id)
                .includes(aimingCx.pitch._id)
            ) {
              aimingCx.setPitch(undefined);
            }

            machineCx.resetMSHash();
            // deleted pitch may still be in the queue
            onChangeTrained();
          }}
        />
      )}

      {listStore.dialogDeleteList && (
        <PitchListsContext.Consumer>
          {(listsCx) => (
            <CommonConfirmationDialog
              key={listStore.dialogDeleteList}
              identifier="DeleteListDialog"
              title={t('common.delete-x', {
                x: t('common.pitch-list'),
              }).toString()}
              description={t('common.confirm-delete-x', {
                x: listsCx.active?.name ?? '(no name)',
              }).toString()}
              action={{
                label: 'common.delete',
                color: RADIX.COLOR.DANGER,
                onClick: () => {
                  if (!listsCx.active) {
                    return;
                  }

                  listsCx.deleteLists([listsCx.active._id]).then((success) => {
                    if (success) {
                      sectionsCx.tryGoHome();
                    }
                  });
                },
              }}
            />
          )}
        </PitchListsContext.Consumer>
      )}

      <CommonSimpleFileUploader
        ref={(elem) => (fileInput.current = elem as CommonSimpleFileUploader)}
        id="pitch-list-uploader"
        acceptedTypes={['text/csv']}
        notifyMode="aggregate"
        onChange={(files) => onCSVChange(files)}
        hidden
      />
    </ErrorBoundary>
  );
};
