import { DownloadIcon } from '@radix-ui/react-icons';
import { Box, Button, Flex } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { BasicVideoPlayer } from 'components/common/video/basic-player';
import { IVideosContext } from 'contexts/videos/videos.context';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { GenerateStadium } from 'lib_ts/enums/videos.enums';
import { IVideo } from 'lib_ts/interfaces/i-video';
import { IGenerateVideoOptions } from 'lib_ts/interfaces/mlb-stats-api/i-generate-video-request';
import { IMlbPitchExt } from 'lib_ts/interfaces/mlb-stats-api/i-pitch';
import React from 'react';
import { MlbStatsService } from 'services/mlb-stats.service';

const COMPONENT_NAME = 'GenerateVideoDialog';

interface IProps extends IBaseDialog {
  videosCx: IVideosContext;
  guid: IMlbPitchExt;
}

interface IState {
  loading: boolean;

  options: IGenerateVideoOptions;

  video?: IVideo;
  playbackUrl?: string;
}

export class GenerateVideoDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loading: false,
      options: {
        stadium: GenerateStadium.Default,
      },
    };

    this.generate = this.generate.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  private async generate() {
    this.setState({ loading: true });

    const video = await MlbStatsService.getInstance().makeSkeletalVideo(
      this.props.guid.gamePk,
      this.props.guid.guid,
      {
        guid: this.props.guid,
        options: this.state.options,
      }
    );

    if (!video) {
      this.setState({
        loading: false,
      });
      return;
    }

    this.props.videosCx
      .getCachedPlayback(video._id)
      .then((playback) => {
        if (playback) {
          this.setState({
            video: video,
            playbackUrl: playback.video.url,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);

        NotifyHelper.error({
          message_md: `There was an error generating your video. See console for more details.`,
        });

        this.setState({
          video: video,
          playbackUrl: undefined,
          loading: false,
        });
      });
  }

  private renderContent() {
    try {
      if (this.state.loading) {
        return <p>Please wait...</p>;
      }

      return (
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <Flex gap={RADIX.FLEX.GAP.FORM}>
            <Box flexGrow="1">
              <CommonSelectInput
                id="video-stadium"
                name="stadium"
                placeholder="Stadium"
                options={Object.values(GenerateStadium).map((g) => ({
                  label: g,
                  value: g,
                }))}
                value={this.state.options.stadium}
                onChange={(v) =>
                  this.setState({
                    options: {
                      ...this.state.options,
                      stadium: v as GenerateStadium,
                    },
                  })
                }
                disabled={this.state.loading}
              />
            </Box>

            <Box>
              <Button
                color={RADIX.COLOR.INFO}
                disabled={this.state.loading}
                onClick={() => this.generate()}
              >
                Generate
              </Button>
            </Box>
          </Flex>

          {this.state.video && this.state.playbackUrl && (
            <Box>
              <BasicVideoPlayer
                video_url={this.state.playbackUrl}
                video_mime_type={this.state.video.mime_type}
                height_px={this.state.video.cap_size_0}
                width_px={this.state.video.cap_size_1}
              />
            </Box>
          )}
        </Flex>
      );
    } catch (e) {
      console.error(e);
      return <CommonCallout text="common.request-failed-msg" />;
    }
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={this.props.identifier}
          title="Generate Video"
          width={RADIX.DIALOG.WIDTH.LG}
          content={this.renderContent()}
          buttons={[
            {
              invisible: !this.state.playbackUrl,
              icon: <DownloadIcon />,
              label: 'common.download',
              color: RADIX.COLOR.INFO,
              onClick: () => window.open(this.state.playbackUrl),
            },
          ]}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }
}
