import { CaretDownIcon } from '@radix-ui/react-icons';
import { Box, Button } from '@radix-ui/themes';
import { CommonMenu } from 'components/common/menu';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { Fragment, useMemo } from 'react';

const MAX_BUTTONS_WITHOUT_MENU = 2;

const ActionButton = (props: IMenuAction) => (
  <Button
    title={props.tooltip}
    disabled={props.disabled}
    className={props.className}
    color={props.color}
    variant={props.variant}
    onClick={props.onClick}
  >
    {props.prefixIcon}
    {props.label && t(props.label)}
    {props.suffixIcon}
  </Button>
);

export const SectionHeaderActions = (props: { actions: IMenuAction[] }) => {
  const visible = useMemo(
    () => props.actions.filter((a) => !a.invisible),
    [props.actions]
  );

  if (visible.length <= MAX_BUTTONS_WITHOUT_MENU) {
    return (
      <Fragment>
        {visible.map((btn, i) => (
          <Box key={i}>
            <ActionButton {...btn} />
          </Box>
        ))}
      </Fragment>
    );
  }

  return (
    <CommonMenu
      title={t('common.actions')}
      side="bottom"
      trigger={
        <Button>
          {t('common.actions')} <CaretDownIcon />
        </Button>
      }
      actions={visible}
      skipSort
    />
  );
};
