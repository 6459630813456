import { Button, Flex } from '@radix-ui/themes';
import { CommonDialog } from 'components/common/dialogs';
import { VideoSelectionForm } from 'components/common/dialogs/video-selection/form';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonVideoPreview } from 'components/common/video/preview';
import { IVideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import { DEFAULT_ACCEPT_BTN, IBaseDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import React from 'react';

const COMPONENT_NAME = 'VideoSelectionDialog';

/** shared by the dialog and form components */
export interface IVideoSelectionProps {
  /** provide for default value */
  video_id?: string;

  /** for passing the selected video_id value back to parent component */
  handleChange: (video_id?: string) => void;
}

interface IProps extends IBaseDialog, IVideoSelectionProps {
  // when false, only the button to trigger onOpen is shown
  showDialog?: boolean;
  showCurrent?: boolean;

  /** for parent to show dialog */
  onOpen?: () => void;

  /** from release position */
  px: number;

  videosCx: IVideosContext;
}

interface IState {
  video_id?: string;
  video_playback?: IVideoPlayback;
}

/**
 * video_id selections in the embedded form will only update this component's state video_id
 * iff accepted for close, this will pass its state video_id to the parent
 */
export class VideoSelectionDialog extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      video_id: props.video_id,
    };
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;

    if (this.state.video_id) {
      this.props.videosCx
        .getCachedPlayback(this.state.video_id)
        .then((playback) => {
          if (playback?.video._id === this.state.video_id) {
            this.setState({ video_playback: playback });
          }
        });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ): void {
    if (this.state.video_id !== prevState.video_id) {
      // video has changed, update playback
      if (this.state.video_id) {
        this.props.videosCx
          .getCachedPlayback(this.state.video_id)
          .then((playback) => {
            if (playback?.video._id === this.state.video_id) {
              this.setState({ video_playback: playback });
            }
          });
      } else {
        // clear the video
        this.setState({ video_playback: undefined });
      }
    }
  }

  render() {
    const options = this.props.videosCx.getVideosByReleaseSide(this.props.px);
    const current = this.props.video_id
      ? options.find((vo) => vo._id === this.props.video_id)
      : undefined;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        {(this.props.showCurrent || this.props.onOpen) && (
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            {this.props.showCurrent && (
              <div>
                {current
                  ? current.VideoTitle || current.VideoFileName
                  : t('videos.no-video')}
              </div>
            )}

            {this.props.onOpen && (
              <div>
                <Button onClick={this.props.onOpen}>
                  {t('videos.select-video')}
                </Button>
              </div>
            )}
          </Flex>
        )}

        {this.props.showDialog && (
          <CommonDialog
            identifier={this.props.identifier}
            width={RADIX.DIALOG.WIDTH.MD}
            title="videos.assign-video"
            content={
              <CommonFormGrid columns={1}>
                <VideoSelectionForm
                  video_id={this.props.video_id}
                  options={options}
                  handleChange={(video_id) =>
                    this.setState({ video_id: video_id })
                  }
                />

                {this.state.video_playback && (
                  <CommonVideoPreview
                    playback={this.state.video_playback}
                    hideHeader
                  />
                )}
              </CommonFormGrid>
            }
            buttons={[
              {
                ...DEFAULT_ACCEPT_BTN,
                onClick: () => {
                  this.props.handleChange(this.state.video_id);
                  this.props.onClose();
                },
              },
            ]}
            onClose={this.props.onClose}
          />
        )}
      </ErrorBoundary>
    );
  }
}
