import { DownloadIcon } from '@radix-ui/react-icons';
import { CommonDialog } from 'components/common/dialogs';
import { CommonVideoPlayer } from 'components/common/video/player';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import { ReactNode } from 'react';

interface IPlaybackDialogProps {
  identifier: string;
  title: ReactNode;
  subtitle?: ReactNode;

  playback: IVideoPlayback;
  onClose: () => void;
}

export const VideoPlaybackDialog = (props: IPlaybackDialogProps) => {
  return (
    <CommonDialog
      title={props.title}
      subtitle={props.subtitle}
      identifier={props.identifier}
      content={<CommonVideoPlayer playback={props.playback} />}
      buttons={[
        {
          icon: <DownloadIcon />,
          label: 'common.export-video',
          onClick: () => window.open(props.playback.video.url, '_blank'),
        },
      ]}
      width={RADIX.DIALOG.WIDTH.MD}
      onClose={props.onClose}
    />
  );
};
