import { Flex, SegmentedControl } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTextareaInput } from 'components/common/form/textarea';
import { DEFAULT_CARD } from 'components/common/pitch-lists/manage-card/details-tab';
import env from 'config';
import { AuthContext } from 'contexts/auth.context';
import {
  MIN_MODEL_BUILDER_GROUPS,
  REF_LIST_SHOTS_OPTIONS,
} from 'contexts/machine-calibration.context';
import { MachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { t } from 'i18next';
import { DEFAULT_ACCEPT_BTN, IBaseDialog } from 'interfaces/i-dialogs';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { PitchListExtType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import {
  FOLDER_SEPARATOR,
  IPitchList,
  safeFolder,
} from 'lib_ts/interfaces/pitches/i-pitch-list';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

const COMPONENT_NAME = 'ManageListDialog';

export const getVisibilityBlurb = (parent?: string, machineID?: string) => {
  switch (parent) {
    case PitchListOwner.Team: {
      return t('pl.accessible-to-team');
    }

    case PitchListOwner.Machine: {
      return t('pl.accessible-to-x', { x: machineID || t('common.machine') });
    }

    case PitchListOwner.User: {
      return t('pl.accessible-to-user');
    }

    default: {
      return;
    }
  }
};

enum FolderMode {
  Existing = 'common.existing-folder',
  New = 'common.new-folder',
}

const MAX_NAME_LENGTH = 30;
const MAX_FOLDER_LENGTH = 60;
const MAX_DESC_LENGTH = 3000;

interface IProps extends IBaseDialog {
  mode: 'create' | 'edit' | 'copy';
  // should also close the dialog when ready
  onCreated: (list: IPitchList) => void;
}

/** used for creating, copying, and editing pitch lists */
export const ManageListDialog = (props: IProps) => {
  const { current } = useContext(AuthContext);
  const { machine } = useContext(MachineContext);
  const {
    active: aList,
    lists,
    loading,
    copyList,
    createList,
    updateList,
  } = useContext(PitchListsContext);

  const parentOptions = useMemo(() => {
    const output: IOption[] = [
      {
        label: 'common.personal',
        value: PitchListOwner.User,
      },
    ];

    if (current.machine_lists) {
      output.push({
        label: 'common.machine',
        value: PitchListOwner.Machine,
      });
    }

    if (current.team_lists) {
      output.push({
        label: 'common.team',
        value: PitchListOwner.Team,
      });
    }

    return output;
  }, [current.machine_lists, current.team_lists]);

  const typeOptions = useMemo(() => {
    const output: IOption[] = [];

    Object.values(PitchListExtType).forEach((m) => {
      const option: IOption = {
        label: m,
        value: m,
      };

      switch (m) {
        case PitchListExtType.Card: {
          if (current.quick_session) {
            output.push(option);
          }
          break;
        }

        case PitchListExtType.Reference: {
          if (current.role === UserRole.admin) {
            output.push(option);
          }
          break;
        }

        case PitchListExtType.Sample: {
          if (env.enable.sample_lists) {
            output.push(option);
          }
          break;
        }

        default: {
          break;
        }
      }
    });

    return output;
  }, [current.quick_session, current.role]);

  const [formModel, setFormModel] = useState<Partial<IPitchList>>({});

  const updateFormModel = useCallback(
    (value: Partial<IPitchList>) =>
      setFormModel({
        ...formModel,
        ...value,
      }),
    [formModel, setFormModel]
  );

  const [folderMode, setFolderMode] = useState(FolderMode.Existing);

  const [tempParentDef, setTempParentDef] = useState(formModel._parent_def);

  // init the form model based on whether we're editing an existing list
  useEffect(() => {
    const defaultParentDef = current.machine_lists
      ? PitchListOwner.Machine
      : PitchListOwner.User;

    if (aList !== undefined) {
      const nextModel: Partial<IPitchList> = { ...aList };
      if (
        props.mode === 'copy' &&
        aList.name &&
        !aList.name.startsWith('Copy of ')
      ) {
        nextModel.name = `Copy of ${aList.name}`;
      }

      if (!aList.name) {
        nextModel.name = 'Unnamed';
      }

      if (!aList.folder) {
        nextModel.folder = '';
      }

      if (!aList._parent_def) {
        nextModel._parent_def = defaultParentDef;
      }

      setFormModel(nextModel);
      setTempParentDef(nextModel._parent_def);
      return;
    }

    // brand new list, set some defaults
    const nextModel: Partial<IPitchList> = {
      ...formModel,
      _parent_def: defaultParentDef,
      name: `Pitch List #${lists.length + 1}`,
      folder: '',
    };
    setFormModel(nextModel);
    setTempParentDef(nextModel._parent_def);
  }, []);

  useEffect(() => {
    if (tempParentDef !== formModel._parent_def) {
      updateFormModel({ _parent_id: undefined });
    }
  }, [tempParentDef, updateFormModel]);

  const existingFolderOptions = useMemo(() => {
    const sameParent = lists.filter((l) => l._parent_def === tempParentDef);

    const uniqueFolders = ArrayHelper.unique(sameParent.map((l) => l.folder));

    const output: IOption[] = uniqueFolders.map((s) => {
      const o: IOption = {
        label: s,
        value: s,
      };

      return o;
    });

    return output;
  }, [lists, tempParentDef]);

  const dialogTitle = useMemo(() => {
    switch (props.mode) {
      case 'edit': {
        return t('common.edit-x', { x: t('common.list') });
      }

      case 'copy': {
        return t('common.duplicate-x', { x: t('common.list') });
      }

      case 'create': {
        return t('common.create-x', { x: t('common.list') });
      }

      default: {
        return t('common.manage-x', { x: t('common.list') });
      }
    }
  }, [props.mode]);

  const dialogDescription = useMemo(() => {
    switch (props.mode) {
      case 'edit': {
        return t('pl.edit-your-pitch-list-msg').toString();
      }

      case 'copy': {
        return t('pl.duplicate-your-pitch-list-msg').toString();
      }

      case 'create': {
        return t('pl.create-your-pitch-list-msg').toString();
      }

      default: {
        return undefined;
      }
    }
  }, [props.mode]);

  const warningBlurb = useMemo(
    () => getVisibilityBlurb(formModel._parent_def, machine.machineID),
    [formModel._parent_def]
  );

  const isSuperAdmin = current.role === UserRole.admin;

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={props.identifier}
        loading={loading}
        width={RADIX.DIALOG.WIDTH.MD}
        title={dialogTitle}
        description={dialogDescription}
        content={
          <CommonFormGrid columns={1}>
            {isSuperAdmin && typeOptions.length > 0 && (
              <CommonSelectInput
                id="manage-list-type"
                name="type"
                label="common.type"
                labelIcon={<SuperAdminIcon />}
                options={typeOptions}
                value={formModel.type}
                onChange={(v) =>
                  updateFormModel({
                    type: v as PitchListExtType,
                  })
                }
                disabled={loading}
                labelOptional
                optional
              />
            )}

            {isSuperAdmin && formModel.type === PitchListExtType.Reference && (
              <CommonCallout
                text={`Any reference lists for model building must contain at least ${MIN_MODEL_BUILDER_GROUPS} unique pitch(es).`}
                color={RADIX.COLOR.SUPER_ADMIN}
              />
            )}

            <CommonTextInput
              id="manage-list-name"
              label="common.name"
              name="name"
              value={formModel.name}
              disabled={loading}
              placeholder="Type in a list name"
              maxLength={MAX_NAME_LENGTH}
              onChange={(v) =>
                updateFormModel({
                  name: v,
                })
              }
            />

            <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
              <CommonInputLabel
                name="manage-list-folder-path"
                label="Select Folder / Path"
              />

              <SegmentedControl.Root
                value={folderMode}
                onValueChange={(v) => setFolderMode(v as FolderMode)}
              >
                {Object.values(FolderMode).map((o, i) => (
                  <SegmentedControl.Item key={`mode-${i}`} value={o}>
                    {t(o)}
                  </SegmentedControl.Item>
                ))}
              </SegmentedControl.Root>

              {folderMode === FolderMode.Existing && (
                <CommonSearchInput
                  id="existing-folder"
                  name="search-existing-folder"
                  options={existingFolderOptions}
                  values={formModel.folder ? [formModel.folder] : []}
                  onChange={(v) =>
                    updateFormModel({
                      folder: v[0],
                    })
                  }
                  disabled={loading}
                  optional
                />
              )}

              {folderMode === FolderMode.New && (
                <CommonTextInput
                  id="manage-list-folder"
                  name="folder"
                  value={formModel.folder}
                  disabled={loading}
                  placeholder="Type in a folder or path (optional)"
                  maxLength={MAX_FOLDER_LENGTH}
                  onChange={(v) =>
                    updateFormModel({
                      folder: v,
                    })
                  }
                  hint_md={`e.g. "Team A" or "Practice${FOLDER_SEPARATOR}Warm-Up"`}
                />
              )}
            </Flex>

            <CommonSelectInput
              id="manage-list-visibility"
              name="_parent_def"
              label="common.visibility"
              options={parentOptions}
              value={tempParentDef}
              onChange={(v) => setTempParentDef(v as PitchListOwner)}
              disabled={loading}
              hint_md={warningBlurb}
            />

            {isSuperAdmin && formModel.type === PitchListExtType.Reference && (
              <CommonSelectInput
                id="manage-list-live"
                name="live"
                label="Live"
                labelColor={RADIX.COLOR.SUPER_ADMIN}
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
                value={formModel.live?.toString()}
                onOptionalBooleanChange={(v) => updateFormModel({ live: v })}
                disabled={loading}
                hint_md="For reference lists, this will determine whether it is usable in model building UI."
                optional
              />
            )}

            {isSuperAdmin && formModel.type === PitchListExtType.Reference && (
              <CommonSelectInput
                id="manage-list-calibration-shots"
                name="shots"
                label="Calibration Shots"
                labelColor={RADIX.COLOR.SUPER_ADMIN}
                options={REF_LIST_SHOTS_OPTIONS}
                value={formModel.default_calibration_shot_count?.toString()}
                onNumericChange={(v) => {
                  if (v <= 0) {
                    NotifyHelper.warning({
                      message_md: 'Shots per pitch must be positive.',
                    });
                    return;
                  }

                  updateFormModel({ default_calibration_shot_count: v });
                }}
                hint_md="Provide a value to prescribe a fixed number of shots per pitch in machine calibration."
                optional
                skipSort
              />
            )}

            {isSuperAdmin && formModel.type === PitchListExtType.Reference && (
              <CommonTextareaInput
                id="manage-list-description"
                label="common.description"
                labelColor={RADIX.COLOR.SUPER_ADMIN}
                name="description_md"
                value={formModel.description_md}
                disabled={loading}
                placeholder="Describe how this list should be used..."
                maxLength={MAX_DESC_LENGTH}
                onChange={(v) => updateFormModel({ description_md: v })}
                hint_md="Markdown input is also accepted"
                rows={8}
              />
            )}
          </CommonFormGrid>
        }
        buttons={[
          {
            ...DEFAULT_ACCEPT_BTN,
            onClick: () => {
              if (!formModel.name?.trim()) {
                NotifyHelper.error({
                  message_md: t('common.check-inputs-msg'),
                });
                return;
              }

              const payload: Partial<IPitchList> = {
                ...formModel,
                folder: safeFolder(formModel.folder),
                /** parent id will be set based on def, but via the server to ensure consistency */
                _parent_def: tempParentDef,
              };

              if (props.mode === 'edit' || props.mode === 'copy') {
                payload._id = aList?._id;
              }

              if (payload.type === PitchListExtType.Card && !payload.card) {
                payload.card = DEFAULT_CARD;
              }

              switch (props.mode) {
                case 'create': {
                  createList(payload)
                    .then((list) => {
                      if (list) {
                        props.onCreated(list);
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                      NotifyHelper.error({
                        message_md: 'There was an error, please try again.',
                      });
                    });
                  break;
                }

                case 'edit': {
                  updateList({ payload: payload })
                    .then((list) => {
                      if (list) {
                        props.onCreated(list);
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                      NotifyHelper.error({
                        message_md: 'There was an error, please try again.',
                      });
                    });
                  break;
                }

                case 'copy': {
                  if (!payload?._id) {
                    NotifyHelper.error({
                      message_md: `Original list must be provided for copying. ${ERROR_MSGS.CONTACT_SUPPORT}`,
                    });
                    return;
                  }

                  copyList(payload)
                    .then((list) => {
                      if (list) {
                        props.onCreated(list);
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                      NotifyHelper.error({
                        message_md: 'There was an error, please try again.',
                      });
                    });
                  break;
                }
              }
            },
          },
        ]}
        onClose={props.onClose}
      />
    </ErrorBoundary>
  );
};
