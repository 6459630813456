import { Avatar } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { GameDataContext } from 'contexts/game-data.context';
import { RadixAvatarSize } from 'lib_ts/enums/radix-ui';
import { useContext, useMemo } from 'react';

export const GameDataPlayerAvatar = (props: {
  size: RadixAvatarSize;
  // for URL
  playerPk: number;
  // for fallback
  name?: string;
}) => {
  const { seasonPlayers } = useContext(GameDataContext);

  const color = useMemo(
    () =>
      seasonPlayers.find((p) => p.playerPk === props.playerPk)?.teamColorHex ??
      'var(--gray-4)',
    [props.playerPk]
  );

  const initials = useMemo(
    () => StringHelper.getInitials(props.name),
    [props.name]
  );

  const url = `https://img.mlbstatic.com/mlb-photos/image/upload/people/${props.playerPk}/headshot/silo/current.png`;

  return (
    <Avatar
      src={url}
      fallback={initials}
      size={props.size}
      style={{
        background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%), ${color}`,
        backgroundBlendMode: 'normal, multiply, normal',
      }}
    />
  );
};
