import { PlusIcon, UpdateIcon } from '@radix-ui/react-icons';
import { Code, Grid, Text } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { ReassignListsDialog } from 'components/common/pitch-lists/reassign-lists';
import { CommonTableHoC } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import { RefreshListsDialog } from 'components/sections/admin-portal/dialogs/refresh-lists';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import { EditTeamDialog } from 'components/sections/admin-portal/teams/edit';
import { ITeamsContext, TeamsContext } from 'contexts/admin/teams.context';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { GlobalContext } from 'contexts/global.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { SubSectionName } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { MlbAbbreviation } from 'lib_ts/enums/batrack.enums';
import { GameStatus } from 'lib_ts/enums/mlb.enums';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import { IAdminTeam, ITeam } from 'lib_ts/interfaces/i-team';
import { IMongoBase } from 'lib_ts/interfaces/mongo/_base';
import { IPitchListPutManyRequest } from 'lib_ts/interfaces/pitches/i-pitch-list';
import React, { useContext } from 'react';
import { AuthService } from 'services/auth.service';

const IDENTIFIER = TableIdentifier.AdminTeamList;

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

interface IProps {
  authCx: IAuthContext;
  teamsCx: ITeamsContext;
}

interface IDialogs {
  dialogEditor?: number;
  dialogReassign?: number;
  dialogRefresh?: number;
}

interface IState extends IDialogs {
  manageTeam?: ITeam;
  reassignListsPayload: IPitchListPutManyRequest;

  override: GameStatus;
}

export const TeamsTableHoC = () => {
  const props: IProps = {
    authCx: useContext(AuthContext),
    teamsCx: useContext(TeamsContext),
  };

  return <TeamsTable {...props} />;
};

class TeamsTable extends React.Component<IProps, IState> {
  private init = false;

  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: [
        {
          label: 'common.edit',
          onClick: (m: ITeam) => {
            this.setState({
              manageTeam: m,
              dialogEditor: Date.now(),
            });
          },
        },
        {
          label: 'admin.refresh-lists',
          color: RADIX.COLOR.WARNING,
          onClick: (m: ITeam) => {
            this.setState({
              manageTeam: m,
              dialogRefresh: Date.now(),
            });
          },
        },
        {
          label: 'common.reassign-lists',
          onClick: (m: ITeam) => {
            const payload: IPitchListPutManyRequest = {
              filter: {
                _parent_id: m._id,
                _parent_def: PitchListOwner.Team,
              },
              update: {
                processed: new Date(),
                process_notes: `reassigned from team ${m.name} by admin ${this.props.authCx.current.email}`,
              },
            };

            this.setState({
              dialogReassign: Date.now(),
              reassignListsPayload: payload,
            });
          },
          color: RADIX.COLOR.WARNING,
        },
      ],
    },
    {
      label: 'common.created',
      key: '_created',
      formatFn: (m: IMongoBase) =>
        lightFormat(parseISO(m._created), 'yyyy-MM-dd'),
    },
    {
      label: 'common.name',
      key: 'name',
      formatFn: (m: IAdminTeam) => {
        if (m.abbreviation && m.abbreviation !== MlbAbbreviation.None) {
          return `${m.name} (${m.abbreviation})`;
        }

        return m.name;
      },
    },
    {
      label: 'common.game-status',
      key: 'batrackStatus',
      formatFn: (m: IAdminTeam) => {
        if (
          !m.batrackStatus ||
          ![
            GameStatus.InProgress,
            GameStatus.Unreachable,
            GameStatus.NoGame,
          ].includes(m.batrackStatus)
        ) {
          return <></>;
        }

        const color: RadixColor | undefined = (() => {
          switch (m.batrackStatus) {
            case GameStatus.InProgress: {
              return RADIX.COLOR.WARNING;
            }

            case GameStatus.Unreachable:
            case GameStatus.Unknown: {
              return RADIX.COLOR.DANGER;
            }

            case GameStatus.NoGame: {
              return RADIX.COLOR.SUCCESS;
            }

            default: {
              return;
            }
          }
        })();

        return <Text color={color}>{m.batrackStatus}</Text>;
      },
    },
    {
      label: 'admin.domains',
      key: 'email_domains',
      sortRowsFn: (a: ITeam, b: ITeam, dir: number) =>
        ((a.email_domains ?? []).length > (b.email_domains ?? []).length
          ? 1
          : -1) * dir,
      formatFn: (m: ITeam) => {
        if (m.email_domains && m.email_domains.length > 0) {
          return (
            <ul className="no-style no-padding">
              {m.email_domains.map((d, i) => (
                <li key={i}>
                  <Code>{d}</Code>
                </li>
              ))}
            </ul>
          );
        }

        return '--';
      },
    },
    {
      label: 'admin.machines',
      key: 'count_machines',
      align: 'right',
    },
    {
      label: 'admin.users',
      key: 'count_users',
      align: 'right',
    },
    {
      label: 'admin.videos',
      key: 'count_videos',
      align: 'right',
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = {
      reassignListsPayload: { filter: {}, update: {} },
      override: GameStatus.Unknown,
    };

    this.renderDialogs = this.renderDialogs.bind(this);
  }

  componentDidMount(): void {
    if (!this.init) {
      this.init = true;

      if (this.props.authCx.current.role === UserRole.admin) {
        AuthService.getInstance()
          .getGameStatusOverride()
          .then((gs) => {
            this.setState({ override: gs });
          });
      }
    }
  }

  // todo: these belong in the context
  private renderDialogs() {
    return (
      <>
        {this.state.dialogRefresh && this.state.manageTeam && (
          <RefreshListsDialog
            key={this.state.dialogRefresh}
            name={this.state.manageTeam.name}
            parent_def={PitchListOwner.Team}
            parent_id={this.state.manageTeam._id}
            onClose={() => this.setState({ dialogRefresh: undefined })}
          />
        )}

        {this.state.dialogReassign && (
          <PitchListsContext.Consumer>
            {(listsCx) => (
              <ReassignListsDialog
                key={this.state.dialogReassign}
                identifier="TeamsTableReassignListsDialog"
                authCx={this.props.authCx}
                listsCx={listsCx}
                refPayload={this.state.reassignListsPayload}
                onClose={() => this.setState({ dialogReassign: undefined })}
              />
            )}
          </PitchListsContext.Consumer>
        )}

        {this.state.dialogEditor && (
          <AuthContext.Consumer>
            {(authCx) => (
              <EditTeamDialog
                key={this.state.dialogEditor}
                id={this.state.manageTeam?._id}
                authCx={authCx}
                teamsCx={this.props.teamsCx}
                onClose={() => this.setState({ dialogEditor: undefined })}
              />
            )}
          </AuthContext.Consumer>
        )}
      </>
    );
  }

  render() {
    const data: ITeam[] = this.props.teamsCx.teams;

    const pagination: ITablePageable = {
      identifier: IDENTIFIER,
      total: data.length,
      enablePagination: true,
      pageSizes: PAGE_SIZES,
    };

    const sort: ITableSortable = {
      enableSort: true,
      defaultSort: {
        key: 'name',
        dir: -1,
      },
    };

    const select: ITableSelectable = {
      enableSelect: true,
    };

    return (
      <ErrorBoundary componentName="TeamsTable">
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={<AdminTabNav active={SubSectionName.Teams} />}
          table={
            <GlobalContext.Consumer>
              {(globalCx) => (
                <CommonTableHoC
                  id="AdminTeams"
                  toolbarContent={
                    // todo: encapsulate this
                    <Grid columns="6" gap={RADIX.FLEX.GAP.SM}>
                      <CommonTableButton
                        color={RADIX.COLOR.NEUTRAL}
                        icon={<UpdateIcon />}
                        label="common.refresh"
                        disabled={this.props.teamsCx.loading}
                        onClick={() => this.props.teamsCx.refresh()}
                      />

                      <AuthContext.Consumer>
                        {(authCx) => {
                          if (authCx.current.role === UserRole.admin) {
                            return (
                              <CommonTableButton
                                color={RADIX.COLOR.SUCCESS}
                                icon={<PlusIcon />}
                                label="common.create"
                                disabled={this.props.teamsCx.loading}
                                onClick={() => {
                                  this.setState({
                                    manageTeam: undefined,
                                    dialogEditor: Date.now(),
                                  });
                                }}
                              />
                            );
                          }
                        }}
                      </AuthContext.Consumer>
                    </Grid>
                  }
                  displayColumns={this.BASE_COLUMNS}
                  displayData={data}
                  checkboxColumnIndex={0}
                  enableListener={globalCx.dialogs.length === 0}
                  {...pagination}
                  {...select}
                  {...sort}
                  vFlex
                />
              )}
            </GlobalContext.Consumer>
          }
        />

        {this.renderDialogs()}
      </ErrorBoundary>
    );
  }
}
