import { Box, Text } from '@radix-ui/themes';
import { t } from 'i18next';

export const PitchListSidebarSectionTitle = (props: {
  label: string;
  subLabel?: string;
  title?: string;
  className?: string;
}) => {
  return (
    // minWidth is needed for truncation
    <Box minWidth="0">
      <Text size="2" title={props.title} className={props.className} truncate>
        {t(props.label)}
        {props.subLabel && <small className="block">{t(props.subLabel)}</small>}
      </Text>
    </Box>
  );
};
