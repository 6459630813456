import { parseISO, startOfHour } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_TIMEZONE } from 'enums/env';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { TIMEZONES } from 'lib_ts/enums/datetime.enums';
import { MainService } from 'services/main.service';
import slugify from 'slugify';

const ROWS_PER_FILE = 500;

type stringish = string | undefined;

export class StringHelper {
  static getTimezoneString = (config: { value: string; format: string }) => {
    const date = startOfHour(parseISO(config.value));
    const offsetHours = -date.getTimezoneOffset() / 60;
    const tz = TIMEZONES.find(
      (t) => t.offset === offsetHours && t.utc.includes(LOCAL_TIMEZONE)
    );

    return `${format(date, config.format)} ${tz?.abbr ?? offsetHours}`;
  };

  static hexToRgb = (hex?: string): number[] => {
    const black = [0, 0, 0];

    if (!hex) {
      return black;
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result) {
      return black;
    }

    return [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ];
  };

  static keyify(v: string): string[] {
    const values = slugify(v, {
      lower: true,
      strict: true,
    }).split('-');

    return ArrayHelper.unique(values);
  }

  static capitalize(value: string, delimiter?: string): string {
    const parts = value.split(delimiter ?? ' ');

    return parts
      .map((p) => String(p).charAt(0).toUpperCase() + String(p).slice(1))
      .join(delimiter ?? ' ');
  }

  static classNames(values: stringish[]): string {
    return ArrayHelper.unique(values.filter((v) => v?.trim()))
      .join(' ')
      .trim();
  }

  // strips any accent characters and converts to lower case
  static normalize(value?: string) {
    if (!value) {
      return '';
    }

    return (
      value
        .normalize('NFD')
        // remove all accents
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    );
  }

  static async saveCsv(
    data: any[],
    options: {
      prefix: string;
      maxRows?: number;
    }
  ) {
    if (data.length === 0) {
      return;
    }

    const maxRows = options.maxRows ?? ROWS_PER_FILE;

    // only one file is needed
    if (data.length <= maxRows) {
      const csvString = await MainService.getInstance().convertJSONToCSV(data);
      const blob = new Blob([csvString], { type: 'text/csv' });

      MiscHelper.saveAs(blob, `${options.prefix}.csv`);
      return;
    }

    /** split rows into separate files to avoid exceeding server payload limits */
    const chunks = ArrayHelper.chunkArray(data, maxRows);

    // split the data into multiple files
    for (let i = 0; i < chunks.length; i++) {
      const csvString = await MainService.getInstance().convertJSONToCSV(
        chunks[i]
      );

      const blob = new Blob([csvString], { type: 'text/csv' });

      const start = i * maxRows + 1;
      const end = Math.min((i + 1) * maxRows, data.length);

      MiscHelper.saveAs(blob, `${options.prefix}_${start}-${end}.csv`);
    }
  }

  static getInitials(name: stringish): string {
    const split = slugify(name ?? '', { strict: true, trim: true }).split('-');

    return split
      .map((s) => (s ? s.substring(0, 1) : '?'))
      .join('')
      .toUpperCase();
  }
}
