import { Box } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { LOCAL_TIMEZONE } from 'enums/env';
import { getTimeFromDate, ITime } from 'interfaces/forms/date';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { TIMEZONES } from 'lib_ts/enums/datetime.enums';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import React, { ReactNode } from 'react';

// every hour from 0-23
const HOUR_OPTIONS = ArrayHelper.getIntegerOptions(0, 23).map((o) => ({
  ...o,
  label: `${o.label}h`,
}));

// every 5 min from 0-55
export const NEAREST_MINUTE = 5;

const MINUTE_OPTIONS = ArrayHelper.getIntegerOptions(0, 59)
  .filter((o) => parseInt(o.value) % NEAREST_MINUTE === 0)
  .map((o) => ({ ...o, label: `${o.label}m` }));

export const VALID_TZ = TIMEZONES.filter(
  (t) => t.enabled || t.utc.includes(LOCAL_TIMEZONE)
).sort((a, b) => (a.offset < b.offset ? -1 : 1));

const TZ_OPTIONS = VALID_TZ.map((t) => {
  const o: IOption = {
    label: t.abbr,
    value: t.abbr,
  };
  return o;
});

interface IProps {
  defaultTime?: ITime;

  onChangeTime: (value: ITime | undefined) => void;
}

interface IState {
  // updated whenever a click goes through
  selected: ITime;

  // different abbr can have the same offset
  abbr: string;
}

export class CommonTimeWidget extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const selected =
      props.defaultTime ?? getTimeFromDate(new Date(), NEAREST_MINUTE);

    this.state = {
      selected: selected,
      abbr: VALID_TZ.find((t) => t.offset === selected.offset)?.abbr ?? '',
    };
  }

  componentDidMount(): void {
    // forces the original value to match what selected rounded into
    this.props.onChangeTime(this.state.selected);
  }

  render(): ReactNode {
    return (
      <CommonFormGrid columns={5}>
        <Box gridColumn="span 2">
          <CommonSelectInput
            id="time-widget-hour"
            value={this.state.selected.h.toString()}
            options={HOUR_OPTIONS}
            onNumericChange={(v) =>
              this.setState(
                {
                  selected: {
                    ...this.state.selected,
                    h: v,
                  },
                },
                () => this.props.onChangeTime(this.state.selected)
              )
            }
            skipSort
          />
        </Box>

        <Box gridColumn="span 2">
          <CommonSelectInput
            id="time-widget-minute"
            value={this.state.selected.m.toString()}
            options={MINUTE_OPTIONS}
            onNumericChange={(v) =>
              this.setState(
                {
                  selected: {
                    ...this.state.selected,
                    m: v,
                  },
                },
                () => this.props.onChangeTime(this.state.selected)
              )
            }
            skipSort
          />
        </Box>

        <CommonSelectInput
          id="time-widget-tz-abbr"
          value={this.state.abbr}
          options={TZ_OPTIONS}
          onChange={(abbr) => {
            const tz = VALID_TZ.find((t) => t.abbr === abbr);

            if (!tz) {
              NotifyHelper.error({
                message_md: `Failed to find timezone with abbreviation ${abbr}.`,
              });
              return;
            }

            this.setState(
              {
                abbr: abbr,
                selected: {
                  ...this.state.selected,
                  offset: tz.offset,
                },
              },
              () => this.props.onChangeTime(this.state.selected)
            );
          }}
        />
      </CommonFormGrid>
    );
  }
}
