import { ICheckableTally } from 'interfaces/i-contexts';
import { IMongoBase } from 'lib_ts/interfaces/mongo/_base';
import { createContext, FC, ReactNode, useMemo, useState } from 'react';

const CONTEXT_NAME = 'CheckedContext';

export interface ICheckedContext {
  // when something within the data changes (e.g. check/uncheck 1 item) and only the item + tally needs to change
  tally: ICheckableTally;

  // the last item that was checked or unchecked (used as a reference for shift-click bulk selection)
  lastCheckedIndex: number | null;
  readonly setLastCheckedIndex: (value: number | null) => void;

  readonly getChecked: (id: string) => boolean;
  readonly setChecked: (ids: string[], value: boolean) => void;
  readonly resetChecked: () => void;
}

const DEFAULT: ICheckedContext = {
  tally: {
    key: Date.now(),
    checked: 0,
    total: 0,
    mode: 'none',
  },

  lastCheckedIndex: null,
  setLastCheckedIndex: () => console.error(`${CONTEXT_NAME}: not init`),

  getChecked: () => false,
  setChecked: () => console.error(`${CONTEXT_NAME}: not init`),
  resetChecked: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const CheckedContext = createContext(DEFAULT);

interface IProps {
  data: IMongoBase[];
  children: ReactNode;
}

export const CheckedProvider: FC<IProps> = (props) => {
  const [_checkedDict, _setCheckedDict] = useState<{
    [id: string]: boolean | undefined;
  }>({});

  const _ids = useMemo(() => props.data.map((m) => m._id), [props.data]);

  const _tally = useMemo(() => {
    const checked = Object.keys(_checkedDict)
      // found in valid ids list (i.e. omit deleted stuff)
      .filter((id) => _ids.includes(id))
      // marked as true (i.e. omit unchecked stuff)
      .filter((id) => _checkedDict[id]).length;

    const o: ICheckableTally = {
      key: Date.now(),
      checked: checked,
      total: props.data.length,
      mode:
        props.data.length === 0 || checked === 0
          ? 'none'
          : props.data.length === checked
          ? 'all'
          : 'some',
    };

    return o;
  }, [_ids, _checkedDict]);

  const [_lastCheckedIndex, _setLastCheckedIndex] = useState(
    DEFAULT.lastCheckedIndex
  );

  const state: ICheckedContext = {
    tally: _tally,

    lastCheckedIndex: _lastCheckedIndex,
    setLastCheckedIndex: _setLastCheckedIndex,

    getChecked: (id) => !!_checkedDict[id],
    setChecked: (ids, value) => {
      ids.forEach((id) => {
        _checkedDict[id] = value;
      });

      _setCheckedDict({ ..._checkedDict });
    },

    resetChecked: () => _setCheckedDict({}),
  };

  return (
    <CheckedContext.Provider value={state}>
      {props.children}
    </CheckedContext.Provider>
  );
};
