export type MachineMode = 'empty-carousel' | 'inspect-machine' | 'stereo-check';

export enum CalibrationStep {
  Setup,
  CollectData,
  ReviewMetric,
  TrainError,
}

export enum MachineButtonMode {
  NoPitch = 'NoPitch',
  Calibrate = 'Calibrate',
  Fire = 'Fire',
  Refresh = 'Refresh',
  Send = 'Send',
  Train = 'Train',
  Unavailable = 'Unavailable',
}

export enum ResetPlateMode {
  // i.e. DEFAULT_PLATE
  Default = 'Default',
  // plate based on traj from pitch
  PitchTraj = 'PitchTraj',
  // plate based on backup value
  PitchBackup = 'PitchBackup',
}
