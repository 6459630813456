import { StateCreator } from 'zustand';
import {
  PitchListState,
  PitchListStorePropsAndDependencies,
} from 'components/sections/pitch-list/store/pitch-list-store';
import { IAuthContext } from 'contexts/auth.context';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { IPitchListsContext } from 'contexts/pitch-lists/lists.context';

type ContextsDependencies = {
  // These are required for now since this store has to interact with legacy contexts
  authCx: IAuthContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  listsCx: IPitchListsContext;
};

export type ContextsSlice = ContextsDependencies & {
  authCx: IAuthContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  listsCx: IPitchListsContext;
  // Actions
  setContexts: (contexts: ContextsDependencies) => void;
};

type CreateContextsSlice = (
  props: PitchListStorePropsAndDependencies
) => StateCreator<
  PitchListState,
  [['zustand/devtools', never]],
  [],
  ContextsSlice
>;

export const createContextsSlice: CreateContextsSlice =
  (props) => (set, get) => ({
    ...props,
    setContexts: (contexts) => {
      set(
        {
          ...contexts,
        },
        undefined,
        'pitchList/setContexts'
      );
    },
  });
