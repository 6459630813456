import { NotifyHelper } from 'classes/helpers/notify.helper';
import { MlbSportId } from 'lib_ts/enums/mlb-stats-api/base.enum';
import { MLB_TEAMS } from 'lib_ts/enums/mlb.enums';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { IVideo } from 'lib_ts/interfaces/i-video';
import {
  IMlbGame,
  IMlbGameScore,
} from 'lib_ts/interfaces/mlb-stats-api/i-game';
import { IGenerateVideoRequest } from 'lib_ts/interfaces/mlb-stats-api/i-generate-video-request';
import { IMlbPitchExt } from 'lib_ts/interfaces/mlb-stats-api/i-pitch';
import { IMlbPlayerExt } from 'lib_ts/interfaces/mlb-stats-api/i-player';
import { IMlbTeam, IMlbTeamExt } from 'lib_ts/interfaces/mlb-stats-api/i-team';
import { MlbStatsSkeletalData } from 'lib_ts/interfaces/mlb-stats-api/raw-results/game-guids-biomechanics';
import { MlbStatsGameGuidVideo } from 'lib_ts/interfaces/mlb-stats-api/raw-results/game-guids-video';
import { BaseRESTService } from 'services/_base-rest.service';

interface ISeasonTally {
  season: number;
  total: number;
}

export class MlbStatsService extends BaseRESTService {
  private static instance: MlbStatsService;
  static getInstance(): MlbStatsService {
    if (!MlbStatsService.instance) {
      MlbStatsService.instance = new MlbStatsService();
    }

    return MlbStatsService.instance;
  }

  private constructor() {
    super({
      controller: 'mlb-stats',
    });
  }

  async countGames(config: { sportId: MlbSportId }): Promise<ISeasonTally[]> {
    return await this.get({
      uri: 'games/count',
      params: {
        sportId: config.sportId,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to count games.',
          });
          return [];
        }

        return result.data as ISeasonTally[];
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to count games.',
        });
        console.error(e);
        return [];
      });
  }

  // id = _id from mlb-stats-games
  async getGamePlayers(
    game: IMlbGame,
    teams: IMlbTeam[]
  ): Promise<IMlbPlayerExt[]> {
    return await this.get({
      uri: `game/${game._id}/players`,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get players for game.',
          });
          return [];
        }

        const homeTeam = teams.find((m) => m.teamPk === game.home.teamPk);
        const awayTeam = teams.find((m) => m.teamPk === game.away.teamPk);

        const output = result.data as IMlbPlayerExt[];

        if (homeTeam && awayTeam) {
          for (const player of output) {
            const isHome = !!homeTeam.playerPks.includes(player.playerPk);
            const team = isHome ? homeTeam : awayTeam;

            player.isHome = isHome;
            player.teamPk = team.teamPk;
          }
        }

        return output;
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get players for game.',
        });
        console.error(e);
        return [];
      });
  }

  async getGameScore(gameID: string): Promise<IMlbGameScore | undefined> {
    return await this.get({
      uri: `game/${gameID}/score`,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get score for game.',
          });
          return undefined;
        }

        return result.data as IMlbGameScore;
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get score for game.',
        });
        console.error(e);
        return undefined;
      });
  }

  async getPlayerPitches(config: {
    mode: 'pitcher' | 'hitter';
    playerID: string;
  }): Promise<IMlbPitchExt[]> {
    return await this.get({
      uri: `players/${config.playerID}/pitches`,
      params: {
        role: config.mode,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get pitches for player.',
          });
          return [];
        }

        return result.data as IMlbPitchExt[];
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get pitches for player.',
        });
        console.error(e);
        return [];
      });
  }

  async getGamePitches(config: { gameID: string }): Promise<IMlbPitchExt[]> {
    return await this.get({
      uri: `game/${config.gameID}/pitches`,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get pitches for game.',
          });
          return [];
        }

        return result.data as IMlbPitchExt[];
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get pitches for game.',
        });
        console.error(e);
        return [];
      });
  }

  async getPitches(config: {
    sportId: MlbSportId;
    season: number | string;
    gamePk?: number | string;
    pitcherPk?: number | string;
    batterPk?: number | string;
  }): Promise<IMlbPitchExt[]> {
    return await this.get({
      uri: 'pitches',
      params: {
        sportId: config.sportId,
        season: config.season,
        gamePk: config.gamePk ?? -1,
        pitcherPk: config.pitcherPk ?? -1,
        batterPk: config.batterPk ?? -1,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get pitches for game.',
          });
          return [];
        }

        const output = result.data as IMlbPitchExt[];

        // for checked context to work
        output.forEach((m) => (m._id = m.guid));

        return output;
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get pitches for game.',
        });
        console.error(e);
        return [];
      });
  }

  async getGamePlayVideos(
    gamePk: number | string,
    guid: string
  ): Promise<MlbStatsGameGuidVideo | undefined> {
    return await this.get({
      uri: 'game/play/videos',
      params: {
        gamePk: gamePk,
        guid: guid,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get broadcast data.',
          });
          return;
        }

        return result.data as MlbStatsGameGuidVideo;
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get broadcast data.',
        });
        console.error(e);
        return undefined;
      });
  }

  async getGamePlaySkeletalData(
    gamePk: number | string,
    guid: string
  ): Promise<MlbStatsSkeletalData | undefined> {
    return await this.get({
      uri: 'game/play/skeletal',
      params: {
        gamePk: gamePk,
        guid: guid,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get skeletal data.',
          });
          return undefined;
        }

        return result.data as MlbStatsSkeletalData;
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get skeletal data.',
        });
        console.error(e);
        return undefined;
      });
  }

  async makeSkeletalVideo(
    gamePk: number | string,
    guid: string,
    options: IGenerateVideoRequest
  ): Promise<IVideo | undefined> {
    return await this.post(
      {
        uri: 'game/play/make-skeletal-video',
        params: {
          gamePk: gamePk,
          guid: guid,
        } as any,
      },
      options
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to generate video.',
            delay_ms: 0,
          });
          return;
        }

        return result.data as IVideo;
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to generate video.',
        });

        console.error(e);
        return undefined;
      });
  }

  async getGames(config: {
    sportId: MlbSportId;
    season: number | string;
  }): Promise<IMlbGame[]> {
    return await this.get({
      uri: 'games',
      params: {
        sportId: config.sportId,
        season: config.season,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get games.',
          });
          return [];
        }

        return result.data as IMlbGame[];
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get games.',
        });
        console.error(e);
        return [];
      });
  }

  // sorts them by name
  async getPlayers(config: {
    sportId: MlbSportId;
    season: number | string;

    // for mixin colors
    teams: IMlbTeamExt[];
  }): Promise<IMlbPlayerExt[]> {
    return await this.get({
      uri: 'players',
      params: {
        sportId: config.sportId,
        season: config.season,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get players.',
          });
          return [];
        }

        const output = result.data as IMlbPlayerExt[];

        for (const player of output) {
          const team = config.teams.find((team) =>
            team.playerPks.includes(player.playerPk)
          );

          player.teamPk = team?.teamPk;
          player.teamColorHex = team?.colorHex;
          player.teamCode = team?.code;
          player.teamName = team?.name;
          player.teamShortName = team?.shortName;
        }

        output.sort((a, b) => a.name.localeCompare(b.name));

        return output;
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get players.',
        });
        console.error(e);
        return [];
      });
  }

  async getTeams(config: {
    sportId: MlbSportId;
    season: number | string;
  }): Promise<IMlbTeamExt[]> {
    return await this.get({
      uri: 'teams',
      params: {
        sportId: config.sportId,
        season: config.season,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to get teams.',
          });
          return [];
        }

        const output = result.data as IMlbTeamExt[];

        for (const team of output) {
          const cTeam = MLB_TEAMS.find((m) => m.teamPk === team.teamPk);
          team.colorHex = cTeam?.hexColor;
          team.code = cTeam?.code;
          team.shortName = cTeam?.shortName;
        }

        output.sort((a, b) => a.name.localeCompare(b.name));

        return output;
      })
      .catch((e) => {
        NotifyHelper.error({
          message_md: 'Failed to get teams.',
        });
        console.error(e);
        return [];
      });
  }
}
