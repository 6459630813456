import { GameDataContext } from 'contexts/game-data.context';
import { MlbSportId } from 'lib_ts/enums/mlb-stats-api/base.enum';
import { useContext, useMemo } from 'react';

export const GameDataTeamLogo = (props: {
  isDark: boolean;
  // e.g. 48px
  size: string;
  teamPK: number | undefined;
  name?: string;
}) => {
  const { filterGlobal } = useContext(GameDataContext);

  const url = useMemo(() => {
    if (!props.teamPK) {
      return '/img/mlb-logo-square.svg';
    }

    const base = props.isDark
      ? 'https://www.mlbstatic.com/team-logos/team-cap-on-dark/'
      : 'https://www.mlbstatic.com/team-logos/team-cap-on-light/';

    return base + `${props.teamPK}.svg`;
  }, [props.teamPK, props.isDark]);

  if (filterGlobal.sportId !== MlbSportId.MLB) {
    return <></>;
  }

  return (
    <img
      loading="lazy"
      height={props.size}
      width={props.size}
      alt={props.name ?? 'no team'}
      src={url}
    />
  );
};
