import { IMachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { METERS_TO_INCHES } from 'lib_ts/classes/math.utilities';
import { INumberDict, INumberTally } from 'lib_ts/classes/misc.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { StaticVideoType } from 'lib_ts/enums/machine-msg.enum';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import {
  PitcherHand,
  PitcherRelease,
  TrainingStatus,
} from 'lib_ts/enums/pitches.enums';
import { EMPTY_SELECT_VALUE, RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { ICustomIO } from 'lib_ts/interfaces/csv/i-custom';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { IVideo } from 'lib_ts/interfaces/i-video';
import { IMachineStateMsg } from 'lib_ts/interfaces/machine-msg/i-machine-state';
import {
  IPitch,
  IReassignOptions,
  ITrainingDict,
  ITrajectoryBreak,
} from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';

interface IBaseConfig {
  options: IReassignOptions;
  role: UserRole;
  teamID: string;
  parentDef: string;
}

interface ICopyConfig extends IBaseConfig {}

interface IReassignConfig extends ICopyConfig {
  parentId: string;
}

const getBaseOptions = (config: IBaseConfig) => {
  switch (config.parentDef) {
    case PitchListOwner.User: {
      return config.options.users.map((m) => {
        const o: IOption = {
          label: m.email,
          group: m.machineID,
          value: m._id,
        };

        return o;
      });
    }

    case PitchListOwner.Machine: {
      return config.options.machines.map((m) => {
        const o: IOption = {
          label: `${m.machineID}${m.sandbox ? ' (Sandbox)' : ''}`,
          group: config.options.teams.find((t) => t._id === m._parent_id)?.name,
          value: m._id,
        };

        return o;
      });
    }

    case PitchListOwner.Team: {
      return config.options.teams.map((m) => {
        const o: IOption = {
          label: m.name,
          value: m._id,
        };

        return o;
      });
    }

    default: {
      return [
        {
          label: 'common.select-a-parent-type-first',
          value: EMPTY_SELECT_VALUE,
          disabled: true,
        },
      ];
    }
  }
};

export const getPitchYearOptions = (): IOption[] => {
  const first = 2020;
  const current = new Date().getFullYear();

  return Array.from({ length: 2 + current - first }, (_, i) => i + first).map(
    (yr) => ({ label: yr.toString(), value: yr.toString() })
  );
};

export const getCopyToIdOptions = (config: ICopyConfig): IOption[] => {
  return getBaseOptions(config).sort((a: IOption, b: IOption) =>
    a.label.localeCompare(b.label)
  );
};

export const getReassignToIdOptions = (config: IReassignConfig): IOption[] => {
  return getBaseOptions(config)
    .filter((o) => {
      /** don't allow reassignment to the current parent */
      return o.value !== config.parentId;
    })
    .sort((a: IOption, b: IOption) => a.label.localeCompare(b.label));
};

const MAX_SPEED_DELTA_MPH = 2;
const MAX_SPIN_DELTA_RPM = 500;

interface IPitchError {
  text: string;
  color: RadixColor;
}

export class PitchListHelper {
  static getMsMsg(config: {
    pitch: IPitch;
    machineCx: IMachineContext;
    training: boolean;
    video_uuid?: string;
  }): IMachineStateMsg | undefined {
    const FN_NAME = 'getMsMsg';
    const ms = getMSFromMSDict(config.pitch, config.machineCx.machine).ms;
    if (!ms) {
      console.warn(`${FN_NAME}: no ms found`);
      return;
    }

    const isLHP = config.pitch.bs.px > 0;

    const safeVideoID = config.video_uuid
      ? config.video_uuid
      : isLHP
      ? StaticVideoType.default_LHP
      : StaticVideoType.default_RHP;

    return {
      ...ms,
      ball_type: config.machineCx.machine.ball_type,
      video_uuid: safeVideoID,
      training: config.training,
    };
  }

  static getSafePitchBreaks(
    pitch: Partial<IPitch>
  ): ITrajectoryBreak | undefined {
    if (pitch.breaks) {
      return pitch.breaks;
    }

    if (!pitch.traj) {
      return undefined;
    }

    const trajBreaks = TrajHelper.getBreaks(pitch.traj);

    if (!isNaN(trajBreaks.xInches) && !isNaN(trajBreaks.zInches)) {
      return trajBreaks;
    }

    return undefined;
  }

  static getSafeShotBreaks(
    shot: Partial<IMachineShot>
  ): ITrajectoryBreak | undefined {
    if (shot.break) {
      return {
        xInches: -shot.break.PITCH_HBTrajectory * METERS_TO_INCHES,
        zInches: shot.break.PITCH_VBTrajectory * METERS_TO_INCHES,
      };
    }

    if (!shot.traj) {
      return;
    }

    const trajBreaks = TrajHelper.getBreaks(shot.traj);

    if (!isNaN(trajBreaks.xInches) && !isNaN(trajBreaks.zInches)) {
      return trajBreaks;
    }

    return;
  }

  static pitcherHand = (value?: PitcherHand) => {
    switch (value) {
      case PitcherHand.LHP: {
        return t('common.abbrev-left-handed-pitcher');
      }

      case PitcherHand.RHP: {
        return t('common.abbrev-right-handed-pitcher');
      }

      default: {
        return '???';
      }
    }
  };

  static shortPitcherRelease = (value?: PitcherRelease) => {
    switch (value) {
      case PitcherRelease.Overhand: {
        return t('qs.abbrev-overhand');
      }

      case PitcherRelease.Sidearm: {
        return t('qs.abbrev-sidearm');
      }

      case PitcherRelease.ThreeQuarter: {
        return t('qs.abbrev-3-quarter');
      }

      default: {
        return '???';
      }
    }
  };

  /** returns the list of the first (partial) pitches for each non-empty matching_hash (i.e. removes duplicates re: matching_hash) */
  static getUniquePitches(
    pitches: Partial<IPitch>[],
    machine: IMachine
  ): Partial<IPitch>[] {
    const uniqueDict: { [matching_hash: string]: Partial<IPitch> } = {};

    pitches.forEach((p) => {
      const ms = getMSFromMSDict(p, machine).ms;

      if (!ms?.matching_hash) {
        return;
      }

      if (uniqueDict[ms.matching_hash]) {
        return;
      }

      uniqueDict[ms.matching_hash] = p;
    });

    return Object.values(uniqueDict);
  }

  /** converts a pitch to the Custom CSV format */
  static convertToCustomExport(config: {
    pitch: IPitch;
    plate_ft: number;
    video?: IVideo;
  }): ICustomIO {
    const roundForCSV = (n: number) => Math.round(n * 100) / 100;

    const seams = BallHelper.getPitchSeams(config.pitch);

    const output: ICustomIO = {
      PitchID: config.pitch._id,
      VideoID: config.video?._id,

      PitchTitle: config.pitch.name ?? 'Unnamed',
      PitchType: config.pitch.type ?? 'No Type',
      PitcherFullName: config.video?.PitcherFullName ?? '',
      PitchYear: config.pitch.year ?? '',
      VideoTitle: config.video?.VideoTitle ?? '',

      PlateLocHeight:
        config.pitch.plate_loc_backup !== undefined
          ? roundForCSV(config.pitch.plate_loc_backup.plate_z)
          : undefined,

      PlateLocSide:
        config.pitch.plate_loc_backup !== undefined
          ? roundForCSV(config.pitch.plate_loc_backup.plate_x)
          : undefined,

      ReleaseX: roundForCSV(config.pitch.bs.px),
      ReleaseY: roundForCSV(config.plate_ft),
      ReleaseZ: roundForCSV(config.pitch.bs.pz),
      ReleaseV: roundForCSV(-config.pitch.bs.vy),
      SpinX: roundForCSV(config.pitch.bs.wx),
      SpinY: roundForCSV(config.pitch.bs.wy),
      SpinZ: roundForCSV(config.pitch.bs.wz),
      SeamLat: roundForCSV(seams.latitude_deg),
      SeamLon: roundForCSV(seams.longitude_deg),

      HorizontalBreakIn: config.pitch.breaks?.xInches,
      VerticalBreakIn: config.pitch.breaks?.zInches,
    };

    return output;
  }

  static getCopyToIdOptions = (config: ICopyConfig): IOption[] => {
    return getBaseOptions(config).sort((a: IOption, b: IOption) =>
      a.label.localeCompare(b.label)
    );
  };

  static getReassignToIdOptions = (config: IReassignConfig): IOption[] => {
    return getBaseOptions(config)
      .filter((o) => {
        /** don't allow reassignment to the current parent */
        return o.value !== config.parentId;
      })
      .sort((a: IOption, b: IOption) => a.label.localeCompare(b.label));
  };

  static getAvgShot = (shots: IMachineShot[]): Partial<IMachineShot> => {
    if (!shots || shots.length === 0) {
      return {};
    }

    const tally: { [key: string]: INumberTally } = {};

    /** cast the pitches to any to suppress typescript warnings */
    shots.forEach((p: any) => {
      ['bs', 'ms', 'traj', 'actual_ms', 'break']
        .filter((sKey) => !!p[sKey]) //only calculate if pitch has the data
        .forEach((sKey) => {
          const state = p[sKey];

          /** spawn dictionary if undefined */
          if (!tally[sKey]) {
            tally[sKey] = {};
          }

          Object.keys(state)
            .filter((aKey) => typeof state[aKey] === 'number') //only calculate for number attributes
            .forEach((aKey) => {
              /** spawn array if undefined */
              if (!tally[sKey][aKey]) {
                tally[sKey][aKey] = [];
              }

              tally[sKey][aKey].push(p[sKey][aKey]);
            });
        });
    });

    const avg: { [key: string]: INumberDict } = {};

    /** after all values are gathered, sum and divide each list of numbers
     * by their own lengths, which may be different */
    Object.keys(tally).forEach((sKey) => {
      avg[sKey] = {};

      Object.keys(tally[sKey]).forEach((aKey) => {
        const list = tally[sKey][aKey];
        if (list.length > 0) {
          avg[sKey][aKey] =
            list.reduce((prev: number, curr: number) => prev + curr, 0) /
            list.length;
        }
      });
    });

    return avg as Partial<IMachineShot>;
  };

  /**
   * comparison between pitch's bs and the actual bs (avg) from matching shots
   * @param pitch
   * @param machine
   * @returns undefined if there's nothing wrong with the pitch, otherwise provides a summary of the error
   */
  static getError = (
    pitch: IPitch,
    shots: IMachineShot[]
  ): IPitchError | undefined => {
    if (shots.length === 0) {
      return undefined;
    }

    const averagePitch = this.getAvgShot(shots);
    if (!averagePitch?.bs) {
      return undefined;
    }

    if (!averagePitch?.traj) {
      return undefined;
    }

    const actualSpeed = BallHelper.getSpeed(averagePitch.traj);
    const targetSpeed = BallHelper.getSpeed(pitch.traj);

    if (Math.abs(actualSpeed - targetSpeed) > MAX_SPEED_DELTA_MPH) {
      const error: IPitchError = {
        color: RADIX.COLOR.WARNING,
        text: [
          `Actual speed (${actualSpeed.toFixed(
            1
          )} mph) differs from target speed (${targetSpeed.toFixed(
            1
          )} mph) by more than ${MAX_SPEED_DELTA_MPH} mph.`,
          'Please reset training data at your earliest convenience.',
        ].join(' '),
      };

      return error;
    }

    const axes: { name: string; actual: number; target: number }[] = [
      { name: 'back', actual: averagePitch.bs.wx, target: pitch.bs.wx },
      { name: 'gyro', actual: averagePitch.bs.wy, target: pitch.bs.wy },
      { name: 'side', actual: averagePitch.bs.wz, target: pitch.bs.wz },
    ];

    const errorSpinAxis = axes.find(
      (m) => Math.abs(m.actual - m.target) > MAX_SPIN_DELTA_RPM
    );

    if (errorSpinAxis) {
      const error: IPitchError = {
        text: [
          `Actual ${errorSpinAxis.name} spin (${errorSpinAxis.actual.toFixed(
            0
          )} rpm) differs from target ${
            errorSpinAxis.name
          } spin (${errorSpinAxis.target.toFixed(
            0
          )} rpm) by more than ${MAX_SPIN_DELTA_RPM} rpm.`,
          'Please reset training data at your earliest convenience.',
        ].join(' '),
        color: RADIX.COLOR.WARNING,
      };

      return error;
    }
  };

  static enumeratePitches = (pitches: IPitch[], max: number): string => {
    const lines: string[] = pitches
      .filter((_, i) => i < max)
      .map((p) => ` - ${p.name}`);
    const diffMax = pitches.length - max;
    if (diffMax > 0) {
      lines.push(` - ...and ${diffMax} more`);
    }
    return lines.join('\n');
  };

  static getTrainingDict = (config: {
    machineID: string;
    current?: ITrainingDict;
    total: number;
    untrained: number;
  }): ITrainingDict => {
    const output = config.current ?? {};

    const value = (() => {
      if (config.untrained === 0) {
        return TrainingStatus.Full;
      }

      if (config.untrained === config.total) {
        return TrainingStatus.Not;
      }

      return TrainingStatus.Partial;
    })();

    output[config.machineID] = value;

    return output;
  };
}
