import { CardStackIcon } from '@radix-ui/react-icons';
import { Skeleton } from '@radix-ui/themes';
import { CommonNavMenu } from 'components/common/layout/nav-menu';
import { MachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { SectionsContext } from 'contexts/sections.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { PitchListExtType } from 'lib_ts/enums/pitches.enums';
import { IPitchList } from 'lib_ts/interfaces/pitches';
import { useCallback, useContext, useMemo } from 'react';
import { SidebarNavigationTrigger } from '../nav-trigger';

export const MiniMenu = (props: { actions: IMenuAction[] }) => {
  const {
    active: aList,
    lists,
    loading,
    recentIDs,
  } = useContext(PitchListsContext);
  const { active: aSection, tryChangeSection } = useContext(SectionsContext);
  const { machine } = useContext(MachineContext);

  if (lists.length === 0) {
    return <Skeleton />;
  }

  const listToAction = useCallback(
    (l: IPitchList, group: string): IMenuAction => {
      return {
        group: group,
        label: l.name,
        onClick: () => {
          if (loading) {
            return;
          }

          if (
            !aList ||
            aList._id !== l._id ||
            aSection.section !== SectionName.Pitches ||
            aSection.subsection !== SubSectionName.List ||
            aSection.fragments?.[0] !== l._id
          ) {
            /** move user to pitch list (invisible) section */
            tryChangeSection({
              trigger: 'sidebar, open list',
              section: SectionName.Pitches,
              subsection: SubSectionName.List,
              fragments: [l._id],
            });
          }
        },
      };
    },
    [aList, aSection, tryChangeSection]
  );

  const extendedActions = useMemo(() => {
    const output = [...props.actions];

    const recents = lists
      .filter((l) => recentIDs.includes(l._id))
      .sort((a, b) =>
        recentIDs.findIndex((id) => id === a._id) <
        recentIDs.findIndex((id) => id === b._id)
          ? -1
          : 1
      );

    output.push(...recents.map((l) => listToAction(l, t('main.recent-lists'))));

    /** show all lists sorted alphabetically */
    const groupSortFn = (
      l: IPitchList
    ): {
      sort: number;
      group: string;
    } => {
      switch (l.type) {
        case PitchListExtType.Sample: {
          return {
            sort: 3,
            group: 'constants.pitch-list-samples',
          };
        }

        case PitchListExtType.Card: {
          return {
            sort: 5,
            group: 'constants.pitch-list-cards',
          };
        }

        case PitchListExtType.Reference: {
          return {
            sort: 4,
            group: 'constants.pitch-list-references',
          };
        }

        default: {
          break;
        }
      }

      // no type, use parent
      switch (l._parent_def) {
        case PitchListOwner.Team: {
          return {
            sort: 2,
            group: 'constants.pitch-list-team',
          };
        }

        case PitchListOwner.Machine: {
          return {
            sort: 1,
            group: t('constants.pitch-list-x-lists', {
              x: machine.machineID,
            }),
          };
        }

        case PitchListOwner.User:
        default: {
          return {
            sort: 0,
            group: 'constants.pitch-list-personal',
          };
        }
      }
    };

    output.push(
      ...lists
        .sort((a, b) => {
          const aDef = groupSortFn(a);
          const bDef = groupSortFn(b);

          if (aDef.sort !== bDef.sort) {
            return aDef.sort < bDef.sort ? -1 : 1;
          }

          // within the same category, sort by folder so folderless stuff goes to top
          return (a.folder ?? '').localeCompare(b.folder ?? '');
        })
        .map((l) => {
          const def = groupSortFn(l);
          const group = t(def.group);
          return listToAction(l, l.folder ? `${group}: ${l.folder}` : group);
        })
    );

    return output;
  }, [props.actions, recentIDs, listToAction]);

  return (
    <CommonNavMenu>
      <SidebarNavigationTrigger
        icon={<CardStackIcon />}
        label="main.pitch-lists"
        actions={extendedActions}
      />
    </CommonNavMenu>
  );
};
