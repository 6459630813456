import { Button, Flex, Text } from '@radix-ui/themes';
import { MlbStatsHelper } from 'classes/helpers/mlb-stats.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { AddAveragesIcon } from 'components/common/custom-icon/shorthands';
import { ErrorBoundary } from 'components/common/error-boundary';
import { GameDataContext } from 'contexts/game-data.context';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { mean } from 'lib_ts/classes/math.utilities';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMlbPitchExt } from 'lib_ts/interfaces/mlb-stats-api/i-pitch';
import { useContext, useMemo } from 'react';

interface IRepertoire {
  type: string;
  // 0-100%
  usage: number;
  speedMPH: number;
  xBreakIN: number;
  zBreakIN: number;
}

export const GameDataPitcherRepertoire = (props: {
  pitches: IMlbPitchExt[];
}) => {
  const { openAddDialog } = useContext(GameDataContext);

  const repertoire: IRepertoire[] = useMemo(() => {
    const total = props.pitches.length;
    const groups = ArrayHelper.groupBy(props.pitches, 'type');

    const output: IRepertoire[] = [];

    Object.keys(groups).forEach((key) => {
      const group = groups[key];

      output.push({
        type: key,
        usage: 100 * (group.length / total),
        speedMPH: mean(group.map((p) => p.releaseData.releaseSpeed)),
        xBreakIN: mean(group.map((p) => p.trajectoryData.horizontalBreak)),
        zBreakIN: mean(group.map((p) => p.trajectoryData.verticalBreak)),
      });
    });

    output.sort((a, b) => (a.usage > b.usage ? -1 : 1));

    return output;
  }, [props.pitches]);

  return (
    <ErrorBoundary componentName="GameDataPitcherRepertoire">
      <Flex p="2" gap="4" direction="column" align="end">
        {repertoire.length === 0 && (
          <CommonCallout text="common.no-results-found" />
        )}

        {repertoire.length > 0 && (
          <table style={{ borderSpacing: '8px 4px' }}>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>
                  <Text size="1" color={RADIX.COLOR.SECONDARY}>
                    Usage <sub>%</sub>
                  </Text>
                </td>
                <td>
                  <Text size="1" color={RADIX.COLOR.SECONDARY}>
                    Vel. <sub>mph</sub>
                  </Text>
                </td>
                <td>
                  <Text size="1" color={RADIX.COLOR.SECONDARY}>
                    VB <sub>in</sub>
                  </Text>
                </td>
                <td>
                  <Text size="1" color={RADIX.COLOR.SECONDARY}>
                    HB <sub>in</sub>
                  </Text>
                </td>
              </tr>

              {repertoire.map((m, i) => (
                <tr key={i}>
                  <td>
                    <Text size="1">{m.type}</Text>
                  </td>
                  <td align="right">
                    <Text size="1">{m.usage.toFixed(1)}</Text>
                  </td>
                  <td align="right">
                    <Text size="1">{m.speedMPH.toFixed(1)}</Text>
                  </td>
                  <td align="right">
                    <Text size="1">{m.xBreakIN.toFixed(1)}</Text>
                  </td>
                  <td align="right">
                    <Text size="1">{m.zBreakIN.toFixed(1)}</Text>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <Button
          variant={RADIX.BUTTON.VARIANT.SECONDARY}
          onClick={() => {
            const averages = MlbStatsHelper.averagePitches(props.pitches);

            if (!averages || averages.length === 0) {
              NotifyHelper.warning({
                message_md: `There was a problem calculating average pitches. Please check your selections and try again.`,
              });
              return;
            }

            openAddDialog({
              guids: averages,
              pitcherPk: averages[0].pitcherPk,
              isAverage: true,
            });
          }}
        >
          <AddAveragesIcon /> {t('pd.add-averages-to-pitch-list')}
        </Button>
      </Flex>
    </ErrorBoundary>
  );
};
