import {
  Card,
  Container,
  Flex,
  Heading,
  Spinner,
  Text,
} from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonContentWithSidebar } from 'components/common/layout/content-with-sidebar';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { TableProvider } from 'components/common/table/context';
import { GameDataFooter } from 'components/sections/game-data/footer';
import { GameDataPitches } from 'components/sections/game-data/pitches';
import { GameDataPitchesSidebar } from 'components/sections/game-data/pitches/sidebar';
import { GameDataTeamLogo } from 'components/sections/game-data/team-logo';
import { Breadcrumbs } from 'components/sections/header/breadcrumbs';
import { PitchListStoreProvider } from 'components/sections/pitch-list/store/use-pitch-list-store';
import { AimingProvider } from 'contexts/aiming.context';
import { GameDataContext } from 'contexts/game-data.context';
import { SectionsContext } from 'contexts/sections.context';
import { LOCAL_GAMETIME_FORMAT } from 'enums/env';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { isAppearanceDark } from 'index';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMlbGameScore } from 'lib_ts/interfaces/mlb-stats-api/i-game';
import { IMlbPitchExt } from 'lib_ts/interfaces/mlb-stats-api/i-pitch';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MlbStatsService } from 'services/mlb-stats.service';

const COMPONENT_NAME = 'GameDataGame';

export const GameDataGame = (props: { gameID: string | undefined }) => {
  const { tryChangeSection } = useContext(SectionsContext);
  const { seasonGames, seasonTeams } = useContext(GameDataContext);
  const [pitches, setPitches] = useState<IMlbPitchExt[]>();

  const [score, setScore] = useState<IMlbGameScore>();

  const game = useMemo(
    () => seasonGames.find((p) => p._id === props.gameID),
    [props.gameID, seasonGames]
  );

  useEffect(() => {
    if (score) {
      // we've already loaded data
      return;
    }

    if (!game) {
      return;
    }

    if (game.home.score !== undefined && game.away.score !== undefined) {
      // score via game data
      setScore({
        teams: {
          home: {
            runs: game.home.score,
            isWinner: !!game.home.isWinner,
          },
          away: {
            runs: game.away.score,
            isWinner: !!game.away.isWinner,
          },
        },
      });
      return;
    }

    // fallback to score via API
    MlbStatsService.getInstance()
      .getGameScore(game._id)
      .then((result) => setScore(result));
  }, [game, score]);

  const homeTeam = useMemo(
    () => seasonTeams.find((m) => m.teamPk === game?.home.teamPk),
    [seasonTeams, game]
  );

  const awayTeam = useMemo(
    () => seasonTeams.find((m) => m.teamPk === game?.away.teamPk),
    [seasonTeams, game]
  );

  const isDark = useMemo(() => isAppearanceDark(), []);
  const bgColor = isDark ? 'black' : 'white';

  const dateString = useMemo(() => {
    if (!game) {
      return '';
    }

    if (game.gameDate) {
      return StringHelper.getTimezoneString({
        value: game.gameDate,
        format: LOCAL_GAMETIME_FORMAT,
      });
    }

    return game.officialDate;
  }, [game]);

  useEffect(() => {
    if (pitches) {
      // we've already loaded data
      return;
    }

    if (!game) {
      return;
    }

    MlbStatsService.getInstance()
      .getGamePitches({
        gameID: game._id,
      })
      .then((result) => setPitches(result));
  }, [game, pitches]);

  if (!game) {
    return <Spinner size="3" />;
  }

  if (!pitches) {
    return <Spinner size="3" />;
  }

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <PitchListStoreProvider>
        <AimingProvider>
          <CommonContentWithSidebar
            left={
              <FlexTableWrapper
                header={
                  <>
                    <Breadcrumbs
                      items={[
                        {
                          label: 'common.pitches',
                          onClick: () =>
                            tryChangeSection({
                              section: SectionName.Pitches,
                              subsection: SubSectionName.Library,
                              trigger: `${COMPONENT_NAME} > breadcrumbs`,
                            }),
                        },
                        {
                          label: 'main.game-data',
                          onClick: () =>
                            tryChangeSection({
                              section: SectionName.Pitches,
                              subsection: SubSectionName.GameData,
                              trigger: `${COMPONENT_NAME} > breadcrumbs`,
                            }),
                        },
                        {
                          label: `${game.officialDate}: ${
                            awayTeam?.code ?? game.away.name
                          } @ ${homeTeam?.code ?? game.home.name}`,
                        },
                      ]}
                    />

                    <Card
                      size="5"
                      className="half-background"
                      style={{
                        background: `linear-gradient(90deg, ${
                          awayTeam?.colorHex ?? bgColor
                        } 0%, ${bgColor} 20%, ${bgColor} 80%, ${
                          homeTeam?.colorHex ?? bgColor
                        } 100%)`,
                      }}
                    >
                      <Container size="3">
                        <Flex justify="between" align="center">
                          <Flex gap="2" direction="column" align="center">
                            <GameDataTeamLogo
                              isDark={isDark}
                              size="60px"
                              teamPK={game.away.teamPk}
                            />

                            <Text size="3">
                              {awayTeam && awayTeam.code && awayTeam.shortName
                                ? `${awayTeam.code} ${awayTeam.shortName}`
                                : game.away.name}
                            </Text>
                          </Flex>

                          {score && (
                            <Heading size="8">{score.teams.away.runs}</Heading>
                          )}

                          <Flex
                            mt="5"
                            gap="2"
                            direction="column"
                            align="center"
                            justify="center"
                          >
                            <Text color={RADIX.COLOR.NEUTRAL} align="center">
                              @
                            </Text>
                            <Text
                              color={RADIX.COLOR.NEUTRAL}
                              align="center"
                              truncate
                            >
                              {dateString}
                            </Text>
                          </Flex>

                          {score && (
                            <Heading size="8">{score.teams.home.runs}</Heading>
                          )}

                          <Flex gap="2" direction="column" align="center">
                            <GameDataTeamLogo
                              isDark={isDark}
                              size="60px"
                              teamPK={game.home.teamPk}
                            />

                            <Text size="3">
                              {homeTeam && homeTeam.code && homeTeam.shortName
                                ? `${homeTeam.code} ${homeTeam.shortName}`
                                : game.home.name}
                            </Text>
                          </Flex>
                        </Flex>
                      </Container>
                    </Card>
                  </>
                }
                table={
                  <TableProvider>
                    <GameDataPitches
                      mode="games"
                      pitches={pitches}
                      playerPk={undefined}
                    />
                  </TableProvider>
                }
                footer={<GameDataFooter />}
              />
            }
            right={<GameDataPitchesSidebar />}
            vFlex
          />
        </AimingProvider>
      </PitchListStoreProvider>
    </ErrorBoundary>
  );
};
