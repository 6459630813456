import { Box, Flex, Tabs, Text } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { ActiveCalibrationModelWarning } from 'components/common/warnings/active-calibration-model-warning';
import { GameDataFooter } from 'components/sections/game-data/footer';
import { GameDataGames } from 'components/sections/game-data/games';
import { GameDataPlayers } from 'components/sections/game-data/players';
import { PitchesHeader } from 'components/sections/pitches/header';
import { GameDataContext } from 'contexts/game-data.context';
import { GameDataTab } from 'enums/game-data.enums';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';
import slugify from 'slugify';

export const GAME_DATA_ID = '----FROM GAME DATA----';

const COMPONENT_NAME = 'GameDataTabs';

export const GameDataTabs = () => {
  const { tab, setTab } = useContext(GameDataContext);

  const tabOptions: {
    label: string;
    value: GameDataTab;
  }[] = [
    {
      value: 'pitchers',
      label: 'common.pitchers',
    },
    {
      value: 'hitters',
      label: 'common.hitters',
    },
    {
      value: 'games',
      label: 'common.games',
    },
  ];

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex
        direction="column"
        gap="3"
        style={{
          height: '100%',
        }}
      >
        <Box>
          <PitchesHeader />
          <ActiveCalibrationModelWarning showSettingsButton />
        </Box>

        <Box>
          <Tabs.Root
            value={tab}
            onValueChange={(value) => setTab(value as GameDataTab)}
          >
            <Tabs.List>
              {tabOptions.map((tab, iTab) => (
                <Tabs.Trigger
                  key={`tab-${iTab}`}
                  id={slugify(tab.value, { strict: true, lower: true })}
                  value={tab.value}
                >
                  {/* double-negation necessary because Spinner's loading defaults to true */}
                  <Flex gap={RADIX.FLEX.GAP.XS} align="center">
                    <Box>
                      <Text>{t(tab.label)}</Text>
                    </Box>
                  </Flex>
                </Tabs.Trigger>
              ))}
            </Tabs.List>
            {/* instead of drawing actual tabs, render outside the tab root to allow for vertical flexing */}
          </Tabs.Root>
        </Box>

        <Box
          flexGrow="1"
          style={{
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          {tab === 'pitchers' && <GameDataPlayers mode="pitchers" />}
          {tab === 'hitters' && <GameDataPlayers mode="hitters" />}
          {tab === 'games' && <GameDataGames />}
        </Box>

        <GameDataFooter />
      </Flex>
    </ErrorBoundary>
  );
};
