import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { AdminPitchListsService } from 'services/admin/pitch-lists.service';

const COMPONENT_NAME = 'RefreshListsDialog';

interface IProps {
  name: string;
  parent_def: PitchListOwner;
  parent_id: string;

  onClose: () => void;
}

interface IState {
  summary: string;
}

export class RefreshListsDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const summary = (() => {
      switch (props.parent_def) {
        case PitchListOwner.Machine: {
          return `${props.name} and its users`;
        }

        case PitchListOwner.User: {
          return props.name;
        }

        case PitchListOwner.Team: {
          return `${props.name}, its machines, and its users`;
        }

        default: {
          throw new Error(`Invalid parent_def: ${props.parent_def}`);
        }
      }
    })();

    this.state = {
      summary: summary,
    };

    this.refresh = this.refresh.bind(this);
  }

  private refresh(config: { ms: boolean; traj: boolean }) {
    AdminPitchListsService.getInstance().refreshLists(
      this.props.parent_def,
      this.props.parent_id,
      { ms: config.ms, traj: config.traj }
    );

    this.props.onClose();
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={COMPONENT_NAME}
          title="Refresh Pitch Lists"
          width={RADIX.DIALOG.WIDTH.LG}
          content={
            <ReactMarkdown
              children={[
                `This will update machine states and/or trajectory values for all pitches in all lists belonging to ${this.state.summary}.`,
                'Retraining may be necessary before any modified pitches can be used again (e.g. when refreshing MS values).',
              ].join('\n\n')}
            />
          }
          buttons={[
            {
              label: 'Update MS',
              color: RADIX.COLOR.INFO,
              onClick: () => this.refresh({ ms: true, traj: false }),
            },
            {
              label: 'Update Traj',
              color: RADIX.COLOR.INFO,
              onClick: () => this.refresh({ ms: false, traj: true }),
            },
            {
              label: 'Update Both',
              color: RADIX.COLOR.INFO,
              onClick: () => this.refresh({ ms: true, traj: true }),
            },
          ]}
          onClose={() => this.props.onClose()}
        />
      </ErrorBoundary>
    );
  }
}
