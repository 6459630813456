export enum QueueType {
  RepeatAll = 'RepeatAll',
  RepeatOne = 'RepeatOne',
  ShuffleEach = 'ShuffleEach',
}

export interface IQueueDefinition {
  type: QueueType;
  label: string;
  tooltip: string;
  icon: JSX.Element;
}

export interface IQueueOptions {
  action: 'add' | 'remove';
  id: string;
}
